import styled from "@emotion/styled";
import { Box, css } from "@mui/material";

export const BenefitContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonContent = styled(Box)`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  & > button {
    height: 48px;
  }
`;

export const CustomButton = css`
width: 46%;
@media (max-width: 995px) {
  width: 49%;
};
@media (max-width: 980px) {
  width: 53%;
};
@media (max-width: 899px) {
  width: 46%;
};
@media (max-width: 680px) {
  width: 51%;
};
@media (max-width: 631px) {
  width: 54%;
};
@media (max-width: 604px) {
  width: 58%;
};
@media (max-width: 559px) {
  width: 48%;
};
`;