export const globalThemeColors = {
  primary: {
    neutral0: '#000000',
    neutral10: '#201B1B',
    neutral20: '#352F2F',
    neutral30: '#4C4646',
    neutral40: '#645D5D',
    neutral50: '#7D7676',
    neutral60: '#968F8F',
    neutral80: '#CDC5C5',
    neutral90: '#EBE6E6',
    neutral95: '#F6F3F3',
    neutral99: '#FEFEFE',
    neutral100: '#ffffff',

    natural0: '#393737',
    natural20: '#545454',
    natural40: '#716F70',
    natural60: '#CBBAA8',
    natural80: '#D9CCBE',
    natural90: '#E7DED4',
    natural95: '#EEE7E0',
    natural99: '#F7F1E9',
    natural100: '#F9F5EF',

    bhBlue0: '#3E63D9',
    bhBlue20: '#163258',
    bhBlue40: '#124DA0',
    bhBlue60: '#4A78B6',
    bhBlue90: '#A5CBFF',
    bhBlue95: '#C7DDFF',
    bhBlue100: '#98BEE2',

    bhYellow0: '#CC9900',
    bhYellow20: '#E5AC00',
    bhYellow40: '#FFBF00',
    bhYellow60: '#FFD559',
    bhYellow80: '#FFE594',
    bhYellow90: '#FEE5B1',

    bhTan60: '#C1B490',
    bhTan80: '#D9CAA2',
    bhTan90: '#EED796',

    bhBrown40: '#9C8665',

    bhPink0: '#CDC8E4',
    bhBlack0: '#000000',
  },

  secondary: {
    sleepio: '#3E63D9',
    daylight: '#FF754F',
    spark: '#3D9886',
    growTherapy: '#BEB0FE',
    springHealth: '#15372E',
    lyraHealth: '#264257',
    heapspace: '#61ACE4',
    calm: '#61ACE4',
    modernHealth: '#5C80D7',
    teladoc: '#362961',
    talkspace: '#5DC0AB',

    benefitsPortal: '#C18E81',

    employee: '#8BA68C',
  },
};


