import { Collapse, Typography } from "@mui/material";
import { useThemeColor } from "src/hooks/useThemeColor";
import { useState } from "react";
import {
  Container,
  Content,
  Header,
  Icon,
  IconContent,
  IconHeader,
  MainContent,
} from "./styled";
import * as icons from "../icons";

interface AccordionProps {
  title: string;
  iconSrc?: string;
  iconName?: string;
  content: React.ReactNode | string;
}

export default function CustomAccordion({
  content,
  iconSrc,
  title,
  iconName,
}: AccordionProps) {
  const [isCollapsed, setCollapsed] = useState(false);
  const IconComponent = iconName ? icons[iconName as keyof typeof icons] : null;
  return (
    <Container
      onClick={() => setCollapsed(!isCollapsed)}
      sx={{
        background: useThemeColor(isCollapsed ? "natural95" : "natural99"),
        '&:hover': {
          background: useThemeColor("natural95"), 
        },
      }}
    >
      <Header>
        <Typography color={"neutral20.main"} variant="h5Medium">
          {title}
        </Typography>
        <IconHeader
          style={{ transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <img
            src={"/assets/svg/SimpleArrowSvg.svg"}
            alt={isCollapsed ? "Close" : "Open"}
          />
        </IconHeader>
      </Header>
      <Collapse in={isCollapsed}>
        <Content>
          <IconContent>
            {!!iconSrc && <Icon src={iconSrc} alt="icon" />}
            {IconComponent && (
              <IconComponent
                strokeWidth="0.5px"
                sx={{
                  width: {
                    xs: "48px",
                    sm: "64px",
                    lg: "100px",
                  },
                  height: "auto",
                }}
              />
            )}
          </IconContent>
          <MainContent color={"neutral40.main"}>{content}</MainContent>
        </Content>
      </Collapse>
    </Container>
  );
}
