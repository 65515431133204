import LinkText from '../LinkText';
import { Typography } from '@mui/material';
import GetIcon from './utils/IconOptions';
import { LinkBoxStyled } from './styled';


export default function LinksSection({ subject, actions }: LinkProps) {
  return (
    <LinkBoxStyled>
      <Typography variant="h4Medium" component="h2">
        {subject}
      </Typography>
      {actions.map((action) => (
        <LinkText key={action.uri} url={action.uri} text={action.description} icon={GetIcon(action.uri)} />
      ))}
    </LinkBoxStyled>
  );
}

interface LinkProps {
  subject: string;
  actions: { description: string; uri: string }[];
}