import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import LogOutIcon from "../icons/LogOutIcon";
import { useThemeColor } from "src/hooks/useThemeColor";
import { useMediaQuery } from "@mui/material";
import { DropdownContainer, UserInfoContainer } from "./styled";
import { AccountMenuProps } from "src/types";
import CustomButtonComponent from "../CustomButton";


export default function AccountMenu({
  user,
  onLogoutClick = async () => { },
}: AccountMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isExtraSmallScreen = useMediaQuery("(max-width: 599px)");
  const open = Boolean(anchorEl);
  const userName = isExtraSmallScreen
    ? user?.firstName.charAt(0)
    : user?.firstName;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <Box>
            <CustomButtonComponent
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              sx={{
                minWidth: "40px",
                backgroundColor: open ? 'natural80.main' : 'natural95.main',
                ':hover': {
                  backgroundColor: 'natural80.main'
                },
                outline: "2px solid",
                outlineColor: open ? 'natural40.main' : 'transparent',
                outlineOffset: 2,
                width: {
                  sm: "auto",
                  xs: "40px"
                },
                height: "40px"
              }}
            >
              <DropdownContainer>
                <Typography
                  variant="button2"
                  color={"neutral20.main"}
                  style={{ textTransform: "capitalize" }}
                >
                  {userName}
                </Typography>
              </DropdownContainer>
            </CustomButtonComponent>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 17px rgba(0,0,0,0.2))",
            mt: 1.5,
          },
          style: { borderRadius: 12 },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <UserInfoContainer>
          <Typography variant={"body1"} sx={{ textTransform: "capitalize" }}>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography color={useThemeColor("natural40")} variant={"body2"}>
            {user?.email}
          </Typography>
        </UserInfoContainer>
        <Divider />
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
