import { Box } from "@mui/material";
import styled from "@emotion/styled";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  cursor: pointer;
`;

export const Content = styled(Box)`
  margin-top: 24px;
  display: flex;
  gap: 16px;
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const IconHeader = styled(Box)`
  transition: transform 150ms ease;
`;

export const IconContent = styled(Box)`
  padding: 10px;
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const Icon = styled.img`
  width: 66.67px;
  @media (max-width: 1280px) {
    width: 66.67px;
  }

  @media (max-width: 960px) {
    width: 42.67px;
  }

  @media (max-width: 600px) {
    width: 32px;
  }
`;

export const MainContent = styled(Box)`
  width: 100%;
  @media (min-width: 600px) {
    width: 73%;
  }
  @media (min-width: 906px) {
    width: 61%;
  }

`;

export const AccordionGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
