import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const PhoneStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_814_352520)">
        <path
          d="M9.38583 20.9142C8.4709 21.5051 7.38034 21.7628 6.2977 21.6439C5.21506 21.525 4.20642 21.0368 3.44155 20.2614L2.77298 19.6071C2.47997 19.3074 2.31592 18.9048 2.31592 18.4857C2.31592 18.0665 2.47997 17.664 2.77298 17.3642L5.61012 14.5557C5.90745 14.2636 6.30759 14.0999 6.7244 14.0999C7.14122 14.0999 7.54136 14.2636 7.83869 14.5557C8.1385 14.8491 8.54132 15.0134 8.96083 15.0134C9.38034 15.0134 9.78317 14.8491 10.083 14.5557L14.5401 10.0985C14.6888 9.95192 14.8069 9.77723 14.8875 9.58459C14.9681 9.39195 15.0096 9.18521 15.0096 8.97638C15.0096 8.76756 14.9681 8.56082 14.8875 8.36818C14.8069 8.17554 14.6888 8.00084 14.5401 7.85424C14.248 7.55691 14.0843 7.15676 14.0843 6.73995C14.0843 6.32314 14.248 5.923 14.5401 5.62567L17.3658 2.79995C17.6656 2.50695 18.0681 2.3429 18.4873 2.3429C18.9064 2.3429 19.3089 2.50695 19.6087 2.79995L20.263 3.46853C21.038 4.23327 21.5261 5.24152 21.6453 6.32379C21.7644 7.40605 21.5073 8.49634 20.9173 9.41138C17.8413 13.9452 13.9273 17.8495 9.38583 20.9142Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_352520">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>{" "}
    </SvgIcon>
  );
};
export default PhoneStreamlineIcon;
