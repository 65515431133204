import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const SecurityLockStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M16.1333 11.4667H7.6C6.71634 11.4667 6 12.183 6 13.0667V18.4C6 19.2836 6.71634 20 7.6 20H16.1333C17.017 20 17.7333 19.2836 17.7333 18.4V13.0667C17.7333 12.183 17.017 11.4667 16.1333 11.4667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.1333 11.4667V7.73333C8.1333 6.74319 8.52663 5.79361 9.22677 5.09347C9.92691 4.39333 10.8765 4 11.8666 4C12.8568 4 13.8063 4.39333 14.5065 5.09347C15.2067 5.79361 15.6 6.74319 15.6 7.73333V11.4667"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15.7333H17.7333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66657 13.8666C8.51929 13.8666 8.3999 13.7472 8.3999 13.5999C8.3999 13.4526 8.51929 13.3333 8.66657 13.3333"
        strokeWidth="1.5"
      />
      <path
        d="M8.66699 13.8666C8.81427 13.8666 8.93366 13.7472 8.93366 13.5999C8.93366 13.4526 8.81427 13.3333 8.66699 13.3333"
        strokeWidth="1.5"
      />
      <path
        d="M8.66657 18.1333C8.51929 18.1333 8.3999 18.0139 8.3999 17.8666C8.3999 17.7194 8.51929 17.6 8.66657 17.6"
        strokeWidth="1.5"
      />
      <path
        d="M8.66699 18.1333C8.81427 18.1333 8.93366 18.0139 8.93366 17.8666C8.93366 17.7194 8.81427 17.6 8.66699 17.6"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
export default SecurityLockStreamlineIcon;
