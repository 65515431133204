import { Box, Typography } from "@mui/material";
import {
  Background,
  Container,
  Content,
  DaylightAppIcon,
  RoundedButton,
  SleepioAppStyledIcon,
} from "./styled";
import { SLEEPIO_PRODUCT } from "src/constants";
import CustomButtonComponent from "../CustomButton";
import { formatId } from "src/utils";

export default function ProductHero({
  title,
  subtitle,
  price,
  description,
  buttonText,
  onClick,
  backgroundImageLeft,
  backgroundImageRight,
  product,
}: ProductHeroProps) {
  return (
    <Container>
      <Background
        backgroundImageLeft={backgroundImageLeft}
        backgroundImageRight={backgroundImageRight}
      />
      <Content>
        <Box className="titles">
          {product === SLEEPIO_PRODUCT ? (
            <SleepioAppStyledIcon />
          ) : (
            <DaylightAppIcon />
          )}
          <Typography variant="h1">{title}</Typography>
          <Typography variant="h5Medium">{subtitle}</Typography>
        </Box>
        <Box className="price">
          <Typography variant="h5">{price}</Typography>
          <Typography color="neutral40.main" variant="body3">
            {description}
          </Typography>
        </Box>
        <CustomButtonComponent
          extraStyles={RoundedButton}
          onClick={onClick}
          color="continue"
          variant="contained"
          id={formatId(`hero-${title}-${buttonText}`)}
        >
          {buttonText}
        </CustomButtonComponent>
      </Content>
    </Container>
  );
}

interface ProductHeroProps {
  product?: string;
  title: string;
  subtitle: string;
  price: string;
  description: string;
  buttonText: string;
  onClick?: () => void;
  backgroundImageLeft: string;
  backgroundImageRight: string;
}
