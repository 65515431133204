import { useEffect, useRef, useState } from "react";
import { Background, Content, MainWrapper } from "./styled";
import { Box } from "@mui/material";

const imageSize = {
  lg: "/assets/svg/background_image_lg.svg",
  md: "/assets/svg/background_image_md.svg",
  sm: "/assets/svg/background_image_sm.svg",
};

export default function BackgroundImage({
  children,
}: React.PropsWithChildren<BackgroundImageProps>) {
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const measureImage = (image: HTMLImageElement) => {
    const { height } = image.getBoundingClientRect();
    if (height) {
      /* we make sure the wrapper component is at least as tall as the image component so it doesn't hide the vertical borders of the image  */
      setImageHeight(height);
    }
  };

  useEffect(() => {
    const image = imageRef.current;
    if (!image) return;
    measureImage(image);
    image.onload = () => {
      measureImage(image);
    };
  }, [imageRef]);

  return (
    /* Wrapper component that contains the image of the background and the main content of the component */
    <MainWrapper
      sx={{
        maxHeight: imageHeight || undefined,
        marginTop: "30px",
        marginBottom: "35px",
      }}
    >
      <Box sx={{ display: { md: "none", sm: "none", xs: "block" } }}>
        <Background ref={imageRef} aria-hidden={true} src={imageSize.sm} />
      </Box>
      <Box
        sx={{ display: { lg: "none", md: "none", sm: "block", xs: "none" } }}
      >
        <Background ref={imageRef} aria-hidden={true} src={imageSize.lg} />
      </Box>
      <Box
        sx={{ display: { lg: "block", md: "block", sm: "none", xs: "none" } }}
      >
        <Background ref={imageRef} aria-hidden={true} src={imageSize.lg} />
      </Box>
      <Content>{children}</Content>
    </MainWrapper>
  );
}

interface BackgroundImageProps {
  src?: string;
  imageWidth?: number;
  imageHeight?: number;
}
