import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const AmbulanceCallStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_972_108991)">
        <path
          d="M22 5.98333C22 5.73801 21.9025 5.50273 21.7291 5.32926C21.5556 5.15579 21.3203 5.05833 21.075 5.05833H19.3583C19.2485 5.0562 19.1437 5.01162 19.0661 4.93393C18.9884 4.85625 18.9438 4.75151 18.9417 4.64167V2.925C18.9417 2.67967 18.8442 2.4444 18.6707 2.27093C18.4973 2.09746 18.262 2 18.0167 2H15.9833C15.738 2 15.5027 2.09746 15.3293 2.27093C15.1558 2.4444 15.0583 2.67967 15.0583 2.925V4.64167C15.0562 4.75151 15.0116 4.85625 14.9339 4.93393C14.8563 5.01162 14.7515 5.0562 14.6417 5.05833H12.925C12.6797 5.05833 12.4444 5.15579 12.2709 5.32926C12.0975 5.50273 12 5.73801 12 5.98333V8.01667C12 8.26199 12.0975 8.49727 12.2709 8.67074C12.4444 8.84421 12.6797 8.94167 12.925 8.94167H14.6417C14.7515 8.9438 14.8563 8.98838 14.9339 9.06607C15.0116 9.14375 15.0562 9.24849 15.0583 9.35833V11.075C15.0583 11.1965 15.0823 11.3168 15.1287 11.429C15.1752 11.5412 15.2434 11.6432 15.3293 11.7291C15.4152 11.815 15.5171 11.8831 15.6294 11.9296C15.7416 11.9761 15.8619 12 15.9833 12H18.0167C18.262 12 18.4973 11.9025 18.6707 11.7291C18.8442 11.5556 18.9417 11.3203 18.9417 11.075V9.35833C18.9438 9.24849 18.9884 9.14375 19.0661 9.06607C19.1437 8.98838 19.2485 8.9438 19.3583 8.94167H21.075C21.3203 8.94167 21.5556 8.84421 21.7291 8.67074C21.9025 8.49727 22 8.26199 22 8.01667V5.98333Z"
        />
        <path
          d="M16.3081 15.4667C15.9121 15.0848 15.3833 14.8714 14.8331 14.8714C14.2829 14.8714 13.7542 15.0848 13.3581 15.4667L13.3081 15.5167C13.2311 15.5882 13.1299 15.6279 13.0248 15.6279C12.9197 15.6279 12.8185 15.5882 12.7414 15.5167C11.2101 14.1992 9.78096 12.7673 8.46645 11.2334C8.39675 11.1555 8.35822 11.0546 8.35822 10.95C8.35822 10.8455 8.39675 10.7446 8.46645 10.6667L8.51645 10.6167C8.71046 10.4232 8.86439 10.1933 8.96942 9.94018C9.07444 9.68708 9.1285 9.41574 9.1285 9.14171C9.1285 8.86768 9.07444 8.59634 8.96942 8.34323C8.86439 8.09013 8.71046 7.86023 8.51645 7.66671L6.76645 5.92504C6.37346 5.53725 5.84356 5.31982 5.29145 5.31982C4.73934 5.31982 4.20944 5.53725 3.81645 5.92504L2.83311 6.90004C2.35936 7.37567 2.06419 8.00016 1.99739 8.66814C1.93059 9.33611 2.09626 10.0067 2.46645 10.5667C5.3534 14.913 9.08244 18.6364 13.4331 21.5167C13.9931 21.8869 14.6637 22.0526 15.3317 21.9858C15.9997 21.919 16.6242 21.6238 17.0998 21.15L18.0748 20.1834C18.4646 19.7914 18.6834 19.2611 18.6834 18.7084C18.6834 18.1556 18.4646 17.6253 18.0748 17.2334L16.3081 15.4667Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_972_108991">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>{" "}
    </SvgIcon>
  );
};
export default AmbulanceCallStreamlineIcon;
