import React from 'react';
import { Grid } from '@mui/material';
import BenefitCard from '../BenefitCard';
import { BenefitInfo } from 'src/types';

import BenefitsStylingOptions from './utils/BenefitsStylingOptions';
import LoadingCard from '../BenefitCard/utils/LoadingCard';

const genericOption = 'generic'

export default function MyBenefitsList({ data, isLoading }: { data: BenefitInfo[], isLoading: boolean }) {

  const benefitsOptions = BenefitsStylingOptions()

  function getAppOptions(name: string | undefined) {
    const lowerCaseName = name?.toLowerCase();
    const matchingKey = Object.keys(benefitsOptions).find(key => lowerCaseName?.includes(key)) as keyof typeof benefitsOptions;
    return matchingKey ? benefitsOptions[matchingKey] : benefitsOptions[genericOption];
  }

  return (
    <Grid container spacing={2}>
      {isLoading
        ?
        (<LoadingCard arrayNumber={5} />)
        :
        data?.map((item) => (
          <Grid item
            xs={6}
            sm={6}
            md={4}
            key={item?.name}
            style={{ display: 'flex' }}
            sx={{
              '@media (max-width:574px)': {
                flexBasis: "100%",
                maxWidth: "100%",
              },
            }}>
            <BenefitCard
              color={getAppOptions(item?.name)?.color || ''}
              icon={getAppOptions(item?.name)?.icon || ''}
              title={item?.name || ''}
              description={item?.description}
              buttonColor='darkButton'
              uri={item.uri}
            />
          </Grid>
        ))
      }
    </Grid>
  );
}
