import { Fade, ModalTypeMap, SwipeableDrawer, Typography } from "@mui/material";
import { CloseIcon } from "../icons";
import {
  CloseButton,
  ModalCardContainer,
  ModalContentContainer,
  StyledModal,
  Topbar,
} from "./styled";
import { CustomModalProps } from "src/types";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactElement, forwardRef } from "react";
import CustomButtonComponent from "../CustomButton";

export const CustomModal = forwardRef(
  (
    {
      isOpen,
      onCloseModal,
      children,
      cardStyles,
      contentContainerStyles,
      sxContentContainerStyles,
      sxContentStyles,
      hideCloseButton,
      dynamicContentRenderStateKey,
      modalRootContainerStyles,
      isBottomTabStyle,
      ...props
    }: CustomModalProps &
      Omit<OverridableComponent<ModalTypeMap>, "open" | "onClose">,
    ref
  ) => {
    const ModalContent = (
      <ModalContentContainer key={dynamicContentRenderStateKey} sx={sxContentContainerStyles} style={contentContainerStyles}>
        {!hideCloseButton && (
          <Topbar>
            <CustomButtonComponent
              extraStyles={CloseButton}
              variant="contained"
              color="secondaryLight"
              onClick={onCloseModal}
              sx={{
                padding: "10px 16px",
                backgroundColor: "neutral99.main",
                ":hover": {
                  backgroundColor: "neutral95.main",
                },
                ":active": {
                  backgroundColor: "neutral60.main",
                },
              }}
            >
              <Typography
                variant="button2"
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                Close
              </Typography>
              <CloseIcon fontSize="small" />
            </CustomButtonComponent>
          </Topbar>
        )}
        <ModalCardContainer ref={ref} sx={sxContentStyles} style={cardStyles}>
          {isBottomTabStyle ? (
            <Fade in={true}>{children as ReactElement<any, any>}</Fade>
          ) : (
            children
          )}
        </ModalCardContainer>
      </ModalContentContainer>
    );

    return isBottomTabStyle ? (
      <SwipeableDrawer
        anchor="bottom"
        onClose={onCloseModal}
        onOpen={() => { }}
        allowSwipeInChildren={true}
        open={isOpen}
        swipeAreaWidth={0}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            ...modalRootContainerStyles,
          },
        }}
        {...props}
      >
        {ModalContent}
      </SwipeableDrawer>
    ) : (
      <StyledModal open={isOpen} onClose={onCloseModal} {...props}>
        {ModalContent}
      </StyledModal>
    );
  }
);
