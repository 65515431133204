import styled from "@emotion/styled";
import { Box } from "@mui/material";

const TEXT_X_PADDING = 16;

export const SimpleParagraphContainer = styled(Box)`
display: flex;
flex-direction: column;
`;

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 48px;
`;

export const TextContainer = styled(MainContainer)`
  align-items: flex-start;
  padding: 0px 16px;
  gap: 48px;
  max-width: ${840 + TEXT_X_PADDING}px;
`;