import {AndroidStreamlineIcon, AppleLogoStreamlineIcon, CalendarStreamlineIcon, CalendarWithClockIcon, SecurityLockStreamlineIcon, ShippingLogisticNextDayDeliveryStreamlineIcon, WorkflowTeamworkCogHandStreamlineIcon } from "../icons";

export const ICONS = {
  calendar: <CalendarStreamlineIcon />,
  dateTime: <CalendarWithClockIcon />,
  '24h': <ShippingLogisticNextDayDeliveryStreamlineIcon />,
  handHoldingGear: <WorkflowTeamworkCogHandStreamlineIcon />,
  padlock: <SecurityLockStreamlineIcon/>,
  apple: <AppleLogoStreamlineIcon/>,
  android: <AndroidStreamlineIcon/>
} as const;
