import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import {
  MainContainer,
  SimpleParagraphContainer,
  TextContainer,
} from "./styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import { LibraryImage } from "../LibraryDetail/styled";

export const SelfEsteem = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const variantFont = useMemo(
    () => (matches ? "bodyXL" : "bodyXLMedium"),
    [matches]
  );
  const variantTitle = useMemo(() => (matches ? "h3" : "h3Medium"), [matches]);

  return (
    <MainContainer
      sx={{
        gap: {
          xs: "48px",
          sm: "80px",
        },
      }}
    >
      <LibraryImage
        src={"/assets/images/SelfEsteemImage1.png"}
        alt="Self Esteem 1"
      />
      <TextContainer>
        <SimpleParagraphContainer>
          <Typography variant={variantFont}>
            Ever sat in a meeting, surrounded by talented colleagues, and
            wondered how you ended up here? Does that sneaky voice in your head
            insist your achievements are just luck, and any moment someone will
            expose you as a fraud?
          </Typography>
          <br />
          <Typography variant={variantFont}>
            If you've nodded along, welcome to the club—the club of Imposter
            Syndrome. Let's unravel it.
          </Typography>
        </SimpleParagraphContainer>

        <SimpleParagraphContainer>
          <Typography variant={variantTitle}>The Uninvited Guest</Typography>
          <br />
          <Typography variant={variantFont}>
            Imposter syndrome can be quite the mouthful but it is essentially
            the nagging feeling that suggests you don't deserve the success
            you've achieved.
          </Typography>
          <br />
          <Typography variant={variantFont}>
            You might attribute your accomplishments to luck, timing, or the
            kindness of others, rather than acknowledging your hard work and
            skills. And despite clear evidence of your competence, you can't
            shake the belief that you're a fake, an imposter in your own life.
          </Typography>
        </SimpleParagraphContainer>

        <SimpleParagraphContainer>
          <Typography variant={variantTitle}>
            The Perfectionist's Dilemma
          </Typography>
          <br />
          <Typography variant={variantFont}>
            One common root of Imposter Syndrome is perfectionism. The desire to
            excel in every task and meet unattainable standards can set the
            stage for feelings of inadequacy. The more you achieve, the more
            pressure you feel to maintain an impossible level of perfection.
          </Typography>
          <br />
          <Typography variant={variantFont}>
            If you’re still nodding, we are here to help you change the script.
          </Typography>
        </SimpleParagraphContainer>
      </TextContainer>
      <LibraryImage
        src={"/assets/images/SelfEsteemImage2.jpg"}
        alt="Self Esteem 1"
      />
      <TextContainer>
        <SimpleParagraphContainer>
          <Typography variant={variantTitle}>
            Embrace Failure as a Stepping Stone
          </Typography>
          <br />
          <Typography variant={variantFont}>
            Failure is not the opposite of success; it's a part of it. Instead
            of viewing failure as a confirmation of your imposter status, see it
            as an opportunity to learn and grow. The most successful individuals
            have faced setbacks and disappointments but used them as stepping
            stones toward greater achievements.
          </Typography>
          <br />
          <Typography variant={variantFont}>
            When you encounter a challenge, ask yourself: What can I learn from
            this experience? How can I use it to improve? Shifting your
            perspective from fear of failure to a mindset of continuous
            improvement can be a game-changer.
          </Typography>
        </SimpleParagraphContainer>
        <SimpleParagraphContainer>
          <Typography variant={variantTitle}>
            Cultivate a Supportive Network
          </Typography>
          <br />
          <Typography variant={variantFont}>
            Imposter Syndrome doesn't discriminate – it affects everyone. Having
            open conversations about your struggles can break down the walls of
            isolation and help you realize that others have faced similar
            challenges.
          </Typography>
          <br />
          <Typography variant={variantFont}>
            Remember, asking for support is a sign of strength, not weakness.
            Your colleagues may have valuable insights and stories of their own
            imposter battles, creating a supportive network that can help you
            navigate the rocky terrain of self-doubt.
          </Typography>
        </SimpleParagraphContainer>
      </TextContainer>
      <LibraryImage
        src={"/assets/images/SelfEsteemImage3.png"}
        alt="Self Esteem 1"
      />
      <TextContainer>
        <Typography variant={variantTitle}>
          Rewrite Your Inner Script
        </Typography>
        <Typography variant={variantFont}>
          Your thoughts have power, and the stories you tell yourself shape your
          reality. Challenge the negative narratives that fuel your imposter
          feelings. Instead of saying, "I'm not qualified for this role,"
          reframe it as, "I may not know everything, but I have the skills and
          ability to learn and adapt."
        </Typography>
        <Typography variant={variantFont}>
          Create a habit of positive self-talk. Replace self-doubt with
          affirmations that highlight your strengths and capabilities. Over
          time, this can reshape your internal dialogue, boosting your
          confidence and diminishing the grip of Imposter Syndrome.
        </Typography>
        <Typography variant={variantTitle}>
          The Journey to Authenticity
        </Typography>
        <Typography variant={variantFont}>
          Imposter Syndrome may be a persistent companion, but it doesn't have
          to dictate your journey. By acknowledging your achievements, embracing
          failure, cultivating a support network, and rewriting your inner
          narrative, you can navigate the challenges of the workplace with
          authenticity and confidence.
        </Typography>
        <Typography variant={variantFont}>
          So, the next time you find yourself in a meeting, take a moment to
          appreciate the journey that brought you to that table. You're not an
          imposter; you're a professional on a unique and valuable path. Own it,
          celebrate it, and keep moving forward. Your authentic success awaits.
        </Typography>
      </TextContainer>
    </MainContainer>
  );
};
