import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LogOutDoorStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_972_108994)">
        <path
          d="M15.9142 18.5329H11.0142V5.46638H15.9142V6.28304H17.5475V4.64971C17.5475 4.19871 17.1818 3.83304 16.7308 3.83304H11.0142V3.01638C11.014 2.44638 10.4447 2.05196 9.91091 2.25204L3.37766 4.70196C3.05891 4.82146 2.84774 5.12604 2.84766 5.46638V18.5329C2.84741 18.8735 3.05866 19.1785 3.37766 19.2981L9.91091 21.748C10.445 21.9483 11.0146 21.5532 11.0142 20.9829V20.1662H16.7308C17.1818 20.1662 17.5475 19.8006 17.5475 19.3495V17.7162H15.9142V18.5329ZM6.93091 12.8163C6.30224 12.8163 5.90932 12.1357 6.22366 11.5913C6.53799 11.0469 7.32382 11.0469 7.63816 11.5913C7.70982 11.7155 7.74757 11.8563 7.74757 11.9996C7.74757 12.4506 7.38191 12.8163 6.93091 12.8163Z"
        />
        <path
          d="M17.3081 15.8436L21.1521 11.9996L17.3081 8.15564L16.1534 9.31039L18.026 11.183H12.6475V12.8163H18.026L16.1534 14.6889L17.3081 15.8436Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_972_108994">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default LogOutDoorStreamlineIcon;
