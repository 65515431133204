import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { MainContainer, TextContainer } from "./styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import { LibraryImage } from "../LibraryDetail/styled";

export const WorkLifeBalance = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const variantFont = useMemo(
    () => (matches ? "bodyXL" : "bodyXLMedium"),
    [matches]
  );
  const variantTitle = useMemo(() => (matches ? "h3" : "h3Medium"), [matches]);

  return (
    <MainContainer
      sx={{
        gap: {
          xs: "48px",
          sm: "80px",
        },
      }}
    >
      <LibraryImage
        src={"/assets/images/WorkLifeBalanceImage1.png"}
        alt="Work Life Balance 1"
      />
      <TextContainer>
        <Typography variant={variantFont}>
          Join us on a journey where self-care guides our way through the
          landscape of burnout. In a world that celebrates hustle, it's
          essential to carve a path that prioritizes our well-being. Let's delve
          into an exploration, blending practical strategies with inspirational
          wisdom to steer clear of burnout and cultivate a life of enduring
          vitality.
        </Typography>
        <Typography variant={variantTitle}>
          Understanding Burnout: The Sneaky Adversary
        </Typography>
        <Typography variant={variantFont}>
          Burnout isn't just exhaustion; it's a pervasive sense of
          depletion—physically, emotionally, and mentally. It's the result of
          prolonged stress, often stemming from work, caregiving, or personal
          expectations. The first step in avoiding burnout is recognizing its
          subtle signs, acknowledging when the flames of stress threaten to
          consume your vitality.
        </Typography>
        <Typography variant={variantTitle}>
          The Power of Boundaries: Guarding Your Flame
        </Typography>
        <Typography variant={variantFont}>
          Imagine your energy as a precious flame. To avoid burnout, you must
          become a vigilant guardian of this flame. Establish clear
          boundaries—know when to say no, delegate tasks, and protect your time.
          Embrace the art of prioritization; not everything requires your
          immediate attention, and that's okay.
        </Typography>
        <Typography variant={variantTitle}>
          Embracing Rest as a Weapon: Your Shield Against Burnout
        </Typography>
        <Typography variant={variantFont}>
          Rest is not a luxury; it's a strategic weapon against burnout. Quality
          sleep, mindful breaks during the day, and moments of leisure are not
          concessions but investments in your well-being. Cultivate a mindset
          that honors rest, understanding that it's the fuel that replenishes
          your flame.
        </Typography>
      </TextContainer>
      <LibraryImage
        src={"/assets/images/WorkLifeBalanceImage2.png"}
        alt="Work Life Balance 1"
      />
      <TextContainer>
        <Typography variant={variantTitle}>
          The Dance of Self-Care: Nourishing Your Inner Fire
        </Typography>
        <Typography variant={variantFont}>
          Self-care is not a one-size-fits-all concept. It's a dance—a
          personalized, intentional rhythm that nourishes your mind, body, and
          soul. Discover what activities replenish your energy, whether it's a
          quiet walk, indulging in a favorite hobby, or simply being still.
          Prioritize self-care not as an indulgence but as a vital component of
          your burnout-prevention toolkit.
        </Typography>
        <Typography variant={variantTitle}>
          Seeking Support: Allies in the Battle Against Burnout
        </Typography>
        <Typography variant={variantFont}>
          You don't have to navigate the terrain of burnout alone. Build a
          support network—trusted friends, family, or colleagues who understand
          the challenges you face. Share your feelings, seek advice, and let
          their presence be a balm for your soul. Together, you can face the
          flames of stress with collective strength.
        </Typography>
        <Typography variant={variantTitle}>
          Mindful Reflection: The Compass in Burnout Prevention
        </Typography>
        <Typography variant={variantFont}>
          Take moments for mindful reflection. Assess your workload,
          commitments, and emotional well-being regularly. Are you veering
          toward the flames of burnout? Adjust your course. Mindful reflection
          is the compass that guides you toward balance, helping you stay
          aligned with your values and priorities.
        </Typography>
      </TextContainer>
      <LibraryImage
        src={"/assets/images/WorkLifeBalanceImage3.png"}
        alt="Work Life Balance 1"
      />
      <TextContainer>
        <Typography variant={variantTitle}>
          Cultivating Passion and Purpose: Your North Star
        </Typography>
        <Typography variant={variantFont}>
          Passion and purpose are the guiding stars that illuminate your
          journey. Reconnect with the reasons behind your endeavors, infusing
          your daily tasks with meaning. When you feel aligned with your
          passions and purpose, the flames of burnout lose their power to engulf
          you.
        </Typography>
        <Typography variant={variantTitle}>
          Your Flame, Your Responsibility
        </Typography>
        <Typography variant={variantFont}>
          In the pursuit of our goals, dreams, and responsibilities, we must
          remember that our flame—the essence of who we are—is our most precious
          asset. Avoiding burnout is not a luxury but a responsibility we owe to
          ourselves. Let this journey be a reminder that your well-being is
          non-negotiable, and by navigating the flames of stress with intention
          and resilience, you can forge a path of sustainable vitality.
        </Typography>
      </TextContainer>
    </MainContainer>
  );
};
