import React, { useRef, useState } from 'react';
import GenericError from '../GenericError';
import { useSetupInterceptors } from './utils/useSetupInterceptors';

interface EmitterProps {
  children: React.ReactNode;
  logout: () => void;
  disableChat: () => void;
}

export default function Emitter({ children, logout, disableChat }: EmitterProps) {
  const [error, setError] = useState(false);
  const hasLoggedOut = useRef(false);
  const onError = () => {
    setError(true);
    disableChat();
  }

  const handleLogout = () => {
    if (!hasLoggedOut.current) {
      hasLoggedOut.current = true;
      logout();
    }
  };

  useSetupInterceptors(onError, handleLogout)
  
  return (error) ? <GenericError logout={logout} /> : <>{children}</>;
}
