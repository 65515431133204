import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const FooterContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding-top: 24px;
  justify-content: center;
  margin-top: auto;
`;

export const FooterText = styled(Typography)`
  text-align: center;
`;