import { Typography } from "@mui/material"
import { FalseButton, HeroContainer, HeroWrapper, ImagePicture, MainInformation, ProfilePicture, TimeRead } from './styled';
import React from "react";

interface HeadingHeroModalProps {
  falseButtonTitle: string;
  title: string;
  description: string;
  timeRead: string;
  profilePicture: string;
  profileName: string;
  profileDate: string;
}

export const HeadingHeroModal = ({ falseButtonTitle, title, description, timeRead, profilePicture,
  profileName,
  profileDate }: HeadingHeroModalProps) => {
  return (
    <React.Fragment>
      <HeroWrapper>
        <HeroContainer>
          <FalseButton>
            <Typography color={"neutral0.main"} variant="button">
              {falseButtonTitle}
            </Typography>
          </FalseButton>
          <Typography color={"neutral20.main"} variant="h2Medium">
            {title}
          </Typography>
          <Typography color={"neutral40.main"} variant="h5Medium" style={{ textAlign: 'center' }}>
            {description}
          </Typography>
          <TimeRead>
            <Typography color={"neutral0.main"} sx={{ fontSize: '14px' }}>
              {timeRead}
            </Typography>
          </TimeRead>
          <MainInformation>
            <ImagePicture alt="Profile" src={profilePicture} />
            <ProfilePicture>
              <Typography color={"neutral20.main"}>
                {profileName}
              </Typography>
              <Typography color={"neutral40.main"} sx={{ fontSize: '14px' }}>
                {profileDate}
              </Typography>
            </ProfilePicture>
          </MainInformation>
        </HeroContainer>
      </HeroWrapper>
    </React.Fragment>
  )
}
