import { LibraryCardProps } from "../types";
import { SELF_ESTEEM, SLEEP_HEALTH, WORK_LIFE_BALANCE } from "src/constants";

const DATA_PROVIDER: { [key: string]: LibraryCardProps } = {
  [SELF_ESTEEM]: {
    title: "Silence your self-doubt",
    content: "A simple guide to overcoming imposter syndrome",
    imageSrc: "/assets/images/SelfEsteemImage1.png",
    book: SELF_ESTEEM,
    flagText: "Self-Esteem",
  },
  [WORK_LIFE_BALANCE]: {
    title: "Find balance, prevent burnout",
    content: "A compassionate guide to ending and healing from burnout",
    imageSrc: "/assets/images/WorkLifeBalanceImage1.png",
    book: WORK_LIFE_BALANCE,
    flagText: "Work-Life Balance",
  },
  [SLEEP_HEALTH]: {
    title: "Relax and sleep better",
    content:
      "Unwind effortlessly with the body scan technique for better sleep",
    imageSrc: "/assets/images/SleepHealthImage1.jpg",
    book: SLEEP_HEALTH,
    flagText: "Sleep Health",
  },
};

export default DATA_PROVIDER;

export const DATA_PROVIDER_LIST = Object.values(DATA_PROVIDER);
