import { ProductDetailProps } from "src/types";
import ProductDescription from "../ProductDescription";
import ProductHero from "../ProductHero";
import ProductFooter from "../ProductFooter";
import CustomAccordion from "../AccordionComponent";
import { HeroWrapper, MainContainer, ResponsiveFooterWrapper, ResponsiveWrapper } from "./styled";
import Questions from "../QuestionsComponent";
import { Box } from "@mui/material";
import { generateProductDetailData } from "src/utils/generateProductDetailData";
import ProductCarousel from "../ProductCarousel";
import ProductChips, { ProductChipItem } from "../ProductChips";

export const ProductDetail = ({ product, openChat }: ProductDetailProps) => {
  const productDetailData = generateProductDetailData();
  return (
    <MainContainer
      sx={{
        backgroundColor: "neutral99.main",
      }}
    >
      <HeroWrapper>
        <ProductHero {...productDetailData[product].header} />
      </HeroWrapper>
      <ResponsiveWrapper
        sx={{
          padding: {
            xs: "24px",
            md: "64px 24px",
          },
          gap: "24px",
          alignItems: "center",
        }}
      >
        <ProductDescription {...productDetailData[product].mainInformation} />
        <ProductChips items={productDetailData[product].chips as ProductChipItem[]} />
        <ProductCarousel applicationName={product} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          {productDetailData[product].complements.map((elementProps) => (
            <CustomAccordion key={elementProps.title} {...elementProps} />
          ))}
        </Box>
        <Questions onClick={openChat} />
      </ResponsiveWrapper>
      <ResponsiveFooterWrapper
        sx={{
          backgroundColor: "natural95.main",
        }}
      >
        <ProductFooter {...productDetailData[product].footer} />
      </ResponsiveFooterWrapper>
    </MainContainer>
  );
};
