// Save data to local storage
export const saveToLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

// Retrieve data from local storage
export const getFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data;
};

// Remove data from local storage
export const deleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// cookies management
export function getFromCookies(key: string) {
  let item = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, key.length + 1) === `${key}=`) {
        item = decodeURIComponent(cookie.substring(key.length + 1));
        break;
      }
    }
  }
  return item;
}

export function getCsrfToken() {
  return getFromCookies("csrftoken");
}

export function clearAllCookies(extraTargetNames: string[] = []) {
  const TARGET_COOKIE_NAMES = ["csrftoken", "sessionid"];
  const targetNames = [...TARGET_COOKIE_NAMES, ...extraTargetNames];
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
    if (targetNames.includes(name)) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
