import { Typography } from "@mui/material";
import { Container } from "./styled";

interface WelcomeMessageProps {
  firstName: string | undefined;
}

export default function WelcomeMessage({
  firstName,
}: WelcomeMessageProps): React.ReactElement | null {
  return firstName ? (
    <Container>
      <Typography variant="h1">Hi {firstName}</Typography>
    </Container>
  ) : null;
}
