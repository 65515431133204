import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const ScienceBrainStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M6.82568 6.1248C7.20613 4.8384 7.82906 4 8.53306 4C9.23706 4 9.85999 4.8384 10.2397 6.1248"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.24 12.0085C9.86026 13.2949 9.23733 14.1333 8.53333 14.1333C7.82933 14.1333 7.20639 13.2949 6.82666 12.0085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57249 10.8782C12.0088 9.4812 13.5188 7.53756 12.9451 6.53691C12.3713 5.53627 9.93113 5.85754 7.49476 7.2545C5.05839 8.65147 3.54843 10.5951 4.12218 11.5958C4.69593 12.5964 7.13612 12.2752 9.57249 10.8782Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9446 11.5961C13.5183 10.5955 12.0083 8.65183 9.572 7.25487C7.13563 5.85791 4.69544 5.53663 4.12169 6.53728C3.54795 7.53792 5.0579 9.48158 7.49428 10.8785C9.93065 12.2755 12.3709 12.5968 12.9446 11.5961Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2002 20V17.3334"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7334 4C16.81 4 17.9058 8.17991 19.7156 12.8747C19.7466 12.9555 19.7575 13.0426 19.7473 13.1286C19.7372 13.2146 19.7063 13.2968 19.6573 13.3681C19.6084 13.4395 19.5427 13.4979 19.4662 13.5383C19.3896 13.5787 19.3044 13.5999 19.2178 13.6H17.8667V15.7333C17.8667 16.2991 17.6419 16.8417 17.2419 17.2418C16.8418 17.6419 16.2992 17.8667 15.7334 17.8667H14.6667V20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.53327 9.33302C8.38599 9.33302 8.2666 9.21363 8.2666 9.06635C8.2666 8.91908 8.38599 8.79968 8.53327 8.79968"
        strokeWidth="1.5"
      />
      <path
        d="M8.5332 9.33302C8.68047 9.33302 8.79987 9.21363 8.79987 9.06635C8.79987 8.91908 8.68047 8.79968 8.5332 8.79968"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
export default ScienceBrainStreamlineIcon;
