import React, { useCallback } from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "@emotion/styled";
import { globalThemeColors } from "src/theme/globalThemeColors";
import { SerializedStyles } from "@emotion/react";

interface CustomButtonComponentProps extends ButtonProps {
  extraStyles?: SerializedStyles;
}

const StyledButton = styled(
  ({ extraStyles, ...other }: CustomButtonComponentProps) => (
    <Button {...other} />
  )
) <CustomButtonComponentProps>`
  &:focus {
    outline: 2px solid;
    outline-color: ${globalThemeColors.primary.natural40};
    outline-offset: 2px;
  }
  ${(props) => props.extraStyles}
`;

const CustomButtonComponent: React.FC<CustomButtonComponentProps> = (props) => {
  const { extraStyles, onClick, ...rest } = props;
  const [dynamicKey, setDynamicKey] = React.useState(0);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      setTimeout(() => {
        setDynamicKey((prev) => prev + 1);
      }, 100);
    },
    [onClick, setDynamicKey]
  );

  return (
    <StyledButton
      {...rest}
      key={dynamicKey}
      onClick={handleClick}
      extraStyles={extraStyles}
    >
      {props.children}
    </StyledButton>
  );
};

export default CustomButtonComponent;