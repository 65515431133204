import { Typography } from "@mui/material";
import { ProductWrapper, Container } from "./styled";
import ProTip from "../ProTip";
import IconProduct from "./utils/IconProduct";
import CustomButtonComponent from "../CustomButton";
import { formatId } from "src/utils";

export default function ProductFooter({
  name,
  title,
  price,
  description,
  onClick,
  buttonText,
}: ProductFooterProps) {
  return (
    <ProductWrapper>
      <Container>
        <IconProduct name={name} />
        <Typography mb={2} variant="h3Medium" color="neutral20.main">
          {title}
        </Typography>
        <Typography variant="h5" color="neutral0.main">
          {price}
        </Typography>
        <Typography variant="body3" color="neutral40.main">
          {description}
        </Typography>
        <CustomButtonComponent
          variant="contained"
          color='continue'
          style={{ borderRadius: '40px', width: '100%' }}
          onClick={onClick}
          disabled={false}
          id={formatId(`footer-${name}-${buttonText}`)}
        >
          {buttonText}
        </CustomButtonComponent>
      </Container>
      <ProTip />
    </ProductWrapper>
  );
}
interface ProductFooterProps {
  name: string;
  title: string;
  price: string;
  description: string;
  onClick: () => void;
  buttonText: string;
}
