import styled from "@emotion/styled";
import { Box, Modal, css } from "@mui/material";

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalContentContainer = styled(Box)`
  display: flex;
  position: relative;
  min-width: 200px;
  min-height: 200px;
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
  flex-direction: column;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.neutral99.main};
  outline: none;
`;

export const ModalCardContainer = styled(Box)`
  display: flex;
`;

export const Topbar = styled(Box)`
  z-index: 10;
  width: 100%;
  padding: 8px 24px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.palette.neutral99.main};
  @media (max-width: 667px) {
    & > button {
      left: 24px;
    }
  }
`;

export const CloseButton = css`
  gap: 4px;
`;
