import {
  useCallback,
  useEffect,
  useState,
} from "react";
import restApi from "src/rest";
import { Company } from "src/types";

export const useOrganizations = (
) => {
  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [isLoading, setIsLoading] = useState(true)

  const fetchCompanies = useCallback(async () => {
    try {
      const response: Company[] = await restApi.get("organizations/");
      if (response) {
        setCompanies(response);
      }
      setIsLoading(false)
    } catch (error) {
      console.error('error')
    }
  }, [setCompanies]);

  useEffect(() => {
    if (!companies) fetchCompanies();
  }, [fetchCompanies, companies]);

  return { companies, fetchCompanies, isLoading };
};
