import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { MainContainer, TextContainer } from "./styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import { LibraryImage } from "../LibraryDetail/styled";

export const SleepHealth = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const variantFont = useMemo(
    () => (matches ? "bodyXL" : "bodyXLMedium"),
    [matches]
  );
  const variantTitle = useMemo(() => (matches ? "h3" : "h3Medium"), [matches]);

  return (
    <MainContainer
      sx={{
        gap: {
          xs: "48px",
          sm: "80px",
        },
      }}
    >
      <LibraryImage
        src={"/assets/images/SleepHealthImage1.jpg"}
        alt="Self Health 1"
      />
      <TextContainer>
        <Typography variant={variantFont}>
          In the quest for restful nights, the simplicity of the body scan
          technique can be your ally. As you prepare for sleep, find a
          comfortable position and close your eyes. Let's embark on a brief
          journey to release tension and invite tranquility into your body and
          mind.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            1. Set the Scene:
          </Typography>{" "}
          Begin in a quiet, dimly lit space. Disconnect from screens and create
          a serene atmosphere conducive to relaxation.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            2. Find comfort:
          </Typography>{" "}
          Lie down or sit in a comfortable position. Take a moment to adjust
          your body and ensure you feel at ease.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            3. Focus on your breath:
          </Typography>{" "}
          Inhale slowly and deeply through your nose, allowing your chest and
          abdomen to rise. Exhale gently through your mouth, releasing any
          tension with each breath.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            4. Scan your body:
          </Typography>{" "}
          Start from your toes and gradually shift your focus upward. With each
          area, tense and then release the muscles. Notice any areas holding
          tension and consciously let go.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            5. Release facial tension:
          </Typography>{" "}
          Move to your face and jaw. Allow your facial muscles to relax,
          unclenching your jaw and softening your forehead.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            6. Drift into serenity:
          </Typography>{" "}
          Continue the scan, moving through your neck, shoulders, arms, and
          torso. Imagine a wave of relaxation cascading down, soothing each part
          of your body.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            7. Mindful moments:
          </Typography>{" "}
          If your mind wanders, gently guide it back to the sensations in your
          body. Embrace the present moment, letting go of thoughts that may
          disrupt your peace.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            8. Breath and be present:
          </Typography>{" "}
          Conclude the body scan by returning your focus to your breath. Inhale
          serenity, exhale any remaining tension.
        </Typography>
        <Typography variant={variantFont} component="span">
          <Typography variant={variantTitle} component="span">
            9. Embrace stillness:
          </Typography>{" "}
          As you lie in this state of calm, allow the tranquility to linger.
          Drift into sleep with a sense of peace enveloping your body and mind.
        </Typography>
        <Typography variant={variantFont}>
          Make the body scan a nightly ritual, adapting it to suit your
          preferences. With practice, this simple yet powerful technique can
          pave the way for nights of rejuvenating and uninterrupted sleep.{" "}
        </Typography>
      </TextContainer>
    </MainContainer>
  );
};
