import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ChatTwoBubblesOvalStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_762_139794)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.87886 2C7.78927 2 5.78526 2.83009 4.30769 4.30766C2.83013 5.78523 2.00004 7.78923 2.00004 9.87883C1.99512 11.4297 2.45344 12.9467 3.31627 14.2354L2.26353 17.0526C2.14724 17.3637 2.41391 17.6831 2.74087 17.6244L6.43071 16.9606C6.79717 17.1391 7.17587 17.2887 7.56327 17.4081C7.4284 16.8176 7.35716 16.2028 7.35716 15.5714C7.35716 11.0348 11.0348 7.35714 15.5714 7.35714C16.2055 7.35714 16.8227 7.42897 17.4154 7.56494C17.1264 6.62397 16.663 5.74377 16.0476 4.97097C15.3091 4.04357 14.3709 3.29477 13.3028 2.78037C12.2347 2.26597 11.0644 1.99922 9.87886 2ZM20.1174 11.0257C18.9117 9.82016 17.2766 9.14286 15.5717 9.14286C14.6044 9.14222 13.6494 9.35987 12.778 9.77959C11.9065 10.1993 11.141 10.8103 10.5384 11.567C9.93589 12.3237 9.51187 13.2066 9.29799 14.1499C9.0841 15.0932 9.08583 16.0727 9.30309 17.0153C9.52033 17.9579 9.94747 18.8393 10.5527 19.5937C11.1579 20.3483 11.9256 20.9566 12.7986 21.3731C13.6716 21.7897 14.6273 22.004 15.5946 22C16.5619 21.9959 17.5157 21.7736 18.3851 21.3496L21.2594 21.8667C21.5864 21.9256 21.853 21.6061 21.7367 21.2949L20.9263 19.126C21.6303 18.0746 22.0043 16.8369 22.0003 15.5714C22.0003 13.8665 21.323 12.2313 20.1174 11.0257Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_762_139794">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default ChatTwoBubblesOvalStreamlineIcon;
