import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import { useAuthState } from "src/hooks/useAuthState";
import { AppContainer, LoadingScreenContainer } from "./styled";
import { useBenefits } from "src/hooks/useBenefits";
import { useEffect, useMemo } from "react";
import theme from "src/theme";
import LoginView from "src/components/Views/LoginView";
import { HomeNavigationView } from "src/components/HomeNavigationView";
import CallbackView from "src/components/CallbackView";
import Emitter from "src/components/Emitter/Emitter";
import AutoLoginView from "src/components/Views/AutoLoginView";
import LoadingBH from "src/components/LoadingBH";

export default function App() {
  const {
    isAuth,
    authenticateAuth0,
    logout,
    openChat,
    user,
    disableChat,
    initAut0Login,
    authenticate,
    isAppReady,
  } = useAuthState();
  const { benefits, isLoading } = useBenefits(isAuth);
  const list = useMemo(() => benefits?.benefits || [], [benefits]);
  const supportData = useMemo(() => benefits?.crisis_support || [], [benefits]);

  const HomeViewRouteElementProps = useMemo(
    () => ({
      benefitsData: list,
      supportData: supportData,
      openChat: openChat,
      logout: logout,
      user: user,
      isLoading: isLoading,
    }),
    [user, list, supportData, openChat, logout, isLoading]
  );

  useEffect(() => {
    isAuth
      ? (document.body.style.backgroundColor = theme.palette.background.default)
      : (document.body.style.backgroundColor = theme.palette.neutral100.main);
  }, [isAuth]);

  return (
    <HashRouter>
      <Emitter logout={logout} disableChat={disableChat}>
        <AppContainer maxWidth={false}>
          {isAppReady ? (
            <Routes>
              {isAuth ? (
                <>
                  <Route
                    path="/"
                    element={
                      <HomeNavigationView {...HomeViewRouteElementProps} />
                    }
                  />
                  <Route
                    path="/:product"
                    element={
                      <HomeNavigationView {...HomeViewRouteElementProps} />
                    }
                  />
                  <Route
                    path="/library/:book"
                    element={
                      <HomeNavigationView {...HomeViewRouteElementProps} />
                    }
                  />
                  {/* Include your new auth protected routes... */}
                </>
              ) : (
                <>
                  <Route
                    path="/login"
                    element={<LoginView handleLogin={initAut0Login} />}
                  />
                  <Route
                    path="/auto-login"
                    element={<AutoLoginView handleLogin={initAut0Login} />}
                  />
                  <Route
                    path="/callback"
                    element={
                      <CallbackView
                        authenticateAuth0={authenticateAuth0}
                        authenticate={authenticate}
                        logout={logout}
                      />
                    }
                  />
                </>
              )}
              <Route
                path="*"
                element={<Navigate to={isAuth ? "/" : "/login"} />}
              />
            </Routes>
          ) : (
            <LoadingScreenContainer>
              <LoadingBH message="Loading..." />
            </LoadingScreenContainer>
          )}
        </AppContainer>
      </Emitter>
    </HashRouter>
  );
}
