import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const WorkflowTeamworkHandGatherStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M19.9777 18.5723L17.3217 15.8985C17.5696 15.4452 17.6643 14.9238 17.5915 14.4123C17.5187 13.9008 17.2825 13.4265 16.918 13.0602L14.5247 10.7104C14.3351 10.521 14.0951 10.3899 13.8332 10.3329C13.5712 10.2758 13.2984 10.2951 13.0472 10.3886C11.6 10.8875 10.1807 11.4639 8.79547 12.1153C8.66037 12.2505 8.58447 12.4337 8.58447 12.6248C8.58447 12.8159 8.66037 12.9992 8.79547 13.1343L11.5013 15.7874L10.4709 15.6742C10.271 15.6479 10.0685 15.6976 9.90344 15.8135C9.73841 15.9294 9.62292 16.1031 9.57985 16.3001C9.53677 16.4971 9.56925 16.7031 9.67085 16.8773C9.77245 17.0515 9.93576 17.1812 10.1284 17.2408C10.9893 17.522 12.5288 18.0077 13.5641 18.2377C13.9581 18.3252 14.3678 18.3122 14.7554 18.1999L16.5441 20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.32435 10.4597C8.35068 10.2598 8.30096 10.0572 8.18505 9.89221C8.06914 9.72718 7.89548 9.61169 7.69847 9.56862C7.50146 9.52554 7.29545 9.55802 7.12124 9.65962C6.94704 9.76122 6.81733 9.92453 6.75782 10.1172C6.47655 10.9788 5.99021 12.5176 5.76093 13.5529C5.67283 13.9468 5.68557 14.3565 5.79796 14.7442L4 16.5329"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.42725 19.9687L8.10104 17.3127"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4103 9.45155C15.0806 8.83633 14.721 8.19405 14.4134 7.70913C14.1969 7.36878 13.8985 7.0882 13.5454 6.89311L13.5554 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1137 4.00281L10.1009 6.70366C9.76727 6.8027 9.45897 6.97264 9.19708 7.20184C8.93519 7.43104 8.72589 7.7141 8.5835 8.03165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default WorkflowTeamworkHandGatherStreamlineIcon;
