import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const WorkflowTeamworkHandshakeStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.4178 9.50001L9.70943 7.68335C9.46702 7.56764 9.18889 7.55172 8.93486 7.63899C8.68083 7.72626 8.47121 7.90976 8.3511 8.15001L8.18443 8.49168C8.10011 8.6689 8.05243 8.86131 8.04424 9.0574C8.03605 9.25348 8.06752 9.4492 8.13677 9.63283C8.20603 9.81646 8.31163 9.98423 8.44726 10.1261C8.58289 10.2679 8.74575 10.3809 8.9261 10.4583L10.2011 11.1L13.4178 9.50001Z"
      />
      <path
        d="M13.7926 8.78339L14.2842 9.03339C14.4021 8.66162 14.3723 8.25872 14.2009 7.90839L14.0342 7.56672C13.9127 7.3258 13.701 7.14254 13.4451 7.05673C13.1892 6.97093 12.9098 6.98949 12.6676 7.10839L11.5176 7.65839L13.7926 8.78339Z"
      />
      <path
        d="M18.4507 14.5583L16.3174 10.2083C16.1481 9.861 15.8487 9.59448 15.4841 9.46667L10.6924 11.8C11.0229 12.2629 11.2255 12.8049 11.2796 13.3711C11.3337 13.9373 11.2376 14.5078 11.0007 15.025L9.93406 17.3417C9.75357 17.7391 9.49546 18.0964 9.1749 18.3926C8.85433 18.6889 8.47779 18.918 8.0674 19.0667C7.66185 19.2086 7.23228 19.2692 6.80328 19.2449C6.37429 19.2206 5.95431 19.1119 5.5674 18.925L5.28407 18.7833L3.7674 20.65C3.6701 20.7731 3.60954 20.9212 3.5927 21.0773C3.57586 21.2333 3.60342 21.3909 3.67221 21.5319C3.74099 21.673 3.84821 21.7918 3.98152 21.8746C4.11482 21.9574 4.2688 22.0008 4.42573 22H9.70906C9.83564 22.0007 9.96071 21.9725 10.0748 21.9177C10.1888 21.8628 10.2889 21.7827 10.3674 21.6833L12.3424 19.1833L17.8257 16.5083C18.1652 16.3306 18.4211 16.0264 18.538 15.6615C18.655 15.2966 18.6236 14.9003 18.4507 14.5583Z"
      />
      <path
        d="M17.8257 17.6084L20.2007 20.6501C20.2964 20.771 20.3567 20.916 20.3748 21.0691C20.393 21.2223 20.3683 21.3774 20.3035 21.5173C20.2387 21.6572 20.1364 21.7764 20.0079 21.8617C19.8794 21.9469 19.7298 21.9948 19.5757 22.0001H14.2757C14.1492 22.0007 14.0241 21.9726 13.91 21.9177C13.796 21.8628 13.6959 21.7827 13.6174 21.6834L12.4341 20.1667L17.8257 17.6084Z"
      />
      <path
        d="M11.9922 2C11.8271 2.00216 11.6694 2.0687 11.5526 2.18544C11.4359 2.30219 11.3693 2.45991 11.3672 2.625V4.29167C11.3672 4.45743 11.433 4.6164 11.5502 4.73361C11.6675 4.85082 11.8264 4.91666 11.9922 4.91666C12.1579 4.91666 12.3169 4.85082 12.4341 4.73361C12.5513 4.6164 12.6172 4.45743 12.6172 4.29167V2.625C12.615 2.45991 12.5485 2.30219 12.4317 2.18544C12.315 2.0687 12.1573 2.00216 11.9922 2Z"
      />
      <path
        d="M20.3922 5.74162C20.275 5.62458 20.1161 5.55884 19.9505 5.55884C19.7849 5.55884 19.626 5.62458 19.5088 5.74162L18.3255 6.92495C18.2637 6.98337 18.2145 7.05377 18.1809 7.13187C18.1472 7.20996 18.1299 7.29409 18.1299 7.37912C18.1299 7.46415 18.1472 7.54828 18.1809 7.62637C18.2145 7.70447 18.2637 7.77487 18.3255 7.83329C18.4446 7.9476 18.6022 8.01303 18.7672 8.01662C18.9325 8.0147 19.0907 7.94902 19.2088 7.83329L20.3922 6.64995C20.454 6.59154 20.5032 6.52113 20.5368 6.44304C20.5705 6.36495 20.5878 6.28082 20.5878 6.19579C20.5878 6.11076 20.5705 6.02662 20.5368 5.94853C20.5032 5.87044 20.454 5.80003 20.3922 5.74162Z"
      />
      <path
        d="M4.49264 5.74172C4.3755 5.62237 4.21575 5.55445 4.04853 5.55288C3.88131 5.55132 3.72032 5.61625 3.60097 5.73339C3.48163 5.85052 3.4137 6.01027 3.41214 6.17749C3.41057 6.34471 3.4755 6.50571 3.59264 6.62505L4.77597 7.83339C4.89407 7.94912 5.05229 8.0148 5.21764 8.01672C5.38264 8.01313 5.54027 7.9477 5.65931 7.83339C5.77635 7.7162 5.84209 7.55735 5.84209 7.39172C5.84209 7.22609 5.77635 7.06724 5.65931 6.95005L4.49264 5.74172Z"
      />
      <path
        d="M4.14287 15.6833C3.97302 16.0314 3.9461 16.4322 4.06787 16.7999C4.13035 16.9809 4.22806 17.1478 4.35535 17.2908C4.48265 17.4338 4.63701 17.5502 4.80953 17.6333L5.89287 18.1666C6.18949 18.3188 6.51449 18.4078 6.84729 18.4279C7.18008 18.4479 7.51342 18.3987 7.8262 18.2833C8.4276 18.0499 8.9127 17.5888 9.1762 16.9999L10.2179 14.6833C10.477 14.1094 10.5028 13.4572 10.2897 12.8647C10.0767 12.2721 9.64147 11.7857 9.0762 11.5083L8.08453 11.0249C7.74148 10.8601 7.34734 10.8367 6.9872 10.9599C6.62705 11.083 6.32978 11.3429 6.15953 11.6833L4.14287 15.6833Z"
      />
    </SvgIcon>
  );
};
export default WorkflowTeamworkHandshakeStreamlineIcon;
