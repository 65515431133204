import React from "react";
import { Box } from "@mui/material";
import SleepioAppIcon from "src/components/icons/SleepioAppIcon";
import DaylightAppIcon from "src/components/icons/DaylightAppIcon";

interface IconProductProps {
  name: string;
}

const iconMap: Record<string, React.ReactNode> = {
  "sleepio": <SleepioAppIcon sx={{ borderRadius: 3, width: 64, height: 64 }} />,
  "daylight": <DaylightAppIcon sx={{ borderRadius: 3, width: 64, height: 64 }} />
};

const IconProduct: React.FC<IconProductProps> = ({ name }) => {
  return (
    <Box>
      {iconMap[name] || iconMap["sleepio"]}
    </Box>
  );
};

export default IconProduct;
