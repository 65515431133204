import styled from "@emotion/styled";
import { Box, css } from "@mui/material";
import DaylightAppIconOriginal from "../icons/DaylightAppIcon";
import SleepioAppIcon from "../icons/SleepioAppIcon";

export const SleepioAppStyledIcon = styled(SleepioAppIcon)`
  border-radius: 8px;
  width: 64px;
  height: 64px;
  @media (min-width: 700px) {
    width: 96px;
    height: 96px;
  }
`;

export const DaylightAppIcon = styled(DaylightAppIconOriginal)`
  border-radius: 8px;
  width: 64px;
  height: 64px;
  @media (min-width: 700px) {
    width: 96px;
    height: 96px;
  }
`;

interface BackgroundProps {
  backgroundImageLeft: string;
  backgroundImageRight: string;
}

/* Avoid passing down custom properties to the Box component */
export const Background = styled(Box, { shouldForwardProp: key => !['backgroundImageLeft', 'backgroundImageRight'].includes(key) }) <BackgroundProps>`
  position: relative;
  min-height: 313px;
  width: 100%;
  overflow: hidden;
  &::before {
    content: ''; 
    background-image: url('${(props) => props.backgroundImageLeft}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    position: absolute;
    width: 80%;
    height: 100%;
    transform: translateX(5%) translateX(-45%);
    mask-image: linear-gradient(
      black 98%,
      transparent
    );
  }
  &::after {
    content: '';
    background-image: url('${(props) => props.backgroundImageRight}');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: 90%;
    height: 100%;
    transform-origin: 50% 50%;
    transform: translateX(33%) rotate(0deg);
    mask-image: linear-gradient(
      black 98%,
      transparent
    );
  }
  @media (min-width: 700px) {
    min-height: 400px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 90%;
    &::after {
      width: 100%;
      transform: translateX(160px);
      background-position: right center;
    }
    &::before {
      width: 100%;
      transform: translateX(-190px);
      background-position: left center;
    }
  }
  @media (min-width: 1044px) {
    &::after {
      transform: none;
    }
    &::before {
      transform: translateX(-2%);
    }
  }
`;

export const Container = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.natural99.main};
  position: relative;
  border-radius: 12px;
  overflow: hidden;
`;

export const Content = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  padding: 0 16px 24px 16px;
  & > * {
    max-width: 350px
  }
  @media (min-width: 700px) {
    min-height: 400px;
    padding: 80px 0;
  }
  & .titles {
    margin-bottom: 8px;
    & h1 {
      margin-bottom: 8px;
    }
  }
  & .price {
    margin-top: 8px;
  }
`;

export const RoundedButton = css`
  width: 100%;
  max-width: 400px;
  border-radius: 2rem;
`;
