import { useCallback, useEffect, useState } from "react";
import restApi from "src/rest";
import {
  clearAllCookies,
  deleteFromLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/store";
import { useChat } from "./useChat";
import { useHeap } from "./useHeap";
import { ApiUser, ChatConfig, User } from "src/types";
import { removeUrlQueryParams } from "src/utils/urlManipulation";
import { LOCAL_AUTH_KEY } from "src/constants";

export const useAuthState = () => {
  const localAuthKey = getFromLocalStorage(LOCAL_AUTH_KEY);
  const [user, setUser] = useState<User | null>(null);
  const [isAppReady, setIsAppReady] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [chatConfig, setChatConfig] = useState<ChatConfig | null>(null);

  const { openChat, disableChat } = useChat(isAuth, user, chatConfig, setUser);
  useHeap(isAuth, user);

  const userAdapter = (userData: ApiUser): User => {
    return {
      id: userData.id,
      firstName: userData.first_name,
      lastName: userData.last_name,
      phone: userData.phone,
      userName: userData.user_name,
      email: userData.email,
      chatId: userData.chat_id,
      organization: userData.organization_name,
    };
  };

  const fetchConfig = useCallback(async () => {
    const config = await restApi.get("configs/");
    setChatConfig(config || null);
  }, [setChatConfig]);

  const fetchUserInformation = useCallback(async () => {
    const response = await restApi.get("users/");
    if (response.user?.id) {
      saveToLocalStorage(LOCAL_AUTH_KEY, "true");
      setUser(userAdapter(response.user));
      setIsAuth(true);
    }
  }, [setUser, setIsAuth]);

  const authenticateAuth0 = useCallback(
    async (code: string, state: string) => {
      try {
        const response = await restApi.get(
          `auth/authenticate/?code=${code}&state=${state}`
        );
        const isUserFullyAuthenticated = !response.should_complete_user_info;
        if (response && isUserFullyAuthenticated) {
          await fetchUserInformation();
        }
      } catch (error) {
        throw error;
      }
    },
    [fetchUserInformation]
  );

  const logout = useCallback(async () => {
    try {
      const response = await restApi.post("logout/");
      deleteFromLocalStorage(LOCAL_AUTH_KEY);
      clearAllCookies();
      // set chat config to default values at last ...
      setUser(null);
      // after all local app state call auth0 logout page ...
      const url = response.url;
      window.location.href = url;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const initAut0Login = useCallback(async () => {
    try {
      const response = await restApi.get("auth/login/");
      const url = response.url;
      window.location.href = url;
    } catch (error) {
      console.error("Failed to fetch URL:", error);
      throw error;
    }
  }, []);

  const authenticate = useCallback(async () => {
    await fetchUserInformation();
    removeUrlQueryParams();
  }, [fetchUserInformation]);

  useEffect(() => {
    (async () => {
      try {
        if (!!localAuthKey) {
          await fetchUserInformation();
        }
      } catch {
        console.error("Failed to fetch user information");
      }
      setIsAppReady(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuth && !chatConfig) {
      fetchConfig();
    }
  }, [isAuth, chatConfig, fetchConfig]);

  return {
    isAuth,
    authenticateAuth0,
    user,
    logout,
    setUser,
    openChat,
    initAut0Login,
    authenticate,
    disableChat,
    isAppReady,
  };
};
