import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useThemeColor } from "src/hooks/useThemeColor";

export const HeroWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const HeroContainer = styled(Box)`
  display: flex;
  max-width: 745px; // See if I can leave it as width
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
`;


export const FalseButton = styled(Box)`
  width: 168px;
  height: 40px;
  background: ${() => useThemeColor('bhBlue95')};
  border-radius: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  justify-content: center;
`

export const MainInformation = styled(Box)`
  margin-top:6px;
  display: flex;
  gap: 8px;
  width:220px;
  height: 66px;
  align-items: center;
  gap: 12px;
`

export const ProfilePicture = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const ImagePicture = styled.img`
  max-width: 36px;
  max-height: 36px;
  border-radius: 155px;
  margin-left: 16px;
`

export const TimeRead = styled(Box)`
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 5px 8px;
  background: ${() => useThemeColor('neutral90')};
`
