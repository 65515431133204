import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const FaceIdStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M9.8667 14.3737C10.45 14.9052 11.2108 15.1999 12 15.1999C12.7892 15.1999 13.55 14.9052 14.1334 14.3737"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 9.33337V10.4"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6665 9.33337V10.4"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9999 9.33337V11.4667C11.9999 11.7496 11.8875 12.0209 11.6875 12.221C11.4874 12.421 11.2161 12.5334 10.9332 12.5334H10.3999"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4V4.53333"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8807 6.11902L17.2798 6.71991"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0001 12H19.4668"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6567 17.6569L17.2798 17.28"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20V19.4667"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.34277 17.6569L6.71966 17.28"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12H4.53333"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.34277 6.34314L6.71966 6.72003"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1333 12C6.1333 13.5559 6.75139 15.0481 7.85161 16.1483C8.95182 17.2485 10.444 17.8666 12 17.8666C13.5559 17.8666 15.0481 17.2485 16.1483 16.1483C17.2485 15.0481 17.8666 13.5559 17.8666 12C17.8666 10.444 17.2485 8.95182 16.1483 7.85161C15.0481 6.75139 13.5559 6.1333 12 6.1333C10.444 6.1333 8.95182 6.75139 7.85161 7.85161C6.75139 8.95182 6.1333 10.444 6.1333 12Z"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default FaceIdStreamlineIcon;
