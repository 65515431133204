import { useRef } from "react";
import { Swiper } from "swiper/react";
import "swiper/css";
import { Mousewheel } from "swiper/modules";
import { ProductImage, Container } from "./styled";
import { daylightImages, sleepioImages } from "./utils/imagesKind";
import CustomTitle from "../CustomTitle/CustomTitle";
import { Box } from "@mui/material";

interface ProductCarouselProps {
  applicationName: "sleepio" | "daylight";
}

export default function ProductCarousel({
  applicationName,
}: ProductCarouselProps) {
  const swiperRef = useRef(null);
  const images = applicationName === "sleepio" ? sleepioImages : daylightImages;

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <CustomTitle
        title="Screenshots"
        color="neutra20.main"
        variant="h3"
        marginBottomOption="24px"
      />

      <Swiper
        ref={swiperRef}
        modules={[Mousewheel]}
        spaceBetween={20}
        slidesPerView="auto"
        mousewheel={{ enabled: true, forceToAxis: true }}
        freeMode={true}
        breakpoints={{
          717: {
            slidesPerView: 3,
          },
          900: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 4,
          },
          1440: {
            slidesPerView: "auto",
          },
        }}
      >
        {images.map((image, index) => (
          <Container key={index}>
            <ProductImage src={image.src} alt={image.alt} />
          </Container>
        ))}
      </Swiper>
    </Box>
  );
}
