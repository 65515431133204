import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const OnlineDoctorPhoneStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M10.9335 11.4666C12.9954 11.4666 14.6668 9.79518 14.6668 7.73332C14.6668 5.67147 12.9954 4 10.9335 4C8.87166 4 7.2002 5.67147 7.2002 7.73332C7.2002 9.79518 8.87166 11.4666 10.9335 11.4666Z"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.71191 5.83472C8.2904 6.43517 8.98404 6.9128 9.75135 7.23903C10.5187 7.56526 11.3439 7.73339 12.1777 7.73338C13.0211 7.73155 13.8554 7.55984 14.631 7.22849"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 20C4 18.1611 4.73047 16.3976 6.03072 15.0974C7.33097 13.7971 9.09449 13.0667 10.9333 13.0667C11.4851 13.0667 12.0301 13.1324 12.5578 13.2596"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4668 19.9999V13.0879"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2002 16.8H9.33352"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2666 15.7333V17.8666"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3698 13.1558H15.1579C14.8671 13.1558 14.6313 13.3915 14.6313 13.6823V19.4735C14.6313 19.7643 14.8671 20 15.1579 20H19.3698C19.6605 20 19.8963 19.7643 19.8963 19.4735V13.6823C19.8963 13.3915 19.6605 13.1558 19.3698 13.1558Z"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8687 18.2478H17.6583"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default OnlineDoctorPhoneStreamlineIcon;
