import { LibraryDetailProps } from "src/types";
import {
  COMMON_SPACING_VALUE,
  FooterText,
  MainContainer,
  ResponsiveFooterWrapper,
  ResponsiveWrapper,
} from "./styled";
import { generateLibraryData } from "src/utils/generateLibraryData";
import Questions from "../QuestionsComponent";
import KeepReading from "../KeepReading";
import { Box } from "@mui/material";
import { HeadingHeroModal } from "../HeadingHeroModal";
import { useEffect } from "react";
import { APP_VERSION } from "src/constants";

export const LibraryDetail = ({ book, openChat }: LibraryDetailProps) => {
  const libraryData = generateLibraryData();

  useEffect(() => {
    window.scrollOnModalViewToTop && window.scrollOnModalViewToTop();
  }, [book]);

  return (
    <MainContainer
      sx={{
        backgroundColor: "neutral99.main",
      }}
    >
      <ResponsiveWrapper
        sx={{
          marginTop: {sm: `48px`},
          marginBottom: `48px`,
        }}
      >
        <HeadingHeroModal {...libraryData[book]?.heading} />
      </ResponsiveWrapper>

      <ResponsiveWrapper>{libraryData[book]?.content}</ResponsiveWrapper>
      <ResponsiveWrapper style={{ padding: "0px" }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: `${840 + COMMON_SPACING_VALUE}px`,
          }}
        >
          <KeepReading
            carouselPros={{
              slidesOffsetBefore: COMMON_SPACING_VALUE,
              slidesOffsetAfter: COMMON_SPACING_VALUE,
            }}
            titleStyles={{ padding: `0px ${COMMON_SPACING_VALUE}px` }}
            availableBooks={libraryData[book]?.keepReadingContent}
          />
        </Box>
      </ResponsiveWrapper>
      <ResponsiveFooterWrapper
        sx={{
          backgroundColor: "natural95.main",
        }}
      >
        <Questions onClick={openChat} />
        <FooterText variant="body2" color={"neutral60.main"}>
          &copy; 2024 Big Health Version {APP_VERSION}
        </FooterText>
      </ResponsiveFooterWrapper>
    </MainContainer>
  );
};
