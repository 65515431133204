import { SxProps, Theme, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Wrapper } from "./styled";
import LibraryCard from "../LibraryCard";
import { CSSProperties, useCallback, useMemo } from "react";
import { Swiper as SwiperTypes } from "swiper";
import { BookType } from "src/types";
import DATA_PROVIDER from "../LibraryCard/utils/dataProvider";
import { useNavigate } from "react-router-dom";

export default function KeepReading({
  availableBooks,
  titleStyles,
  carouselPros,
}: KeepReedingProps) {
  const navigate = useNavigate();
  const smoothNavigationBook = useCallback(
    (book: string) => {
      return () => {
        if (book) navigate(`/library/${book}`, { replace: true });
      };
    },
    [navigate]
  );

  const cards = useMemo(() => {
    return availableBooks
      .filter((book) => !!DATA_PROVIDER[book])
      .map((book) => DATA_PROVIDER[book]);
  }, [availableBooks]);

  return (
    <Wrapper>
      <Typography variant="h3" sx={titleStyles}>
        Keep reading
      </Typography>
      <Swiper slidesPerView="auto" spaceBetween={24} {...carouselPros}>
        {cards.map((card) => (
          <SwiperSlide key={card.title} style={{ height: 'auto', padding: '4px 0px' }}>
            <LibraryCard
              {...card}
              onClick={smoothNavigationBook(card.book as string)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}
interface KeepReedingProps {
  availableBooks: BookType[];
  carouselPros?: Partial<SwiperTypes["params"]> & { style?: CSSProperties };
  titleStyles?: SxProps<Theme>;
}
