import { useEffect, useCallback } from "react";
import { User } from "src/types";
import { HEAP_APP_ID } from "src/configs/envVars";

declare global {
  interface Window {
    heap: any;
  }
}

export function useHeap(
  isAuth: boolean, 
  user: User | null
) {

  const w = window as Window & typeof globalThis;


  const identify = useCallback(() => {
    w.heap.identify(user?.id);
  },[w, user]);

  const addUserProperties = useCallback((properties: any) => {
    w.heap.addUserProperties(properties);
  },[w]);

  const loadUserData = useCallback(() => {
    addUserProperties({ id: user?.id, email: user?.email, username: user?.userName, organization: user?.organization });
  },[user, addUserProperties]);

  const addEventProperties = useCallback((properties: any) => {
    w.heap.addEventProperties(properties);
  },[w]);

  const track = useCallback((eventName: string, properties: any) => {
    w.heap.track(eventName, properties);
  },[w]);

  const clearEventProperties = useCallback(() => {
    w.heap.clearEventProperties();
  },[w]);

  const resetIdentity = useCallback(() => {
    w.heap.resetIdentity();
  },[w]);

  const removeEventProperty = useCallback((property: string) => {
    w.heap.removeEventProperty(property);
  },[w]);

  const setEventProperties = useCallback((properties: any) => {
    w.heap.setEventProperties(properties);
  },[w]);

  const unsetEventProperty = useCallback((property: string) => {
    w.heap.unsetEventProperty(property);
  },[w]);

  const loadHeap = useCallback ((appId: string, config?: any) => {
    w.heap = w.heap || [];
    w.heap.appid = appId;
    w.heap.config = config = config || {};
    for (var n = function (param: string) {
      return function () {
        w.heap.push([param].concat(Array.prototype.slice.call(arguments, 0)))
      }
    },
      parameters = [
        "addEventProperties",
        "addUserProperties",
        "clearEventProperties",
        "identify",
        "resetIdentity",
        "removeEventProperty",
        "setEventProperties",
        "track",
        "unsetEventProperty"
      ],
      o = 0;
      o < parameters.length;
      o++) {
      w.heap[parameters[o]] = n(parameters[o])
    };
  }, [w]);
  
  const initialize = useCallback((document: Document, scriptId: string) => {
    var e;
    if (!document.getElementById(scriptId)) {
      e = document.createElement("script");
      e.type = "text/javascript";
      e.id = scriptId;
      e.async = !0;
      e.src = "https://cdn.heapanalytics.com/js/heap-" + HEAP_APP_ID + ".js";
      var a = document.getElementsByTagName("script")[0];
      if (a && a.parentNode) {
        a.parentNode.insertBefore(e, a);
      }
      loadHeap(HEAP_APP_ID);
    }
  }, [loadHeap])


  useEffect(() => {
    initialize(document, "Heap-js");
  }, [initialize])

  useEffect(() => {
    if (isAuth && user) {
      identify();
      loadUserData();
    } else {
      resetIdentity();
      clearEventProperties();
    }
  }, [isAuth, user, clearEventProperties, identify, loadUserData, resetIdentity]);
  
  return { addUserProperties, resetIdentity, addEventProperties, track, clearEventProperties, removeEventProperty, setEventProperties, unsetEventProperty};
}