import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const AndroidStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M15.4103 17.591H8.47016C8.16339 17.591 7.86918 17.4692 7.65226 17.2522C7.43534 17.0353 7.31348 16.7411 7.31348 16.4343V11.8076H16.5669V16.4343C16.5669 16.7411 16.4451 17.0353 16.2282 17.2522C16.0112 17.4692 15.717 17.591 15.4103 17.591Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9402 4.86755C10.7131 4.86755 9.53629 5.35501 8.66861 6.22269C7.80093 7.09037 7.31348 8.2672 7.31348 9.49428H16.5669C16.5669 8.2672 16.0795 7.09037 15.2118 6.22269C14.3441 5.35501 13.1673 4.86755 11.9402 4.86755Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15.2777V14.121C5 13.5074 5.24373 12.919 5.67757 12.4852C6.11141 12.0513 6.69982 11.8076 7.31336 11.8076H16.5668C17.1804 11.8076 17.7688 12.0513 18.2026 12.4852C18.6365 12.919 18.8802 13.5074 18.8802 14.121V15.2777"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.47021 17.5911V20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4102 17.5911V20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.81612 6.08049L6.73486 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.064 6.08049L17.1452 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default AndroidStreamlineIcon;
