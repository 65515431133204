import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

export const useDeviceSize = () => {
  const themeState = useTheme();
  const smBreakpointValue = themeState.breakpoints.values.sm;
  // Use orientation media queries
  const isPortrait = useMediaQuery("(orientation: portrait)");
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const isMobileHeightSize = useMediaQuery(
    `(max-height: ${smBreakpointValue}px)`
  );
  const isMobileWidthSize = useMediaQuery(themeState.breakpoints.down("sm"));
  const isMobileSize = useMemo(() => {
    return (
      (isMobileHeightSize && isLandscape) ||
      (isMobileWidthSize && isPortrait)
    );
  }, [isMobileHeightSize, isMobileWidthSize, isPortrait, isLandscape]);

  return {
    isMobileSize,
    isMobileWidthSize,
    isMobileHeightSize,
    isPortrait,
    isLandscape,
  };
};
