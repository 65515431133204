import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useThemeColor } from "src/hooks/useThemeColor";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Footer = styled(Box)`
  border-radius: 12px;
  height: 154px;
  overflow: hidden;
`;

export const Flag = styled(Box)`
  text-transform: capitalize;
  color: black;
  border-radius: 56px;
  padding: 8px 12px;
  background: ${() => useThemeColor("bhBlue95")};
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px;
`;
