import { Link, Typography } from "@mui/material";
import React from "react";
import { DAYLIGHT_PRODUCT, SLEEPIO_PRODUCT } from "src/constants";

export const generateProductDetailData = () => {
  const navigateToProduct = (uri: string) => {
    return () => window.open(uri, "_blank");
  };
  return {
    [SLEEPIO_PRODUCT]: {
      header: {
        product: SLEEPIO_PRODUCT,
        backgroundImageLeft: "/assets/images/sleepiophone1.png",
        backgroundImageRight: "/assets/images/sleepiophone2.png",
        buttonText: "Get Sleepio app",
        description:
          "Sleepio is covered in full by your employer at no extra cost.",
        title: "Sleepio app",
        subtitle: "Clear your mind, get better sleep, have better days.",
        price: "$0.00",
        onClick: navigateToProduct("https://sleepio.com"),
      },
      mainInformation: {
        title: "Better sleep awaits",
        description: (
          <>
            Struggling to sleep? You're not alone. Sleepio offers a free, six-week program to help you get the rest you need. Our clinically proven methods, backed by cognitive behavioral therapy, can improve your sleep without side effects.
            <br />
            <br />
            Plus, it's available 24/7 at no cost to you. With 54% falling asleep faster and 62% spending less time awake at night, Sleepio could be the solution you've been looking for. Ready to start sleeping better?
          </>
        ),
      },
      chips: [
        {
          icon: "calendar",
          label: "6 Week Course",
        },
        {
          icon: "24h",
          label: "Available 24/7",
        },
        {
          icon: "handHoldingGear",
          label: "Customizable",
        },
        {
          icon: "padlock",
          label: "Secure & Private",
        },
        {
          icon: "apple",
          label: "Apple",
        },
        {
          icon: "android",
          label: "Android",
        },
      ],
      complements: [
        {
          title: "How it works",
          iconName: "FaceIdStreamlineIcon",
          content: (
            <React.Fragment>
              <Typography>
                Sleepio is an online sleep improvement program proven to help you
                clear your mind, get better sleep, and have better days.
              </Typography>
              <br />
              <Typography>
                Sleepio isn’t a one-size-fits-all approach. It incorporates your
                unique needs to create a personalized six-week program that you
                complete at your own pace. Each session builds on the last, guiding
                you step-by-step through methods proven to help you quiet your
                racing mind, reshape your behaviors, and get better sleep — all in
                about 20 minutes per week.
              </Typography>
              <br />
              <Typography>
                The program uses cognitive behavioral techniques that are backed by
                decades of clinical research, and has undergone more testing than
                some of the most common sleep medications. In 12 randomized
                controlled trials with over 13,000 people, Sleepio was proven to
                help participants fall asleep 54% faster, spend 62% less time awake
                at night, and have 45% better functioning the next day. You’ll have
                access to all of this clinical evidence from directly within the
                app.
              </Typography>
            </React.Fragment>
          ),
        },
        {
          title: "Key features",
          iconName: "MedicalFilesMedicalCheckStreamLineUltimate",
          content: (
            <React.Fragment>
              <Typography>
                - A personalized program of clinically proven cognitive and
                behavioral techniques, designed to get your sleep schedule,
                thoughts, and lifestyle into shape over six tailored sessions.
              </Typography>
              <br />
              <Typography>
                - A comprehensive onboarding approach with an in-depth questionnaire
                that shapes your tailored program experience.
              </Typography>
              <br />
              <Typography>
                - A Sleep Diary to track your progress and monitor your sleep
                efficiency as you work towards your sleep goals.
              </Typography>
              <br />
              <Typography>
                - Instant, bite-sized support through the “Help Me Now” feature to
                get back on track after a long night or rough morning.
              </Typography>
            </React.Fragment>
          ),
        },
        {
          title: "Clinical results",
          iconName: "OnlineDoctorPhoneStreamlineIcon",
          content: (
            <React.Fragment>
              <Typography>
                “The most effective, long-term solution for poor sleep is a
                cognitive and behavioral approach, which addresses the mental and
                behavioral parts of the individual's sleep pattern,” shared
                Professor Espie. “These approaches help the person to think and
                behave differently in relation to their sleep, which encourages good
                sleep to return. Sleepio is a clinically proven digital sleep
                improvement program that uses cognitive and behavioral techniques to
                help users with persistent sleep problems.”
              </Typography>
            </React.Fragment>
          )
        },
        {
          title: "Data and privacy",
          iconName: "LockShieldStreamlineIcon",
          content: (
            <React.Fragment>
              <Typography>
                At Big Health, we prioritize your privacy and are committed to
                safeguarding your personal and health information. Our Privacy
                Policy outlines how we collect, use, retain, and disclose your
                Personally Identifiable Information (PII) and Protected Health
                Information (PHI) through our mobile digital therapeutic
                applications and websites. We operate in compliance with
                applicable data protection laws and are registered as a Data
                Controller with the UK Information Commissioner’s Office (Reg No.
                Z2141968). By using our apps or websites, you consent to our data
                practices as described in our Privacy Policy. For detailed
                information on how we handle your data, please review our full
                Privacy Policy <Link href="https://www.bighealth.com/privacy" target="_blank">here</Link>.
              </Typography>
            </React.Fragment>
          ),
        },
      ],
      footer: {
        buttonText: "Get Sleepio app",
        description:
          "Sleepio is covered in full by your employer at no extra cost.",
        name: SLEEPIO_PRODUCT,
        price: "$0.00",
        title: "Start your journey to better sleep",
        onClick: navigateToProduct("https://sleepio.com"),
      },
    },
    [DAYLIGHT_PRODUCT]: {
      header: {
        product: DAYLIGHT_PRODUCT,
        backgroundImageLeft: "/assets/images/daylightphone1.png",
        backgroundImageRight: "/assets/images/daylightphone2.png",
        buttonText: "Get Daylight app",
        description:
          "Daylight is covered in full by your employer at no extra cost.",
        title: "Daylight app",
        subtitle: "Your expert guide to managing worry and anxiety",
        price: "$0.00",
        onClick: navigateToProduct("https://trydaylight.com"),
      },
      mainInformation: {
        title: "Worry less",
        description: (
          <>
            Experience the transformative power of Daylight, a digital program designed to help you manage and reduce anxiety effectively. Tailored to fit into your busy life, Daylight offers personalized, expert-developed techniques that you can access anytime, anywhere.
            <br />
            <br />
            In just 10 minutes or less each day, you can learn to understand and combat anxiety, all from the comfort and privacy of your own phone. Start your journey towards a worry-free life with Daylight today.
          </>
        )
      },
      chips: [
        {
          icon: "dateTime",
          label: "10mins daily",
        },
        {
          icon: "24h",
          label: "Available 24/7",
        },
        {
          icon: "handHoldingGear",
          label: "Customizable",
        },
        {
          icon: "padlock",
          label: "Secure & Private",
        },
        {
          icon: "apple",
          label: "Apple",
        },
        {
          icon: "android",
          label: "Android",
        },
      ],
      complements: [
        {
          title: "How it works",
          iconName: "FaceIdStreamlineIcon",
          content: (
            <React.Fragment>
              <Typography>
                Meet Daylight: A digital program that helps you understand where
                anxiety comes from, what makes it worse, and how to reduce it for
                good. All in 10 minutes or less per day, on your schedule, from the
                privacy of your own phone.
              </Typography>
              <br />
              <Typography>
                Daylight was developed by experts who understand where anxiety comes
                from and what makes it worse — clinical psychologists, researchers,
                and the world’s most trusted academic institutions. It’s been proven
                safe and effective in clinical trials, just like any medicine you’d
                get from the pharmacy. In a clinical trial, people who used Daylight
                experienced less anxiety in three weeks and after 10 weeks, 71%
                achieved clinical improvement in anxiety.
              </Typography>
            </React.Fragment>
          ),
        },
        {
          title: "Key features",
          iconName: "MedicalFilesMedicalCheckStreamLineUltimate",
          content: (
            <React.Fragment>
              <Typography>
                - Less than 10 minutes per day: Bite-sized, interactive smartphone
                sessions are easily incorporated into your daily routine.
              </Typography>
              <br />
              <Typography>
                - Let Daylight keep you on track: You’ll get in-the-moment
                suggestions based on your individual needs and regular check-ins to
                guide your progress.
              </Typography>
              <br />
              <Typography>
                - Feel better, stay better: Proven cognitive and behavioral
                techniques teach you life-long skills to reduce tension, re-frame
                anxious thoughts, and manage anxiety for good.
              </Typography>
            </React.Fragment>
          ),
        },
        {
          title: "Clinical results",
          iconName: "OnlineDoctorPhoneStreamlineIcon",
          content: (
            <React.Fragment>
              <Typography>
                "Daylight was incredibly easy to use and I especially enjoyed the
                minimal time commitment – I could complete a session in 5-10 minutes
                when I was feeling anxious or stressed...Thanks to the techniques
                learned through Daylight, I feel like I’m more in control of my
                anxiety."
                <br />– Julia Santos, Daylight User
              </Typography>
            </React.Fragment>
          ),
        },
        {
          title: "Data and privacy",
          iconName: "LockShieldStreamlineIcon",
          content: (
            <React.Fragment>
              <Typography>
                At Big Health, we prioritize your privacy and are committed to
                safeguarding your personal and health information. Our Privacy
                Policy outlines how we collect, use, retain, and disclose your
                Personally Identifiable Information (PII) and Protected Health
                Information (PHI) through our mobile digital therapeutic
                applications and websites. We operate in compliance with
                applicable data protection laws and are registered as a Data
                Controller with the UK Information Commissioner’s Office (Reg No.
                Z2141968). By using our apps or websites, you consent to our data
                practices as described in our Privacy Policy. For detailed
                information on how we handle your data, please review our full
                Privacy Policy <Link href="https://www.bighealth.com/privacy" target="_blank">here</Link>.
              </Typography>
            </React.Fragment>
          ),
        },
      ],
      footer: {
        buttonText: "Get Daylight app",
        description:
          "Daylight is covered in full by your employer at no extra cost.",
        name: DAYLIGHT_PRODUCT,
        price: "$0.00",
        title: "Start managing your worry and anxiety",
        onClick: navigateToProduct("https://trydaylight.com"),
      },
    },
  };
};
