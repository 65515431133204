import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const PhoneActions24SupportStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M12.2633 19.4282L12.2704 19.4332C12.8848 19.8244 13.6143 19.9943 14.3384 19.9147C15.0624 19.8352 15.7377 19.5111 16.2526 18.9958L16.6998 18.5486C16.799 18.4495 16.8776 18.3319 16.9312 18.2024C16.9849 18.073 17.0125 17.9342 17.0125 17.7941C17.0125 17.654 16.9849 17.5152 16.9312 17.3857C16.8776 17.2563 16.799 17.1387 16.6998 17.0396L14.8133 15.1545C14.7142 15.0554 14.5966 14.9768 14.4672 14.9231C14.3377 14.8695 14.199 14.8418 14.0588 14.8418C13.9187 14.8418 13.7799 14.8695 13.6505 14.9231C13.521 14.9768 13.4034 15.0554 13.3044 15.1545C13.1043 15.3545 12.8331 15.4668 12.5502 15.4668C12.2674 15.4668 11.9961 15.3545 11.7961 15.1545L8.77965 12.1373C8.57969 11.9373 8.46735 11.666 8.46735 11.3832C8.46735 11.1004 8.57969 10.8291 8.77965 10.6291C8.87877 10.53 8.9574 10.4124 9.01105 10.283C9.0647 10.1535 9.09231 10.0147 9.09231 9.87462C9.09231 9.73448 9.0647 9.59573 9.01105 9.46627C8.9574 9.33682 8.87877 9.2192 8.77965 9.12014L6.89383 7.23503C6.6938 7.03506 6.42255 6.92273 6.13971 6.92273C5.85687 6.92273 5.58562 7.03506 5.38559 7.23503L4.9376 7.68231C4.42248 8.19727 4.09845 8.87256 4.01907 9.5966C3.93968 10.3206 4.10969 11.0501 4.50099 11.6644L4.50526 11.6716C6.57183 14.7291 9.20537 17.3622 12.2633 19.4282Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5337 6.59997C12.747 5.95998 13.0051 5 14.1841 5C14.595 5.00019 14.989 5.1635 15.2796 5.45404C15.5701 5.74458 15.7334 6.13859 15.7336 6.54948C15.7331 6.92279 15.5848 7.28072 15.3212 7.54501L12.5337 10.3332H15.7336"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0001 9.26657H17.3335L20.0001 5V10.3332"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default PhoneActions24SupportStreamlineIcon;
