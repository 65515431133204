import { Grid } from "@mui/material";
import { AccountMenuProps } from "src/types";
import HeaderDropDownMenu from "./HeaderDropDownMenu";

interface HeaderProps extends AccountMenuProps {
  customButton?: React.ReactNode;
}

export default function Header({
  user,
  onLogoutClick = async () => {},
  customButton,
}: HeaderProps) {
  return (
    <Grid
      container
      spacing={0}
      direction={"row"}
      justifyContent={"space-between"}
      style={{ padding: "8px 0px" }}
    >
      <Grid item display={"flex"} alignItems={"center"}>
        <img
          style={{ width: "19.38px", height: "19.37px", marginRight: "0.5em" }}
          src="/assets/images/BigHealthIcon.png"
          alt="logo"
        />
        <img
          style={{ height: "17px", width: "79.99px" }}
          src="/assets/svg/BhNameSvg.svg"
          alt="title"
        />
      </Grid>
      {customButton
        ? customButton
        : user && (
            <Grid item>
              <HeaderDropDownMenu user={user} onLogoutClick={onLogoutClick} />
            </Grid>
          )}
    </Grid>
  );
}
