import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const LaptopStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M18.3999 13.9999V7.06665C18.3999 6.78376 18.2875 6.51245 18.0875 6.31241C17.8874 6.11238 17.6161 6 17.3332 6H6.66675C6.38385 6 6.11255 6.11238 5.91251 6.31241C5.71248 6.51245 5.6001 6.78376 5.6001 7.06665V13.9999H18.3999Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9081 17.3001C19.9803 17.4625 20.0107 17.6404 19.9967 17.8175C19.9826 17.9947 19.9245 18.1655 19.8276 18.3145C19.7308 18.4635 19.5982 18.5859 19.442 18.6707C19.2858 18.7554 19.1109 18.7998 18.9332 18.7998H5.06678C4.88907 18.7998 4.71417 18.7554 4.55797 18.6707C4.40176 18.5859 4.26921 18.4635 4.17235 18.3145C4.07549 18.1655 4.0174 17.9947 4.00334 17.8175C3.98929 17.6404 4.01972 17.4625 4.09187 17.3001L5.60011 13.9999H18.3999L19.9081 17.3001Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9331 16.6665H13.0664"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default LaptopStreamlineIcon;
