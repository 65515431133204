import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function LogOutIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <g fill="#000" stroke="#000" clipPath="url(#a)">
        <path d="M13.914 17.033h.5v-.817h.633v1.133a.317.317 0 0 1-.316.317H8.514v1.317c0 .221-.22.374-.428.297l-6.533-2.45a.317.317 0 0 1-.205-.297V3.466c0-.132.082-.25.205-.296L8.086.72a.317.317 0 0 1 .428.296v1.317h6.217c.175 0 .316.142.316.317v1.133h-.633v-.817h-5.9v14.067h5.4ZM6.071 9.34a1.317 1.317 0 1 0-2.28 1.317A1.317 1.317 0 0 0 6.07 9.34Z" />
        <path d="m15.308 13.136-.447-.447 1.518-1.52.854-.853h-6.085v-.633h6.085l-.854-.854L14.86 7.31l.448-.447L18.445 10l-3.137 3.136Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
