import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LoadingBH from "../LoadingBH";
import {
  FadeContainer,
  FormContainer,
  HeaderContainer,
  LoadingScreenContainer,
  MainContainer,
} from "./styled";
import { useNavigate } from "react-router-dom";
import OnBoardingForm from "../OnBoardingForm";
import { FooterContainer, FooterText } from "src/styled";
import { removeUrlQueryParams } from "src/utils/urlManipulation";
import { APP_VERSION } from "src/constants";

export default function CallbackView({
  authenticateAuth0,
  authenticate,
  logout,
}: {
  authenticateAuth0: (code: string, state: string) => Promise<void>;
  authenticate: () => void;
  logout: () => void;
}) {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const onBoardingFormRef = useRef<{ goBack: () => void }>();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code") ?? "";
    const state = queryParams.get("state") ?? "";

    if (!code || !state) {
      removeUrlQueryParams();
      return navigate("/login", { replace: true });
    }

    setIsAuthenticating(true);
    const fetchAuth = async () => {
      try {
        await authenticateAuth0(code, state);
        // Hide loading screen after short delay
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setIsAuthenticating(false);
      } catch (error) {
        console.error("Failed to authorize:", error);
        removeUrlQueryParams()
        // Redirect after short delay
        await new Promise((resolve) => setTimeout(resolve, 1500));
        logout();
      }
    };

    fetchAuth();
  }, [authenticateAuth0, navigate, logout]);

  return (
    <MainContainer>
      <FadeContainer in={isAuthenticating} timeout={500}>
        <LoadingScreenContainer>
          <LoadingBH message="Authenticating..." />
        </LoadingScreenContainer>
      </FadeContainer>
      {!isAuthenticating && (
        <FadeContainer in={!isAuthenticating}>
          <Box>
            <HeaderContainer>
              <img src="/assets/svg/TextLogo.svg" alt="logo" />
            </HeaderContainer>
            <FormContainer>
              <OnBoardingForm
                ref={onBoardingFormRef}
                authenticate={authenticate}
                logout={logout}
              ></OnBoardingForm>
              <FooterContainer>
                <FooterText variant="body3" color={"neutral40.main"}>
                  &copy; 2024 Big Health Version {APP_VERSION}
                </FooterText>
              </FooterContainer>
            </FormContainer>
          </Box>
        </FadeContainer>
      )}
    </MainContainer>
  );
}
