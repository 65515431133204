import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ProductWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
`;

export const Container = styled(Box)`
  display: flex;
  max-width: 360px; 
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
`;

