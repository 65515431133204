import { CircularProgress, Grid, Typography } from "@mui/material";
import { LoadingContent } from "./styled";

export default function LoadingBH({message="Loading..."}:{message?:string}) {
  return (
    <LoadingContent>
      <Grid item display={"flex"} alignItems={"center"}>
        <img
          style={{ width: "31px", height: "31px", marginRight: "0.5em" }}
          src="/assets/images/BigHealthIcon.png"
          alt="logo"
        />
        <img
          style={{ height: "27px", width: "128px" }}
          src="/assets/svg/BhNameSvg.svg"
          alt="title"
        />
      </Grid>
      <CircularProgress color="bhYellow40" style={{ width: '40px', margin: '48px 0' }} />
      <Typography variant="h4Medium" color="neutral40.main">{message}</Typography>
    </LoadingContent>
  );
}
