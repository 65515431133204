import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const WorkflowTeamworkCogHandStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M7.19775 8.80234C7.19775 9.08533 7.31017 9.35672 7.51027 9.55682C7.71037 9.75692 7.98176 9.86933 8.26475 9.86933C8.54773 9.86933 8.81912 9.75692 9.01922 9.55682C9.21932 9.35672 9.33174 9.08533 9.33174 8.80234C9.33174 8.51936 9.21932 8.24797 9.01922 8.04787C8.81912 7.84777 8.54773 7.73535 8.26475 7.73535C7.98176 7.73535 7.71037 7.84777 7.51027 8.04787C7.31017 8.24797 7.19775 8.51936 7.19775 8.80234Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17091 4.67443L9.48532 5.70799C9.53706 5.87963 9.65212 6.02518 9.80719 6.11513C9.96226 6.20509 10.1457 6.23271 10.3204 6.1924L11.3675 5.95482C11.566 5.91009 11.7735 5.93009 11.9598 6.01187C12.1461 6.09365 12.3013 6.23293 12.4026 6.4093C12.504 6.58568 12.5463 6.78988 12.5232 6.992C12.5001 7.19413 12.413 7.38356 12.2744 7.53255L11.5411 8.31785C11.4187 8.44946 11.3506 8.62253 11.3506 8.80226C11.3506 8.982 11.4187 9.15506 11.5411 9.28668L12.2744 10.0805C12.413 10.2295 12.5001 10.4189 12.5232 10.6211C12.5463 10.8232 12.504 11.0274 12.4026 11.2038C12.3013 11.3801 12.1461 11.5194 11.9598 11.6012C11.7735 11.683 11.566 11.703 11.3675 11.6582L10.3204 11.4157C10.1457 11.3756 9.96235 11.4033 9.80732 11.4932C9.6523 11.5831 9.5372 11.7285 9.48532 11.9001L9.17091 12.9258C9.11248 13.1208 8.99276 13.2917 8.82951 13.4131C8.66625 13.5346 8.46818 13.6003 8.26468 13.6003C8.06118 13.6003 7.86311 13.5346 7.69985 13.4131C7.5366 13.2917 7.41688 13.1208 7.35845 12.9258L7.04404 11.8944C6.99129 11.7234 6.87603 11.5786 6.72125 11.4888C6.56648 11.3991 6.38355 11.3709 6.20894 11.41L5.16187 11.6526C4.96341 11.6973 4.75584 11.6773 4.56957 11.5955C4.38329 11.5137 4.2281 11.3744 4.12671 11.1981C4.02533 11.0217 3.98307 10.8175 4.00615 10.6154C4.02922 10.4132 4.1164 10.2238 4.25493 10.0748L4.98831 9.28454C5.1107 9.15293 5.17874 8.97986 5.17874 8.80013C5.17874 8.6204 5.1107 8.44733 4.98831 8.31572L4.25493 7.52757C4.1164 7.37858 4.02922 7.18915 4.00615 6.98703C3.98307 6.7849 4.02533 6.5807 4.12671 6.40433C4.2281 6.22795 4.38329 6.08868 4.56957 6.00689C4.75584 5.92511 4.96341 5.90512 5.16187 5.94984L6.20894 6.1924C6.38362 6.23253 6.56701 6.20483 6.72204 6.11491C6.87706 6.02498 6.99216 5.87954 7.04404 5.70799L7.35845 4.67443C7.41688 4.4795 7.5366 4.30861 7.69985 4.18711C7.86311 4.06562 8.06118 4 8.26468 4C8.46818 4 8.66625 4.06562 8.82951 4.18711C8.99276 4.30861 9.11248 4.4795 9.17091 4.67443Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0876 10.0592C15.7144 10.0592 16.5773 11.3567 16.6484 12.8049C16.6696 13.3188 16.4912 13.8209 16.1505 14.2062L15.4434 15.0143C15.2092 15.2817 14.8784 15.4452 14.5238 15.4688C14.1691 15.4924 13.8196 15.3742 13.552 15.1402C13.5299 15.1203 13.5079 15.1004 13.4873 15.079L12.1109 13.7026C11.9222 13.514 11.6663 13.408 11.3995 13.408C11.1327 13.408 10.8769 13.514 10.6882 13.7026C10.4995 13.8913 10.3936 14.1472 10.3936 14.414C10.3936 14.5461 10.4196 14.6769 10.4701 14.7989C10.5207 14.921 10.5948 15.0319 10.6882 15.1253L13.185 17.9571C13.6865 18.4599 13.9679 19.1412 13.9674 19.8513V19.9993"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0016 20V13.4707C19.9792 12.855 19.8803 12.2445 19.7071 11.6533C19.3571 10.4611 18.6132 9.42287 17.597 8.70803C16.5807 7.99319 15.3521 7.64401 14.1118 7.71751"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default WorkflowTeamworkCogHandStreamlineIcon;
