import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ModalCardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: fit-content;
`;
