import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const AppleLogoStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M15.5341 13.441C15.5403 12.9046 15.6823 12.3785 15.9468 11.9118C16.2114 11.4451 16.5899 11.053 17.0469 10.7721C16.7559 10.3585 16.3733 10.0177 15.929 9.77634C15.4846 9.53496 14.9904 9.39952 14.4851 9.38058C13.3933 9.27354 12.3443 10.0228 11.8019 10.0228C11.2596 10.0228 10.3747 9.39486 9.48982 9.40913C8.89508 9.42494 8.31467 9.59506 7.8055 9.90281C7.29633 10.2106 6.8759 10.6454 6.58544 11.1646C5.3509 13.3054 6.27145 16.4881 7.47745 18.2293C8.06261 19.0785 8.76908 20.0347 9.68963 19.9991C10.6102 19.9634 10.917 19.4282 11.9874 19.4282C13.0579 19.4282 13.3647 19.9991 14.2995 19.9848C15.2344 19.9705 15.8623 19.1142 16.4404 18.265C16.8585 17.6585 17.1878 16.9952 17.418 16.2954C16.8584 16.0572 16.3813 15.6595 16.0463 15.1519C15.7113 14.6442 15.5331 14.0492 15.5341 13.441Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0709 6.56185C14.6734 5.84985 14.9731 4.93025 14.9059 4C13.9918 4.08543 13.1458 4.51993 12.5438 5.21313C12.2413 5.55459 12.0113 5.95394 11.8678 6.38696C11.7243 6.81997 11.6702 7.27763 11.7089 7.73217C12.166 7.7311 12.6167 7.62501 13.0262 7.42209C13.4358 7.21917 13.7932 6.92485 14.0709 6.56185Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default AppleLogoStreamlineIcon;
