import styled from "@emotion/styled";
import { Box, css } from '@mui/material';
import Typography from "@mui/material/Typography";
import { globalThemeColors } from '../../theme/globalThemeColors';


export const LinkStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex;
`;

export const TypographyStyled = styled(Typography)`
  margin-top: 0px;
  text-align: start;
`;

export const links = css`
  padding: 0px;
  border-radius: 12px;
  padding: 4px 4px;
  &:active {
    outline: 2px solid;
    outline-color: ${globalThemeColors.primary.natural40};
    outline-offset: 2;
  };
`;