import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const ChatBubbleTextSquareStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_814_352521)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.57144 2C5.00313 2 4.45807 2.22576 4.05621 2.62763C3.65436 3.02949 3.42859 3.57454 3.42859 4.14286V16.8841L2.03667 21.0599C1.95423 21.3071 2.01308 21.5797 2.19021 21.771C2.36735 21.9623 2.63462 22.0419 2.88754 21.9787L8.51651 20.5714H19.8571C20.4254 20.5714 20.9706 20.3457 21.3724 19.9439C21.7743 19.542 22 18.9969 22 18.4286V4.14286C22 3.57453 21.7743 3.02949 21.3724 2.62763C20.9706 2.22576 20.4254 2 19.8571 2H5.57144ZM7.53571 8.92226C7.53571 8.42914 7.93546 8.0294 8.42857 8.0294H17C17.4931 8.0294 17.8929 8.42914 17.8929 8.92226C17.8929 9.41537 17.4931 9.81511 17 9.81511H8.42857C7.93546 9.81511 7.53571 9.41537 7.53571 8.92226ZM8.42857 12.7563C7.93546 12.7563 7.53571 13.1561 7.53571 13.6492C7.53571 14.1423 7.93546 14.542 8.42857 14.542H14.1429C14.636 14.542 15.0357 14.1423 15.0357 13.6492C15.0357 13.1561 14.636 12.7563 14.1429 12.7563H8.42857Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_352521">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default ChatBubbleTextSquareStreamlineIcon;
