import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BookType, HomeViewProps, ProductType } from "src/types";
import HomeView from "../Views/HomeView";
import { AVAILABLE_BOOKS, AVAILABLE_PRODUCTS } from "src/constants";
import { ModalView } from "../ModalView";
import { ProductDetail } from "../ProductDetail";
import { LibraryDetail } from "../LibraryDetail";
import { removeUrlQueryParams } from "src/utils/urlManipulation";
import theme from "src/theme";
import { useDeviceSize } from "src/hooks/useDeviceSize";

interface HomeNavigationViewProps
  extends Omit<HomeViewProps, "isModalOpen" | "handleCloseModal" | "product"> {}

export const HomeNavigationView = ({
  user,
  logout,
  benefitsData,
  supportData,
  openChat,
  isLoading,
}: HomeNavigationViewProps) => {
  const { isMobileSize } = useDeviceSize();

  const navigate = useNavigate();
  const { product, book } = useParams();
  const [modalContent, setModalContent] = useState<{
    element: JSX.Element | null;
    dynamicContentRenderStateKey?: string;
  }>({
    element: null,
    dynamicContentRenderStateKey: "",
  });

  const isModalOpen = useMemo(() => {
    return (
      (!!product && AVAILABLE_PRODUCTS.includes(product)) ||
      (!!book && AVAILABLE_BOOKS.includes(book))
    );
  }, [product, book]);

  const smoothNavigationToHome = useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  const handleCloseModal = () => {
    smoothNavigationToHome();
  };

  useEffect(() => {
    if (
      (!!product && !AVAILABLE_PRODUCTS.includes(product)) ||
      (!!book && !AVAILABLE_BOOKS.includes(book))
    ) {
      smoothNavigationToHome();
    }

    if (!!product && AVAILABLE_PRODUCTS.includes(product)) {
      setModalContent((prev) => ({
        ...prev,
        dynamicContentRenderStateKey: product,
        element: (
          <ProductDetail openChat={openChat} product={product as ProductType} />
        ),
      }));
    } else if (!!book && AVAILABLE_BOOKS.includes(book)) {
      setModalContent((prev) => ({
        ...prev,
        dynamicContentRenderStateKey: book,
        element: <LibraryDetail openChat={openChat} book={book as BookType} />,
      }));
    }
  }, [product, book, smoothNavigationToHome, openChat]);

  useEffect(() => {
    removeUrlQueryParams();
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      const closeAnimationTime = theme.transitions.duration.leavingScreen;
      setTimeout(() => {
        setModalContent((prev) => ({
          ...prev,
          element: null,
          dynamicContentRenderStateKey: "",
        }));
      }, closeAnimationTime);
    }
  }, [isModalOpen]);
  return (
    <>
      <ModalView
        dynamicContentRenderStateKey={modalContent.dynamicContentRenderStateKey}
        modalRootContainerStyles={{
          ...(isMobileSize && { paddingTop: "0px" }),
        }}
        contentContainerStyles={{
          ...(isMobileSize && {
            maxHeight: "100%",
            borderRadius: "0px",
          }),
        }}
        isOpen={isModalOpen}
        onCloseModal={handleCloseModal}
      >
        {modalContent.element}
      </ModalView>
      <HomeView
        benefitsData={benefitsData}
        supportData={supportData}
        openChat={openChat}
        logout={logout}
        user={user}
        navigateToHome={smoothNavigationToHome}
        isLoading={isLoading}
      />
    </>
  );
};
