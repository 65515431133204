import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const MedicalAppSmartphoneStreamlineIcon = ({
  color,
  ...props
}: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M13.5333 15.7333V18.0565C13.5334 18.3118 13.4832 18.5645 13.3856 18.8004C13.288 19.0362 13.1448 19.2505 12.9643 19.431C12.7838 19.6115 12.5695 19.7546 12.3337 19.8522C12.0979 19.9499 11.8451 20.0001 11.5899 20H6.94347C6.68822 20.0001 6.43546 19.9499 6.19962 19.8522C5.96379 19.7546 5.74951 19.6115 5.56902 19.431C5.38853 19.2505 5.24538 19.0362 5.14775 18.8004C5.05011 18.5645 4.99991 18.3118 5 18.0565V7.01012C4.99991 6.75487 5.05011 6.50211 5.14775 6.26627C5.24538 6.03044 5.38853 5.81616 5.56902 5.63567C5.74951 5.45518 5.96379 5.31203 6.19962 5.2144C6.43546 5.11676 6.68822 5.06656 6.94347 5.06665H10.3333"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 16.8136H13.5333"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9335 7.73333C19.9335 7.59188 19.8773 7.45623 19.7773 7.35621C19.6773 7.25619 19.5416 7.2 19.4002 7.2H16.7335V4.53333C16.7335 4.39188 16.6773 4.25623 16.5773 4.15621C16.4773 4.05619 16.3416 4 16.2002 4H14.0668C13.9254 4 13.7897 4.05619 13.6897 4.15621C13.5897 4.25623 13.5335 4.39188 13.5335 4.53333V7.2H10.8668C10.7254 7.2 10.5897 7.25619 10.4897 7.35621C10.3897 7.45623 10.3335 7.59188 10.3335 7.73333V9.86667C10.3335 10.0081 10.3897 10.1438 10.4897 10.2438C10.5897 10.3438 10.7254 10.4 10.8668 10.4H13.5335V13.0667C13.5335 13.2081 13.5897 13.3438 13.6897 13.4438C13.7897 13.5438 13.9254 13.6 14.0668 13.6H16.2002C16.3416 13.6 16.4773 13.5438 16.5773 13.4438C16.6773 13.3438 16.7335 13.2081 16.7335 13.0667V10.4H19.4002C19.5416 10.4 19.6773 10.3438 19.7773 10.2438C19.8773 10.1438 19.9335 10.0081 19.9335 9.86667V7.73333Z"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default MedicalAppSmartphoneStreamlineIcon;
