import React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';


interface CustomTitleProps extends TypographyProps {
  title: string;
  marginBottomOption?: string;
  marginTopOption?: string;
}

export default function CustomTitle({ title, color, variant, marginBottomOption, marginTopOption }: CustomTitleProps) {
  return (
    <Box sx={{ marginBottom: `${marginBottomOption || '0px'}`, marginTop: `${marginTopOption || '0px'}` }}>
      <Typography color={color} variant={variant}>
        {title}
      </Typography>
    </Box>
  );
}
