import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const CalendarStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M18.9333 6.1333H5.06667C4.47756 6.1333 4 6.61086 4 7.19997V18.9333C4 19.5224 4.47756 20 5.06667 20H18.9333C19.5224 20 20 19.5224 20 18.9333V7.19997C20 6.61086 19.5224 6.1333 18.9333 6.1333Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10.4H20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2666 7.73333V4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7334 7.73333V4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.46686 13.6C7.31958 13.6 7.2002 13.4806 7.2002 13.3333C7.2002 13.186 7.31958 13.0667 7.46686 13.0667"
        strokeWidth="1.5"
      />
      <path
        d="M7.4668 13.6C7.61408 13.6 7.73346 13.4806 7.73346 13.3333C7.73346 13.186 7.61408 13.0667 7.4668 13.0667"
        strokeWidth="1.5"
      />
      <path
        d="M7.46686 17.3334C7.31958 17.3334 7.2002 17.214 7.2002 17.0667C7.2002 16.9194 7.31958 16.8 7.46686 16.8"
        strokeWidth="1.5"
      />
      <path
        d="M7.4668 17.3334C7.61408 17.3334 7.73346 17.214 7.73346 17.0667C7.73346 16.9194 7.61408 16.8 7.4668 16.8"
        strokeWidth="1.5"
      />
      <path
        d="M12.0001 13.6C11.8528 13.6 11.7334 13.4806 11.7334 13.3333C11.7334 13.186 11.8528 13.0667 12.0001 13.0667"
        strokeWidth="1.5"
      />
      <path
        d="M12 13.6C12.1473 13.6 12.2667 13.4806 12.2667 13.3333C12.2667 13.186 12.1473 13.0667 12 13.0667"
        strokeWidth="1.5"
      />
      <path
        d="M12.0001 17.3334C11.8528 17.3334 11.7334 17.214 11.7334 17.0667C11.7334 16.9194 11.8528 16.8 12.0001 16.8"
        strokeWidth="1.5"
      />
      <path
        d="M12 17.3334C12.1473 17.3334 12.2667 17.214 12.2667 17.0667C12.2667 16.9194 12.1473 16.8 12 16.8"
        strokeWidth="1.5"
      />
      <path
        d="M16.5333 13.6C16.386 13.6 16.2666 13.4806 16.2666 13.3333C16.2666 13.186 16.386 13.0667 16.5333 13.0667"
        strokeWidth="1.5"
      />
      <path
        d="M16.5332 13.6C16.6805 13.6 16.7999 13.4806 16.7999 13.3333C16.7999 13.186 16.6805 13.0667 16.5332 13.0667"
        strokeWidth="1.5"
      />
      <path
        d="M16.5333 17.3334C16.386 17.3334 16.2666 17.214 16.2666 17.0667C16.2666 16.9194 16.386 16.8 16.5333 16.8"
        strokeWidth="1.5"
      />
      <path
        d="M16.5332 17.3334C16.6805 17.3334 16.7999 17.214 16.7999 17.0667C16.7999 16.9194 16.6805 16.8 16.5332 16.8"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
export default CalendarStreamlineIcon;
