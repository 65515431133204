import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChatConfig, User } from "src/types";
import restApi from "src/rest";

declare global {
  interface Window {
    fcWidget: any;
  }
}

export function useChat(
  isAuth: boolean,
  user: User | null,
  config: ChatConfig | null,
  setUser: React.Dispatch<React.SetStateAction<User | null>>
) {
  const isFirstLoad = useRef(true);
  const w = window as Window & typeof globalThis;
  const [canInitChat, setCanInitChat] = useState(false);
  const userRestore = user?.chatId || user?.id;

  const userData = useMemo(() => ({ ...user, id: undefined }), [user]);
  const backgroundRef = useRef("");

  const saveChatRestoreId = useCallback(
    async (id: string) => {
      const response = await restApi.put(`users/${user?.id}/`, {
        chat_id: id,
      });
      if (user?.id) {
        const newUserData = { ...user, chatId: response.chat_id };
        setUser(newUserData);
      }
    },
    [user, setUser]
  );

  const initUser = useCallback(async () => {
    /* 
      To get the necessary data to relate the BH user with the Freshdesk user and preserve the chat history, 
      the user data is fetched from the chat widget. If the user is not created, it will be created.

      If the user is created, we update the user information to keep it in sync with the BH user. 
      If the user is not created, we create a new user with the BH user information. After creating it, 
      we get the Freshchat user's restoreId value to store in the database. With it, we can restore the chat history.
    */
    w.fcWidget?.user.get(function (resp: any) {
      const getUserStatus = resp && resp.status;
      // If status different from 200,the user is not created, create a new user
      if (getUserStatus !== 200) {
        w.fcWidget?.user.create(
          {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            meta: {
              organization: userData.organization,
            },
          },
          (response: any) => {
            if (response.success && response.data.restoreId) {
              saveChatRestoreId(response.data.restoreId as string);
            } else if (!response.success) {
              console.log(
                `Freshchat error, Error code: ${response.status}, Success: ${response.success
                }, Data: ${JSON.stringify(response.data)}`
              );
            }
          }
        );
      } else if (!resp.data.meta.organization) {
        w.fcWidget?.user.update({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          meta: {
            organization: userData.organization,
          },
        });
      }
    });
  }, [saveChatRestoreId, userData, w.fcWidget]);

  const initFreshChat = useCallback(async () => {
    try {
      if (w.fcWidget?.isInitialized()) return;
      if (config) {
        /* 
          The necessary listeners for the chat functionality in the application, as well as the chat itself, will be initialized. 
          To do this, it is validated that the widget's content is present in the application (w.fcWidget) 
          and that no other chat is being executed/initialized (isFirstLoad.current).

          after the first call of the function the value of isFirstLoad.current will be false, that means that 
          the chat is already being initialized and we should not call again the content of this function.

          These validations are necessary because there are occasions when the function is executed more than one time 
          and sometimes before the widget's content is loaded (reasons: internet speed, device/browser speed, others...).

          "isFirstLoad" is managed as a useRef due to the requirement for fast value refresh. In case 
          this function is called twice in quick succession, we want the first call to be the only one that triggers 
          the chat initialization. This means the first call changes the value of the flag, and the second call 
          should be able to read the updated value..
        */
        if (isFirstLoad.current && w.fcWidget) {
          isFirstLoad.current = false;
          const { freshchat_token, freshchat_host } = config;

          w.fcWidget?.on("widget:loaded", function () {
            // After the chat is initialized (this is trigger by w.fcWidget?.init), the user fetch/create can be executed
            initUser();
          });

          w.fcWidget?.on("widget:closed", () => {
            window.document.body.style.backgroundColor = backgroundRef.current;
            const metaTag: HTMLMetaElement | null = document.querySelector(
              "meta[name=theme-color]"
            );
            if (!metaTag) return;
            metaTag.content = backgroundRef.current;
          });

          w.fcWidget?.on("widget:opened", () => {
            const widgetColor = "#ffbf01";
            backgroundRef.current = window.document.body.style.backgroundColor;
            const metaTag: HTMLMetaElement | null = document.querySelector(
              "meta[name=theme-color]"
            );
            if (!metaTag) return;
            metaTag.content = widgetColor;
          });

          w.fcWidget?.on("user:created", function (resp: any) {
            const userCreatedStatus = resp && resp.status,
              data = resp && resp.data;
            if (userCreatedStatus === 200 && data.restoreId) {
              saveChatRestoreId(data.restoreId as string);
            }
          });

          // with this function he chat will be initialized with the user data, this will start triggering some chat listeners like "widget:loaded".
          await w.fcWidget?.init({
            token: freshchat_token,
            host: freshchat_host,
            ...userData,
            meta: {
              organization: userData.organization,
            },
            restoreId: userRestore,
            externalId: user?.id,
          });
        }
      } else {
        throw new Error("No Freshdesk configuration found");
      }
    } catch (error) {
      throw error;
    }
  }, [
    config,
    userData,
    user,
    saveChatRestoreId,
    userRestore,
    initUser,
    w.fcWidget,
  ]);

  const initialize = useCallback(
    (document: Document, scriptId: string) => {
      var e;
      if (document.getElementById(scriptId)) {
        initFreshChat();
      } else {
        e = document.createElement("script");
        e.id = scriptId;
        e.async = !0;
        e.src = "https://bighealth.freshchat.com/js/widget.js";
        e.onload = initFreshChat;
        document.head.appendChild(e);
      }
    },
    [initFreshChat]
  );

  const initChat = useCallback(() => {
    initialize(document, "Freshchat-js-sdk");
  }, [initialize]);

  const destroyChat = useCallback(() => {
    if (!w.fcWidget?.isInitialized()) return;
    w.fcWidget?.destroy();
  }, [w.fcWidget]);

  const disableChat = () => {
    setCanInitChat(true);
    w.fcWidget?.hide();
  };

  const openChat = useCallback(() => {
    if (!w.fcWidget?.isOpen()) {
      w.fcWidget?.open();
    }
  }, [w.fcWidget]);

  useEffect(() => {
    if (isAuth && user && user.organization && config && !canInitChat) {
      // we validate the minimum requirements to initialize the chat before trigger any chat initialization
      initChat();
    } else if (!isAuth) {
      // only if the user is not authenticated, the chat will be destroyed
      destroyChat();
    }
  }, [isAuth, user, config, initChat, destroyChat, canInitChat]);

  return { initChat, destroyChat, openChat, disableChat };
}
