import { IconWithBackgroundColor } from "../IconWithBackgroundColor";
import { useThemeColor } from "src/hooks/useThemeColor";

export default function CardIcon() {
  return (
    <IconWithBackgroundColor
      icon="ChatTwoBubblesOvalStreamlineIcon"
      style={{ fontSize: "20px", color: useThemeColor("bhBlack0") }}
      bgStyle={{
        maxWidth: "auto",
        display: "flex",
        minHeight: "0px",
        width: "32px",
        height: "32px",
        background: useThemeColor("bhYellow40"),
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
      }}
    />
  );
}
