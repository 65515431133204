import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const ConversationChatStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M11.4666 16.8C11.1837 16.8 10.9124 16.6877 10.7123 16.4876C10.5123 16.2876 10.3999 16.0163 10.3999 15.7334V10.4C10.3999 10.1171 10.5123 9.84583 10.7123 9.64579C10.9124 9.44575 11.1837 9.33337 11.4666 9.33337H18.9332C19.2161 9.33337 19.4874 9.44575 19.6875 9.64579C19.8875 9.84583 19.9999 10.1171 19.9999 10.4V15.7334C19.9999 16.0163 19.8875 16.2876 19.6875 16.4876C19.4874 16.6877 19.2161 16.8 18.9332 16.8H17.8666V20L14.6666 16.8H11.4666Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.26667 12.5333L6.13333 14.6667V11.4667H5.06667C4.78377 11.4667 4.51246 11.3543 4.31242 11.1542C4.11238 10.9542 4 10.6829 4 10.4V5.06667C4 4.78377 4.11238 4.51246 4.31242 4.31242C4.51246 4.11238 4.78377 4 5.06667 4H12.5333C12.8162 4 13.0875 4.11238 13.2876 4.31242C13.4876 4.51246 13.6 4.78377 13.6 5.06667V7.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ConversationChatStreamlineIcon;
