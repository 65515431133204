import { Box, Typography, css } from "@mui/material";
import styled from "@emotion/styled";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RightImage = styled(Box)`
  flex: 1;
  overflow: hidden;
`;

export const RightImages = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const LeftImage = styled(Box)`
  flex: 1;
  overflow: hidden;
`;

export const Container = styled(Box)`
  display: flex;
  height: 100%;
`;

export const Footer = styled(Box)`
  text-align: center;
  margin-top: 9px;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
`;

export const ExtraContainer = styled(Box)`
  flex: 1;
  display: flex;
  height: 100%;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const ChatButton = css`
  margin-top: 24px;
  font-weight: 500;
  &.MuiButton-root {
    margin-top: 12px;
  }
`;

export const Title = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 8px;
  color: black;
`;

export const SubTitle = styled(Typography)`
  font-weight: 500;
  opacity: 0.86;
  color: #645d5d;
`;
