import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SparkAppIcon = (props: SvgIconProps) => {
  const color = props.color || "#3D9886";
  return (
    <SvgIcon {...props} style={{ ...props.style, color }}>
      <path
        d="M3.7583 24H20.2417C22.3183 24 24 22.3185 24 20.2423V3.75771C24 1.68148 22.3183 0 20.2417 0H3.7583C1.68175 0 0 1.68148 0 3.75771V20.2423C0 22.3185 1.68175 24 3.7583 24Z"
      />
      <path
        d="M12.1058 13.5211C6.33172 13.5211 4.12988 12.9707 4.12988 12.9707C4.12988 12.9707 6.1419 16.2767 12.1058 16.2767C18.0698 16.2767 20.0818 12.9707 20.0818 12.9707C20.0818 12.9707 17.8762 13.5211 12.1058 13.5211Z"
        fill="#F8F6F7"
      />
      <path
        d="M12.1058 17.9135C6.33172 17.9135 4.12988 17.0215 4.12988 17.0215C4.12988 17.0215 6.1419 19.0598 12.1058 19.0598C18.0698 19.0598 20.0818 17.0215 20.0818 17.0215C20.0818 17.0215 17.8762 17.9135 12.1058 17.9135Z"
        fill="#F8F6F7"
      />
    </SvgIcon>
  );
};
export default SparkAppIcon;
