import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const ShippingLogisticNextDayDeliveryStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M7.11798 6.65129C8.35736 5.26557 10.043 4.3578 11.8821 4.08568C13.7212 3.81356 15.5975 4.19428 17.185 5.16171C18.7726 6.12913 19.9711 7.62213 20.5723 9.38133C21.1736 11.1405 21.1396 13.0548 20.4762 14.7915C19.8128 16.5282 18.562 17.9777 16.9411 18.8881C15.3201 19.7985 13.4315 20.1123 11.6032 19.775C9.77499 19.4377 8.12266 18.4706 6.93331 17.0417C5.74396 15.6128 5.09273 13.8124 5.09277 11.9533V11.1161"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.18605 13.6277L5.09302 11.1161L3 13.6277"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0232 14.8836V9.30188L13.6743 13.6277H17.0232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.06982 11.2207C9.06982 10.7349 9.26282 10.269 9.60635 9.92544C9.94988 9.58191 10.4158 9.38892 10.9016 9.38892C11.2018 9.38884 11.4952 9.4778 11.7448 9.64454C11.9943 9.81128 12.1888 10.0483 12.3037 10.3256C12.4185 10.6029 12.4484 10.9081 12.3898 11.2024C12.3311 11.4968 12.1865 11.7671 11.9741 11.9792L9.06982 14.8835H12.3668"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ShippingLogisticNextDayDeliveryStreamlineIcon;
