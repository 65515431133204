import { Typography } from "@mui/material";
import { DescriptionWrapper } from "./styled";

export default function ProductDescription({title, description}: ProductDescriptionProps) {
  return (
    <DescriptionWrapper>
      <Typography variant="h2Medium">{title}</Typography>
      <Typography color="neutral40.main">{description}</Typography>
    </DescriptionWrapper>
  );
}
interface ProductDescriptionProps {
  title: string;
  description: string | React.ReactNode;
}
