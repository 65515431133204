import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const LoadingScreenContainer = styled(Box)`
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export const MainContainer = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;

  @media (min-width: 1440px) {
    padding: 0px 132px;
  }
`;
