import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CalendarWithClockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2058_43819)">
          <path d="M7.07812 8.74805C6.9325 8.74805 6.81445 8.63 6.81445 8.48438C6.81445 8.33875 6.9325 8.2207 7.07812 8.2207" stroke="currentColor" strokeWidth="1.5" />
          <path d="M7.07812 8.74805C7.22375 8.74805 7.3418 8.63 7.3418 8.48438C7.3418 8.33875 7.22375 8.2207 7.07812 8.2207" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9.89062 8.74805C9.745 8.74805 9.62695 8.63 9.62695 8.48438C9.62695 8.33875 9.745 8.2207 9.89062 8.2207" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9.89062 8.74805C10.0362 8.74805 10.1543 8.63 10.1543 8.48438C10.1543 8.33875 10.0362 8.2207 9.89062 8.2207" stroke="currentColor" strokeWidth="1.5" />
          <path d="M7.07812 11.209C6.9325 11.209 6.81445 11.0909 6.81445 10.9453C6.81445 10.7997 6.9325 10.6816 7.07812 10.6816" stroke="currentColor" strokeWidth="1.5" />
          <path d="M7.07812 11.209C7.22375 11.209 7.3418 11.0909 7.3418 10.9453C7.3418 10.7997 7.22375 10.6816 7.07812 10.6816" stroke="currentColor" strokeWidth="1.5" />
          <path d="M7.07812 13.6699C6.9325 13.6699 6.81445 13.5519 6.81445 13.4062C6.81445 13.2606 6.9325 13.1426 7.07812 13.1426" stroke="currentColor" strokeWidth="1.5" />
          <path d="M7.07812 13.6699C7.22375 13.6699 7.3418 13.5519 7.3418 13.4062C7.3418 13.2606 7.22375 13.1426 7.07812 13.1426" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9.89062 11.209C9.745 11.209 9.62695 11.0909 9.62695 10.9453C9.62695 10.7997 9.745 10.6816 9.89062 10.6816" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9.89062 11.209C10.0362 11.209 10.1543 11.0909 10.1543 10.9453C10.1543 10.7997 10.0362 10.6816 9.89062 10.6816" stroke="currentColor" strokeWidth="1.5" />
          <path d="M12.7031 8.74805C12.5575 8.74805 12.4395 8.63 12.4395 8.48438C12.4395 8.33875 12.5575 8.2207 12.7031 8.2207" stroke="currentColor" strokeWidth="1.5" />
          <path d="M12.7031 8.74805C12.8487 8.74805 12.9668 8.63 12.9668 8.48438C12.9668 8.33875 12.8487 8.2207 12.7031 8.2207" stroke="currentColor" strokeWidth="1.5" />
          <path d="M9.36328 15.6914H5.14453C4.86481 15.6914 4.59654 15.5803 4.39875 15.3825C4.20096 15.1847 4.08984 14.9164 4.08984 14.6367V6.19922C4.08984 5.9195 4.20096 5.65123 4.39875 5.45344C4.59654 5.25565 4.86481 5.14453 5.14453 5.14453H14.6367C14.9164 5.14453 15.1847 5.25565 15.3825 5.45344C15.5803 5.65123 15.6914 5.9195 15.6914 6.19922V9.36328" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.25391 6.19922V4.08984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.5273 6.19922V4.08984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.6914 19.9102C18.0214 19.9102 19.9102 18.0214 19.9102 15.6914C19.9102 13.3615 18.0214 11.4727 15.6914 11.4727C13.3615 11.4727 11.4727 13.3615 11.4727 15.6914C11.4727 18.0214 13.3615 19.9102 15.6914 19.9102Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M17.5547 15.6914H15.6914V13.8281" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_2058_43819">
            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
