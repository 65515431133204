import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const LinkSection = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const IconPlaceholder = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background: lightgray;
`;

export const GridContainerStyled = styled(Box)`
  display: flex;
  align-items: stretch;
  border-radius: 12px;
`;
