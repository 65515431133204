import { Grid } from "@mui/material";
import LibraryCard from ".";
import { DATA_PROVIDER_LIST } from "./utils/dataProvider";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

export default function MyLibraryList() {
  const navigate = useNavigate();
  const smoothNavigationBook = useCallback(
    (book: string) => {
      return () => {
        if (book) navigate(`/library/${book}`, { replace: true });
      };
    },
    [navigate]
  );
  return (
    <Grid container spacing={2}>
      {DATA_PROVIDER_LIST.map((element, key) => (
        <Grid
          key={key}
          item
          xs={6}
          sm={6}
          md={4}
          sx={{
            '@media (max-width:574px)': {
              flexBasis: "100%",
              maxWidth: "100%",
            },
          }}>
          <LibraryCard
            flagText={element.flagText}
            title={element.title}
            content={element.content}
            imageSrc={element.imageSrc}
            onClick={smoothNavigationBook(element.book as string)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
