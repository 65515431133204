import { Box, ButtonProps, Typography } from "@mui/material";
import Card from "../Card";
import { BenefitContent, ButtonContent, CustomButton } from "./styled";
import { useBenefitCardLogic } from "./hooks/useBenefitCardLogic";
import CustomButtonComponent from "../CustomButton";
import { formatId } from "src/utils";

export default function BenefitCard({ color, icon, title, description, buttonColor, style, uri }: BenefitCardProps) {

  const { buttonText, secondButtonText, isGetAppButton, handleClick } = useBenefitCardLogic({ title, uri });

  return (
    <Card style={style} headerContent={icon} color={color} isFlex>
      <BenefitContent>
        <Box mb={2} >
          <Typography variant="h3Medium" color="neutral20.main" mb={1}>{title}</Typography>
          <Typography color="neutral40.main">{description}</Typography>
        </Box>
        {
          isGetAppButton ? (
            <ButtonContent>
              <>
                <CustomButtonComponent
                  variant="contained"
                  color={buttonColor || 'primary'}
                  onClick={() => handleClick(true)}
                  disabled={!uri}
                  id={formatId(`${title}-${buttonText}`)}
                >
                  {buttonText}
                </CustomButtonComponent>
                <CustomButtonComponent
                  variant="contained"
                  color='lightButton'
                  onClick={() => handleClick(false)}
                  disabled={!uri}
                  id={formatId(`${title}-${secondButtonText}`)}
                >
                  {secondButtonText}
                </CustomButtonComponent>
              </>
            </ButtonContent>
          ) : (
            <CustomButtonComponent
              extraStyles={CustomButton}
              variant="contained"
              color={buttonColor || 'primary'}
              onClick={() => handleClick(true)}
              disabled={!uri}
              id={formatId(`${title}-${buttonText}-button`)}
            >
              {buttonText}
            </CustomButtonComponent>
          )
        }
      </BenefitContent>
    </Card>
  );
}

interface BenefitCardProps {
  color: string;
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonColor?: ButtonProps['color'];
  uri?: string;
  style?: React.CSSProperties;
}

