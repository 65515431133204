import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getCsrfToken } from "./store";

let API_SERVER_URL = process.env.REACT_APP_SERVICE_APP_URL;
if (API_SERVER_URL && !API_SERVER_URL.endsWith('/')) {
  API_SERVER_URL += '/';
}

export interface DetailedError extends Error {
  detail?: any; 
}

async function request(config: AxiosRequestConfig) {
  try {
    const csrfToken = getCsrfToken();
    const targetUrl = new URL(config.url as string, API_SERVER_URL);
    const axiosConfig = {
      ...config,
      url: targetUrl.toString(),
      withCredentials: true,
      headers: {
        "X-CSRFToken": csrfToken,
        "Content-Type": "application/json",
        ...config.headers,
      },
    };
    const response = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    const formattedError:DetailedError = Error(
      `Error on ${config.method?.toUpperCase()}: ${axiosError.message}` )
    formattedError.detail = axiosError
    throw formattedError;
  }
}

export const restApi = {
  get: async (url: string, params = {}) => {
    try {
      return await request({ method: "get", url, params });
    } catch (error) {
      throw error;
    }
  },

  post: async (url: string, data = {}) => {
    try {
      return await  request({ method: "post", url, data });
    } catch (error) {
      throw error;
    }
  },

  put: async(url: string, data = {}) => {
    try {
      return await request({ method: "put", url, data });
    } catch (error) {
      throw error;
    }
  },

  del: async(url: string) => {
    try {
      return await request({ method: "delete", url });
    } catch (error) {
      throw error;
    }
  },
};

export default restApi;
