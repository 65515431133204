import React from 'react';
import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { BenefitContent } from '../styled';

export default function LoadingCard({ arrayNumber }: { arrayNumber: number }) {
  return (
    <>
      {
        Array.from(new Array(arrayNumber)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex' }}>
            <Stack spacing={2} sx={{ width: '100%', borderRadius: 2, padding: 2, boxShadow: 0, bgcolor: 'background.paper' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
              <BenefitContent>
                <Skeleton variant="text" sx={{ fontSize: '1.5rem', marginBottom: 1 }} />
                <Skeleton variant="rectangular" sx={{ fontSize: '3rem', marginBottom: 2 }} />
                <Skeleton variant="rectangular" sx={{ fontSize: '2rem', borderRadius: 4 }} />
              </BenefitContent>
            </Stack>
          </Grid>
        ))
      }
    </>
  )
}