import { Fade } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useThemeColor } from "src/hooks/useThemeColor";
import { LoadingScreenContainer, MainContainer } from "../LoginView/styled";
import LoadingBH from "src/components/LoadingBH";

export default function AutoLoginView({ handleLogin: _handleLogin }: { handleLogin: () => Promise<void> }) {
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = useCallback(async () => {
    setIsLoading(true);
    try {
      await _handleLogin();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  }, [_handleLogin]);

  useEffect(() => {
    handleLogin()
  }, [handleLogin])

  return (
    <MainContainer>
      <Fade in={isLoading} timeout={500}>
        <LoadingScreenContainer style={{ backgroundColor: useThemeColor("white") }}>
          <LoadingBH />
        </LoadingScreenContainer>
      </Fade>
    </MainContainer>
  );
}
