import {
  useCallback,
  useEffect,
  useState,
} from "react";
import restApi from "src/rest";
import { Benefits } from "src/types";

export const useBenefits = (
  isAuth: boolean,
) => {
  const [benefits, setBenefits] = useState<Benefits | null>();
  const [isLoading, setIsLoading] = useState(true)

  const fetchBenefits = useCallback(async () => {
    try {
      const response: Benefits = await restApi.get("users/benefits/");
      if (response) {
        setBenefits(response);
      }
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [setBenefits]);

  useEffect(() => {
    if (isAuth && !benefits) fetchBenefits();
  }, [isAuth, fetchBenefits, benefits]);

  return { benefits, fetchBenefits, isLoading };
};
