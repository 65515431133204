import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { globalThemeColors } from "./theme/globalThemeColors";
import "./theme/customPalette";

/*
  We use GT-Walsheim as the primary font for this app.
  There are different versions found in the /public/fonts folder.
  Below is a base implementation of the GT-Walsheim font, but we need to better
    align the font types with the actual font files to match figma designs.
  Example of how to do this is found at https://mui.com/material-ui/customization/typography/#variants
*/
declare module "@mui/material/styles" {
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    white: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    white: {
      main: "#ffffff",
    },
    background: {
      default: globalThemeColors.primary.neutral100,
    },
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    neutral0: {
      main: globalThemeColors.primary.neutral0,
    },
    neutral10: {
      main: globalThemeColors.primary.neutral10,
    },
    neutral20: {
      main: globalThemeColors.primary.neutral20,
    },
    neutral30: {
      main: globalThemeColors.primary.neutral30,
    },
    neutral40: {
      main: globalThemeColors.primary.neutral40,
    },
    neutral50: {
      main: globalThemeColors.primary.neutral50,
    },
    neutral60: {
      main: globalThemeColors.primary.neutral60,
    },
    neutral80: {
      main: globalThemeColors.primary.neutral80,
    },
    neutral90: {
      main: globalThemeColors.primary.neutral90,
    },
    neutral95: {
      main: globalThemeColors.primary.neutral95,
    },
    neutral99: {
      main: globalThemeColors.primary.neutral99,
    },
    neutral100: {
      main: globalThemeColors.primary.neutral100,
    },

    // Natural
    natural0: {
      main: globalThemeColors.primary.natural0,
    },
    natural20: {
      main: globalThemeColors.primary.natural20,
    },
    natural40: {
      main: globalThemeColors.primary.natural40,
    },
    natural60: {
      main: globalThemeColors.primary.natural60,
    },
    natural80: {
      main: globalThemeColors.primary.natural80,
    },
    natural90: {
      main: globalThemeColors.primary.natural90,
    },
    natural95: {
      main: globalThemeColors.primary.natural95,
    },
    natural99: {
      main: globalThemeColors.primary.natural99,
    },
    natural100: {
      main: globalThemeColors.primary.natural100,
    },

    // BH Blue
    bhBlue0: {
      main: globalThemeColors.primary.bhBlue0,
    },
    bhBlue20: {
      main: globalThemeColors.primary.bhBlue20,
    },
    bhBlue40: {
      main: globalThemeColors.primary.bhBlue40,
    },
    bhBlue60: {
      main: globalThemeColors.primary.bhBlue60,
    },
    bhBlue90: {
      main: globalThemeColors.primary.bhBlue90,
    },
    bhBlue95: {
      main: globalThemeColors.primary.bhBlue95,
    },
    bhBlue100: {
      main: globalThemeColors.primary.bhBlue100,
    },
    // BH Pink
    bhPink0: {
      main: globalThemeColors.primary.bhPink0,
    },
    // BH Yellow
    bhYellow0: {
      main: globalThemeColors.primary.bhYellow0,
    },
    bhYellow20: {
      main: globalThemeColors.primary.bhYellow20,
    },
    bhYellow40: {
      main: globalThemeColors.primary.bhYellow40,
    },
    bhYellow60: {
      main: globalThemeColors.primary.bhYellow60,
    },
    bhYellow80: {
      main: globalThemeColors.primary.bhYellow80,
    },
    bhYellow90: {
      main: globalThemeColors.primary.bhYellow90,
    },
    // BH Black
    bhBlack0: {
      main: globalThemeColors.primary.bhBlack0,
    },
    // BH Tan
    bhTan60: {
      main: globalThemeColors.primary.bhTan60,
    },
    bhTan80: {
      main: globalThemeColors.primary.bhTan80,
    },
    bhTan90: {
      main: globalThemeColors.primary.bhTan90,
    },

    // BH Brown
    bhBrown40: {
      main: globalThemeColors.primary.bhBrown40,
    },

    sleepio: {
      main: globalThemeColors.secondary.sleepio,
    },
    daylight: {
      main: globalThemeColors.secondary.daylight,
    },
    spark: {
      main: globalThemeColors.secondary.spark,
    },
    growTherapy: {
      main: globalThemeColors.secondary.growTherapy,
    },
    springHealth: {
      main: globalThemeColors.secondary.springHealth,
    },
    lyraHealth: {
      main: globalThemeColors.secondary.lyraHealth,
    },
    heapspace: {
      main: globalThemeColors.secondary.heapspace,
    },
    calm: {
      main: globalThemeColors.secondary.calm,
    },
    modernHealth: {
      main: globalThemeColors.secondary.modernHealth,
    },
    teladoc: {
      main: globalThemeColors.secondary.teladoc,
    },
    talkspace: {
      main: globalThemeColors.secondary.talkspace,
    },

    continue: {
      main: "#000",
    },

    lightButton: {
      main: "#000",
    },

    ultraLightButton: {
      main: "#000",
    },

    darkButton: {
      main: "#000",
    },

    secondaryLight: {
      main: "#000",
    },

    benefitsPortal: {
      main: globalThemeColors.secondary.benefitsPortal,
    },

    employee: {
      main: globalThemeColors.secondary.employee,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h2Medium: "h2",
          h3Medium: "h3",
          h5Medium: "h5",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          padding: "12px 16px",
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondaryLight" && {
            borderRadius: 56,
            backgroundColor: globalThemeColors.primary.neutral95,
            color: globalThemeColors.primary.neutral0,
            "&:hover": {
              backgroundColor: globalThemeColors.primary.neutral80,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
              backgroundColor: globalThemeColors.primary.neutral60,
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
            borderRadius: 56,
            backgroundColor: globalThemeColors.primary.bhYellow40,
            color: globalThemeColors.primary.neutral0,
            "&:hover": {
              backgroundColor: globalThemeColors.primary.bhYellow20,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
              backgroundColor: globalThemeColors.primary.bhYellow0,
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
            borderRadius: 56,
            backgroundColor: globalThemeColors.primary.bhTan90,
            color: globalThemeColors.primary.neutral0,
            "&:hover": {
              backgroundColor: globalThemeColors.primary.bhTan80,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
              backgroundColor: globalThemeColors.primary.bhTan60,
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "continue" && {
            borderRadius: 8,
            backgroundColor: globalThemeColors.primary.neutral10,
            color: globalThemeColors.primary.neutral100,
            "&:disabled": {
              backgroundColor: globalThemeColors.primary.neutral60,
              color: globalThemeColors.primary.neutral100,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
            }
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "lightButton" && {
            borderRadius: 56,
            backgroundColor: globalThemeColors.primary.natural99,
            color: globalThemeColors.primary.neutral0,
            border: `1.5px solid ${globalThemeColors.primary.natural60}`,
            "&:hover": {
              backgroundColor: globalThemeColors.primary.natural95,
              border: `1.5px solid ${globalThemeColors.primary.natural40}`,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
              backgroundColor: globalThemeColors.primary.natural80,
              border: `1.5px solid ${globalThemeColors.primary.natural60}`,
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "ultraLightButton" && {
            borderRadius: 56,
            backgroundColor: globalThemeColors.primary.neutral100,
            color: globalThemeColors.primary.neutral0,
            border: `1.5px solid ${globalThemeColors.primary.natural60}`,
            "&:hover": {
              backgroundColor: globalThemeColors.primary.natural95,
              border: `1.5px solid ${globalThemeColors.primary.natural40}`,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
              backgroundColor: globalThemeColors.primary.natural95,
              border: `1.5px solid ${globalThemeColors.primary.natural60}`,
            },
          }),
          ...(ownerState.variant === "contained" &&
            ownerState.color === "darkButton" && {
            borderRadius: 56,
            backgroundColor: globalThemeColors.primary.natural0,
            color: globalThemeColors.primary.neutral100,
            "&:hover": {
              backgroundColor: globalThemeColors.primary.neutral10,
            },
            "&:active": {
              outline: "2px solid",
              outlineColor: `${globalThemeColors.primary.natural40}`,
              outlineOffset: 2,
              backgroundColor: globalThemeColors.primary.neutral0,
            },
          }),
        }),
      },
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ["GTWalsheim-Regular", "Verdona", "Arial", "sans-serif"].join(
      ","
    ),
    bodyXL: {
      fontSize: `${24 / 16}rem`,
      lineHeight: `${38.4 / 16}rem`,
      "@media (min-width: 1440px)": {
        fontSize: `${24 / 18}rem`,
        lineHeight: `${38.4 / 18}rem`,
      },
    },
    bodyXLMedium: {
      fontSize: `${20 / 16}rem`,
      lineHeight: `${32 / 16}rem`,
      "@media (min-width: 1440px)": {
        fontSize: `${20 / 18}rem`,
        lineHeight: `${32 / 18}rem`,
      },
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      "@media (min-width: 1440px)": {
        fontSize: "1.125rem",
        lineHeight: "1.6875rem",
      },
    },
    h1: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
      fontFamily: "GTWalsheim-Medium",
      "@media (min-width: 1440px)": {
        fontSize: "2.375rem",
        lineHeight: "2.85rem",
      },
    },
    body3: {
      fontSize: "0.875rem",
      lineHeight: "1.125rem",
      "@media (min-width: 1440px)": {
        fontSize: "0.875rem",
        lineHeight: "1.125rem",
      },
    },
    body4: {
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      fontFamily: "GTWalsheim-Medium",
      fontWeight: 500,
      "@media (min-width: 1440px)": {
        fontSize: "0.875rem",
        lineHeight: "1.5rem",
      },
    },
    h2: {
      fontSize: "1.6875rem",
      lineHeight: "2rem",
      fontFamily: "GTWalsheim-Bold",
      "@media (min-width: 1440px)": {
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
      },
    },
    h2Medium: {
      fontSize: "1.6875rem",
      lineHeight: "2rem",
      fontWeight: "500",
      fontFamily: "GTWalsheim-Medium",
      "@media (min-width: 1440px)": {
        fontSize: "1.875rem",
        lineHeight: "2.25rem",
      },
    },
    h3: {
      fontSize: `${27 / 16}rem`,
      lineHeight: `${32.4 / 16}rem`,
      fontFamily: "GTWalsheim-Bold",
    },
    h3Medium: {
      fontSize: `${25 / 16}rem`,
      lineHeight: `${30 / 16}rem`,
      fontFamily: "GTWalsheim-Medium",
      fontWeight: 500
    },
    h4: {
      fontSize: "1.0625rem",
      fontFamily: "GTWalsheim-Bold",
      "@media (min-width: 1440px)": {
        fontSize: "1.3125rem",
      },
    },
    h4Medium: {
      fontSize: "1.0625rem",
      fontFamily: "GTWalsheim-Medium",
      fontWeight: 500,
      "@media (min-width: 1440px)": {
        fontSize: "1.3125rem",
      },
    },
    h5: {
      fontSize: "1.0625rem",
      lineHeight: "1.5rem",
      fontFamily: "GTWalsheim-Bold",
      "@media (min-width: 1440px)": {
        fontSize: "1.1875rem",
        lineHeight: "1.5rem",
      },
    },
    h5Medium: {
      fontSize: "1.0625rem",
      lineHeight: "1.5rem",
      fontFamily: "GTWalsheim-Medium",
      fontWeight: '500',
      "@media (min-width: 1440px)": {
        fontSize: "1.1875rem",
        lineHeight: "1.5rem",
      },
    },
    h6: {
      fontSize: "0.6875rem",
      fontFamily: "GTWalsheim-Medium",
      "@media (min-width: 1440px)": {
        fontSize: "0.875rem",
        fontFamily: "GTWalsheim-Medium",
      },
    },
    button: {
      textTransform: "none",
      fontFamily: "GTWalsheim-Medium",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      "@media (min-width: 1440px)": {
        fontSize: "1.125rem",
        lineHeight: "1.875rem",
      },
    },
    button2: {
      textTransform: "none",
      fontFamily: "GTWalsheim-Medium",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
  },
});

export default theme;
