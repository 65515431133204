import { SxProps, Theme, Typography } from "@mui/material";
import Card from "../Card";
import { SupportContentStyled } from "./styled";

export default function SupportCard({
  color,
  icon,
  title,
  description,
  actionComponent,
  customStyles,
}: SupportCardProps) {
  const existTitle = title !== undefined;
  const isEmptyTitle = existTitle && title.length === 0;

  return (
    <Card
      headerContent={icon}
      color={color}
      style={customStyles}
    >
      <SupportContentStyled>
        {existTitle && (
          <Typography variant="h3" color="neutral20.main" sx={{ marginBottom: "16px" }}>
            {isEmptyTitle ? "\u00A0" : title}
          </Typography>
        )}
        <Typography variant="body1" color="neutral40.main">
          {description}
        </Typography>
        {actionComponent}
      </SupportContentStyled>
    </Card>
  );
}

interface SupportCardProps {
  color?: string;
  icon?: React.ReactNode;
  title?: string;
  description: string;
  actionComponent?: React.ReactNode;
  customStyles?: SxProps<Theme>;
}
