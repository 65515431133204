import React from 'react';
import { useState } from 'react';
import { useThemeColor } from 'src/hooks/useThemeColor';
import { PhoneStreamlineIcon, NotificationMessageAlertStreamlineIcon, ChatBubbleTextSquareStreamlineIcon } from '../../icons';

type uriType = "tel" | "sms" | "chat";

function IconOption(): Record<uriType, React.ReactNode> {
  const [icon] = useState<Record<uriType, React.ReactNode>>({
    tel: <PhoneStreamlineIcon style={{ marginRight: 8, color: useThemeColor('neutral60') }} />,
    sms: <NotificationMessageAlertStreamlineIcon style={{ marginRight: 8, color: useThemeColor('neutral60') }} />,
    chat: <ChatBubbleTextSquareStreamlineIcon style={{ marginRight: 8, color: useThemeColor('neutral60') }} />,
  });

  return icon;
}

export default function GetIcon(uri: string | undefined) {
  const iconOption = IconOption()

  const iconPatterns: Record<string, React.ReactNode> = {
    'tel': iconOption.tel,
    'sms': iconOption.sms,
    'chat': iconOption.chat,
  };

  if (!uri) return iconOption.tel;

  const matchingPattern = Object.keys(iconPatterns).find(pattern => uri.includes(pattern));
  return matchingPattern ? iconPatterns[matchingPattern] : iconOption.tel;
}