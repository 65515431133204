import { useMediaQuery } from "@mui/material";
import ModalContent from "./ModalContent";
import ModalProvider from "./ModalProvider";
import { OrganizationModalProps } from "./types";


export default function OrganizationModal({ isOpen, onCloseModal, onConfirm, organizationName }: OrganizationModalProps) {
  const isMobile = useMediaQuery("(max-width: 599px)");
  return (
    <ModalProvider
      isOpen={isOpen}
      isMobile={isMobile}
      onCloseModal={onCloseModal}
    >
      <ModalContent
        onConfirm={onConfirm}
        organizationName={organizationName}
        sxStyles={{
          maxWidth: !isMobile ? "321px" : "auto"
        }}
      />
    </ModalProvider>
  )
}
