export const sleepioImages = [
  { src: '/assets/images/firstCarouselSleepio.png', alt: 'First Slide' },
  { src: '/assets/images/secondCarouselSleepio.png', alt: 'Second Slide' },
  { src: '/assets/images/thirdCarouselSleepio.png', alt: 'Third Slide' },
  { src: '/assets/images/fourCarouselSleepio.png', alt: 'Fourth Slide' },
  { src: '/assets/images/fiveCarouselSleepio.png', alt: 'Fifth Slide' },
  { src: '/assets/images/sixCarouselSleepio.png', alt: 'Sixth Slide' },
];

export const daylightImages = [
  { src: '/assets/images/firstCarouselDaylight.png', alt: 'First Slide' },
  { src: '/assets/images/secondCarouselDaylight.png', alt: 'Second Slide' },
  { src: '/assets/images/thirdCarouselDaylight.png', alt: 'Third Slide' },
  { src: '/assets/images/fourCarouselDaylight.png', alt: 'Fourth Slide' },
  { src: '/assets/images/fiveCarouselDaylight.png', alt: 'Fifth Slide' },
  { src: '/assets/images/sixCarouselDaylight.png', alt: 'Sixth Slide' },
];