import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from 'swiper/modules';
import "swiper/css";
import { CarouselWrapper, ItemText, ItemWrapper } from "./styled";
import { ICONS } from "./const";

export default function ProductChips({items} : ProductChipsProps) {
  return (
    <CarouselWrapper>
      <Swiper
        modules={[Mousewheel]}
        slidesPerView={3}
        breakpoints={{
          450: {
            slidesPerView: 4,
          },
          660: {
            slidesPerView: 6,
          },
        }}
        mousewheel={{ enabled: true, forceToAxis: true }}
      >
        {items.map(item => (
          <SwiperSlide key={item.label}>
            <ItemWrapper>
              {ICONS[item.icon]}
              <ItemText variant="body3" color="neutral60.main">{item.label}</ItemText>
            </ItemWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </CarouselWrapper>
  );
}
export interface ProductChipItem {
  icon: keyof typeof ICONS;
  label: string;
}
interface ProductChipsProps {
  items: ReadonlyArray<ProductChipItem>;
}
