import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useThemeColor } from "src/hooks/useThemeColor";
import theme from "src/theme";

export const BaseCardBackground = theme.palette.natural99.main

export const MainContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ExtraContent = styled(Box)``;

export const BaseCard = styled(Box)`
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background: ${BaseCardBackground};
`;

interface HeaderProps {
  color?: string;
}
export const Header = styled(Box)<HeaderProps>`
  position: relative;
  padding: 12px 28px;
  margin: 24px 0 0;
  display: flex;
  align-items: center;
  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 4px;
    background: ${({ color }) =>
      color ? useThemeColor(color) : "transparent"};
  }
`;

export const Content = styled(Box)`
  margin-top: 8px;
`;
