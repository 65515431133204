export const SLEEPIO_PRODUCT = "sleepio";
export const DAYLIGHT_PRODUCT = "daylight";
export const WORK_LIFE_BALANCE = "work-life-balance";
export const SLEEP_HEALTH = "sleep-health";
export const SELF_ESTEEM = "self-esteem";

export const LOCAL_AUTH_KEY = "active_session";

export const AVAILABLE_PRODUCTS = [SLEEPIO_PRODUCT, DAYLIGHT_PRODUCT];

export const AVAILABLE_BOOKS = [SELF_ESTEEM, SLEEP_HEALTH, WORK_LIFE_BALANCE];

export const APP_VERSION = "1.4.0";

export const SPECIAL_URLS = ["login/"];
