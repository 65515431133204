import { ModalContentProps } from "./types";
import { BottomContainer, ConfirmButton, MainTitleContainer, ModalContentContainer, ModalText, TitleGroup, TitleHeader } from "./styled";


export default function ModalContent({ sxStyles, onConfirm, organizationName }: ModalContentProps) {
  return (
    <ModalContentContainer sx={{ ...sxStyles }}>
      <TitleHeader>
        <ModalText variant="button">
          Final Step
        </ModalText>
      </TitleHeader>
      <TitleGroup>
        <MainTitleContainer>
          <ModalText variant="h3">
            Confirm organization
          </ModalText>
          <ModalText variant="h3Medium">
            You have chosen: {organizationName}
          </ModalText>
        </MainTitleContainer>
        <ModalText variant="body2">
          Please confirm that the organization you are connected
          to appears correct.
        </ModalText>
      </TitleGroup>
      <BottomContainer>
        <ConfirmButton variant="contained" color={"continue"} onClick={onConfirm}>
          Confirm
        </ConfirmButton>
        <ModalText variant="body3">
          You can email support at any time if you need to
          change this later.
        </ModalText>
      </BottomContainer>
    </ModalContentContainer>
  )
}
