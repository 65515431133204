import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const ArrowRightSimpleStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M7 20L15 12L7 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ArrowRightSimpleStreamlineIcon;
