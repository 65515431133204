import SupportCard from "src/components/SupportCard";
import { Box } from "@mui/material";
import { GridContainerStyled, LinkSection } from "./styled";
import LinkText from "../LinkText";
import { PhoneStreamlineIcon } from "../icons";
import LinksSection from "../SupportCard/linksSection";
import { IconWithBackgroundColor } from "../IconWithBackgroundColor";
import { useThemeColor } from "src/hooks/useThemeColor";
import { BenefitInfo } from "src/types";
import GroupBySubject from "./utils/groupSubjectLogic";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BaseCardBackground } from "../Card/styled";

export default function SupportComponent({ data }: { data: BenefitInfo[] }) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const supportCardStyles = {
    backgroundColor: "transparent",
    flex: 1,
    overflow: "unset",
    display: "flex",
  };
  return (
    <>
      <GridContainerStyled
        sx={{
          backgroundColor: BaseCardBackground,
          flexDirection: isSm ? "column" : "row",
          gap: isSm ? "0px" : "24px",
        }}
      >
        <SupportCard
          customStyles={supportCardStyles}
          icon={
            <IconWithBackgroundColor
              icon="AmbulanceCallStreamlineIcon"
              color="bhYellow0"
            />
          }
          title="In Crisis?"
          description="If you are currently experiencing a medical or mental health emergency, please call 911 or go to the nearest emergency room."
          actionComponent={
            <LinkText
              url="tel:911"
              text="Call 911 - Emergency Support"
              icon={
                <PhoneStreamlineIcon
                  style={{
                    marginRight: 8,
                    color: useThemeColor("neutral60"),
                  }}
                />
              }
            />
          }
        />

        <SupportCard
          customStyles={supportCardStyles}
          icon={
            !isSm ? (
              <Box
                sx={{
                  width: "32px",
                  height: "32px",
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
              />
            ) : undefined
          }
          title={isSm ? undefined : ""}
          description="If you are feeling hopeless, are having thoughts of harming yourself, or want immediate mental health support use one of the free, 24/7, confidential resources below to get connected with a trained crisis worker:"
          actionComponent={
            <LinkSection>
              {Object.entries(GroupBySubject(data)).map(
                ([subject, actions]) => (
                  <LinksSection
                    key={subject}
                    subject={subject}
                    actions={actions}
                  />
                )
              )}
            </LinkSection>
          }
        />
      </GridContainerStyled>
    </>
  );
}
