import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const LockShieldStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M14.6666 10.4H9.33327C8.74416 10.4 8.2666 10.8776 8.2666 11.4667V14.6667C8.2666 15.2558 8.74416 15.7334 9.33327 15.7334H14.6666C15.2557 15.7334 15.7333 15.2558 15.7333 14.6667V11.4667C15.7333 10.8776 15.2557 10.4 14.6666 10.4Z"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 13.3334C11.8528 13.3334 11.7334 13.214 11.7334 13.0667C11.7334 12.9194 11.8528 12.8 12.0001 12.8"
        strokeWidth={props.strokeWidth || "1.5"}
      />
      <path
        d="M12 13.3334C12.1473 13.3334 12.2667 13.214 12.2667 13.0667C12.2667 12.9194 12.1473 12.8 12 12.8"
        strokeWidth={props.strokeWidth || "1.5"}
      />
      <path
        d="M9.33301 10.4V8.79997C9.33301 8.09272 9.61396 7.41445 10.1141 6.91435C10.6141 6.41425 11.2924 6.1333 11.9997 6.1333C12.7069 6.1333 13.3852 6.41425 13.8853 6.91435C14.3854 7.41445 14.6663 8.09272 14.6663 8.79997V10.4"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20C7.25262 18.7335 4 15.9182 4 10.9333V4H20V10.9333C20 15.9147 16.7524 18.7321 12 20Z"
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default LockShieldStreamlineIcon;
