import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";
import { useThemeColor } from "src/hooks/useThemeColor";

export const TitleGroup = styled(Box)``;

export const ModalContentContainer = styled(Box)``;

export const BottomContainer = styled(Box)`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  border-radius: 56px;
`;

export const ModalText = styled(Typography)`
  color: ${() => useThemeColor('neutral20')};
`;

export const TitleHeader = styled(Box)`
  height: 52px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const MainTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 2rem;
`;
