import Typography from "@mui/material/Typography";
import { APP_VERSION } from "src/constants";

export default function ProTip() {
  return (
    <Typography variant="body3" sx={{ mt: 6, pb: 2, color: "neutral40.main", textAlign: 'center' }}>
      &copy; 2024 Big Health Version {APP_VERSION}
    </Typography>
  );
}
