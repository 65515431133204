import * as React from "react";
import restApi from "src/rest";

export interface FormField {
  label: string;
  name: keyof FormState;
  type: string;
  placeholder?: string;
}

export interface FormState {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  organization?: string;
  safetyAcknowledgement: boolean;
  termsAgreement: boolean;
  errorMessage: {
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    email?: string;
  };
}

export const fields: FormField[] = [
  { label: "First Name*", name: "firstName", type: "text" },
  { label: "Last Name*", name: "lastName", type: "text" },
  {
    label: "Date of birth*",
    name: "dateOfBirth",
    type: "text",
    placeholder: "MM/DD/YYYY",
  },
  { label: "Email*", name: "email", type: "email" },
];

const transformFormState = (formState: FormState) => {
  const { firstName, lastName, email, dateOfBirth, organization } = formState;
  const [mm, dd, yyyy] = dateOfBirth.split("/");
  const dob = `${yyyy}-${mm}-${dd}`;

  return {
    first_name: firstName,
    last_name: lastName,
    email: email,
    dob: dob,
    organization_id: organization,
  };
};
const emailRegex = /^[^\s@]{3,}@[^\s@]+(?:\.[^\s@]{2,4}){1,2}(?<!\.)$/;

const formatDate = (value: string): string => {
  const cleaned = value.replace(/\D+/g, "");
  const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);

  if (match) {
    let [, mm, dd, yyyy] = match;

    if (mm.length === 2 && parseInt(mm, 10) > 12) {
      mm = "12";
    }
    if (dd.length === 2 && parseInt(dd, 10) > 31) {
      dd = "31";
    }

    if (yyyy.length > 4) {
      yyyy = yyyy.substring(0, 4);
    }

    const year = parseInt(yyyy, 10);
    if (yyyy.length === 4 && (year < 1900 || year > new Date().getFullYear())) {
      yyyy = year < 1900 ? "1900" : new Date().getFullYear().toString();
    }

    return [mm, dd, yyyy].filter((part) => part).join("/");
  }

  return value;
};

export const useOnBoardingForm = () => {
  const [formState, setFormState] = React.useState<FormState>({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    safetyAcknowledgement: false,
    termsAgreement: false,
    errorMessage: {},
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccessAuthenticated, setIsSuccessAuthenticated] = React.useState<
    boolean | null
  >(null);
  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const transformedData = transformFormState(formState);
      const response = await restApi.post("users/", transformedData);
      if (response) {
        setIsSuccessAuthenticated(true);
      }
    } catch (error) {
      setIsSuccessAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: keyof FormState
  ) => {
    const { value } = e.target;
    const formattedValue = formatDate(value);
    setFormState((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newErrorMessage = { ...formState.errorMessage };
    const isEmail = name === "email";
    const isDob = name === "dateOfBirth";

    if (isEmail) {
      newErrorMessage[name] =
        value.length < 4
          ? "Complete email"
          : !emailRegex.test(value)
            ? 'Email must include "@", domain and "." (e.g."user@example.com")'
            : "";
    } else {
      newErrorMessage[name as keyof FormState["errorMessage"]] = "";
    }

    if (isDob) {
      const birth = new Date(value);
      const today = new Date();

      let age = today.getFullYear() - birth.getFullYear();

      const monthDifference = today.getMonth() - birth.getMonth();
      const dayDifference = today.getDate() - birth.getDate();

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      newErrorMessage[name] =
        value.length < 10
          ? "Complete date of birth"
          : age < 18
            ? "You must be 18 years or older to sign up for the Care Navigator"
            : "";
    }
    setFormState((prevState: any) => ({
      ...prevState,
      errorMessage: newErrorMessage,
    }));
  };

  return {
    formState,
    setFormState,
    handleLogin,
    handleDateChange,
    handleChange,
    isLoading,
    handleBlur,
    isSuccessAuthenticated,
  };
};
