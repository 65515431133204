// OnBoardingForm.tsx
import * as React from "react";
import { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import {
  BackText,
  CustomMenuItem,
  CustomSelect,
  CustomTextField,
  DescriptionContainer,
  Footer,
  Form,
  FormContainer,
  FormErrorText,
  FormText,
  FormTitle,
  MistakeText,
  PrivacyText,
  SubmitButton,
} from "./styled";
import { FormState, fields, useOnBoardingForm } from "./utils";
import OrganizationModal from "../OrganizationModal";
import { useOrganizations } from "src/hooks/useOrganizations";
import { useThemeColor } from "src/hooks/useThemeColor";

export default React.forwardRef(
  (
    {
      authenticate,
      logout
    }: {
      authenticate: () => void;
      logout: () => void;
    },
    ref
  ) => {
    const { companies } = useOrganizations();
    const {
      formState,
      setFormState,
      handleLogin,
      handleDateChange,
      handleChange,
      isLoading,
      handleBlur,
      isSuccessAuthenticated,
    } = useOnBoardingForm();

    const [step, setStep] = useState(1);
    const [displayOrganizationModal, setDisplayOrganizationModal] =
      useState(false);
    const [organization, setOrganization] = useState("");

    const handleCloseModal = () => {
      setDisplayOrganizationModal(false);
      setStep(2);
    };

    const handleLoginWrapper = (event: React.FormEvent) => {
      event.preventDefault();
      if (step === 1) {
        setStep(2);
      } else if (step === 2) {
        setDisplayOrganizationModal(true);
        setStep(3);
      } else {
        setFormState((prevState) => ({
          ...prevState,
          organization: organization,
        }));
        handleLogin(event);
      }
    };

    const handleDateChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) =>
      handleDateChange(e, "dateOfBirth");
    const handleChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) =>
      handleChange(e);

    const isUserInfoSubmitButtonEnable = React.useMemo(() => {
      const hasUserInfo =
        formState.firstName &&
        formState.lastName &&
        formState.email &&
        formState.dateOfBirth &&
        formState.safetyAcknowledgement &&
        formState.termsAgreement;

      const hasErrors = Object.values(formState.errorMessage).some(
        (msg) => msg !== ""
      );
      return step === 1
        ? !(hasUserInfo && !hasErrors)
        : !formState.organization;
    }, [formState, step]);

    const getOrganizationNameById = (id: string) => {
      const organization = companies?.find((org) => org.id === id);
      return organization ? organization.name : "";
    };

    React.useImperativeHandle(ref, () => ({
      goBack() {
        if (step !== 1) setStep(step - 1);
      },
    }));

    React.useEffect(() => {
      const authenticateLocal = async () => {
        if (isSuccessAuthenticated) {
          await authenticate();
        }
      };

      authenticateLocal();
    }, [isSuccessAuthenticated, authenticate]);

    return (
      <FormContainer>
        <OrganizationModal
          isOpen={displayOrganizationModal}
          onCloseModal={handleCloseModal}
          onConfirm={(event) => handleLoginWrapper(event as React.FormEvent)}
          organizationName={getOrganizationNameById(
            formState.organization || ""
          )}
        />
        <Form onSubmit={handleLoginWrapper}>
          <DescriptionContainer>
            <FormTitle variant="h3" color={"neutral20.main"}>
              {step === 1 ? "Create an account" : "Finalize account"}
            </FormTitle>
            <FormText
              variant="h3Medium"
              color={"neutral20.main"}
              style={{ fontWeight: 500 }}
            >
              {step === 1
                ? "Tell us a little about you"
                : "Which organization are you connected to?"}
            </FormText>
          </DescriptionContainer>

          {step === 1 ? (
            <>
              {fields?.map((field) => (
                <FormControl key={field.name} fullWidth>
                  <CustomTextField
                    error={!!(formState.errorMessage && formState.errorMessage[field.name as keyof FormState['errorMessage']])}
                    label={field.label}
                    name={field.name}
                    type={field.type}
                    value={formState[field.name]}
                    onChange={
                      field.name === "dateOfBirth"
                        ? handleDateChangeWrapper
                        : handleChangeWrapper
                    }
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    placeholder={field.placeholder}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                      },
                      inputProps: {
                        maxLength:
                          field.name === "dateOfBirth" ? 10 : undefined,
                      },
                    }}
                  />
                  {!!formState.errorMessage &&
                    formState.errorMessage[
                    field.name as keyof FormState["errorMessage"]
                    ] && (
                      <FormErrorText color={"error"}>
                        {
                          formState.errorMessage[
                          field.name as keyof FormState["errorMessage"]
                          ]
                        }
                      </FormErrorText>
                    )}
                </FormControl>
              ))}
              <FormControl fullWidth style={{ margin: "8px 0 12px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.safetyAcknowledgement}
                      onChange={handleChangeWrapper}
                      name="safetyAcknowledgement"
                      color="continue"
                      style={{ paddingTop: 0 }}
                    />
                  }
                  label={
                    <Typography variant="body2" color="neutral20.main">
                      You acknowledge that, if safety becomes a concern, we will
                      reach out to you at the number provided and, in very rare
                      cases, may send emergency services to the address
                      provided.
                    </Typography>
                  }
                  style={{ alignItems: "flex-start", top: "20px" }}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.termsAgreement}
                      onChange={handleChangeWrapper}
                      name="termsAgreement"
                      color="continue"
                      style={{ paddingTop: 0 }}
                    />
                  }
                  label={
                    <Typography variant="body2" color="neutral20.main">
                      I agree to the Terms and Privacy Policy.
                    </Typography>
                  }
                  style={{ alignItems: "flex-start", top: "20px" }}
                />
              </FormControl>
            </>
          ) : (
            <>
              <Typography
                variant="body2"
                color="neutral20.main"
                style={{ width: "100%", marginBottom: "14px" }}
              >
                Select your organization from the list below
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="select-label">Select Employer</InputLabel>
                <CustomSelect
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: "12px",
                        marginTop: "4px",
                      },
                    },
                    MenuListProps: {
                      style: {
                        padding: "0",
                      },
                    },
                  }}
                  label="Select Employer"
                  value={organization}
                  onChange={(e) => {
                    setOrganization(e.target.value as string);
                    return setFormState((prevState) => ({
                      ...prevState,
                      organization: e.target.value as string,
                    }));
                  }}
                  id="select-label"
                  variant="outlined"
                  renderValue={
                    !organization
                      ? (): React.ReactNode => {
                        return (
                          <Typography variant="body2" color="neutral40.main">
                            Select Employer
                          </Typography>
                        );
                      }
                      : undefined
                  }
                >
                  {companies?.map((org) => (
                    <CustomMenuItem key={org.id} value={org.id}>
                      <Typography variant="body2" color="neutral20.main">
                        {org.name}
                      </Typography>
                    </CustomMenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </>
          )}

          <SubmitButton
            type="submit"
            variant="contained"
            color="continue"
            disabled={isUserInfoSubmitButtonEnable || isLoading}
          >
            {isLoading ? "Loading..." : "Continue"}
          </SubmitButton>
          <Footer>
            <PrivacyText variant="body2" color="neutral20.main">
              Your privacy is important to us. Read more in our{" "}
              <Link
                sx={{ textDecorationColor: useThemeColor("neutral20") }}
                href="https://info.sleepio.com/privacy"
                target="_blank"
                color="neutral20.main"
              >
                Privacy Policy
              </Link>
              .
            </PrivacyText>
            <MistakeText variant="body2" color="neutral20.main">
              Made a mistake? <BackText onClick={logout}>Start again</BackText>
            </MistakeText>
          </Footer>
        </Form>
      </FormContainer>
    );
  }
);
