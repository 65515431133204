import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const ItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemText = styled(Typography)`
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-align: center;
  font-weight: 700;
`;

export const CarouselWrapper = styled(Box)`
  padding: 8px;
  width: 100%;
  & .swiper-slide {
    border-style: solid;
    border-color: #e1e1e1;
    box-sizing: border-box;
    padding: 8px 0;
    &:first-of-type {
      border-width: 1px 0 1px 1px;
      border-radius: 16px 0 0 16px;
    }
    &:not(:first-of-type, :last-of-type) {
      border-width: 1px 0 1px 0;
    }
    &:last-of-type {
      border-width: 1px 1px 1px 0px;
      border-radius: 0 16px 16px 0;
    }
  }

`;
