import Card from "../Card";
import { Typography, useMediaQuery } from "@mui/material";
import { Container, Content, Flag, Footer, Image } from "./styled";
import { LibraryCardProps } from "./types";
import { globalThemeColors } from "src/theme/globalThemeColors";
import { formatId } from "src/utils";

export default function LibraryCard({
  flagText,
  title,
  content,
  imageSrc,
  onClick,
}: LibraryCardProps) {
  const isMobileOrTablet = useMediaQuery("(max-width: 1024px)");

  const handleClick = () => {
    const activeElement = document.activeElement as HTMLElement | null;
    if (activeElement && typeof activeElement.blur === 'function') {
      activeElement.blur();
    }
    onClick && onClick();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };
  return (
    <Card
      color="bhBlue95"
      tabIndex={0}
      headerContent={
        <Flag>
          <Typography variant="body4">{flagText}</Typography>
        </Flag>
      }
      style={{
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        ...((!isMobileOrTablet && {
          "&:hover": {
            bgcolor: "natural95.main",
          },
        }) ||
          {}),
        "&:active": {
          bgcolor: "natural95.main",
          outline: "2px solid",
          outlineColor: `${globalThemeColors.primary.natural40}`,
          outlineOffset: 2,
          borderRadius: "12px",
        },
        "&:focus": {
          outline: "2px solid",
          outlineColor: `${globalThemeColors.primary.natural40}`,
          outlineOffset: 2,
          borderRadius: "12px"
        }
      }}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      contentStyle={{
        flex: 1,
        marginBottom: "0px",
        padding: "5px",

      }}
      id={formatId(`${title}-card`)}
    >
      <Container>
        <Content>
          <Typography variant="h3Medium">{title}</Typography>
          <Typography variant="body1" color={"neutral40.main"}>
            {content}
          </Typography>
        </Content>
        <Footer>
          <Image src={imageSrc} />
        </Footer>
      </Container>
    </Card>
  );
}
