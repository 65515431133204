import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Safety911StreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_972_108990)">
        <path
          d="M5.17321 13.18C5.35169 13.3972 5.60869 13.5351 5.88834 13.5639C6.16798 13.5926 6.44765 13.5097 6.66654 13.3334L7.76654 12.3067C7.95962 12.1026 8.06442 11.8307 8.05818 11.5498C8.05194 11.269 7.93516 11.0019 7.73321 10.8067L7.45321 10.5067C7.42649 10.4763 7.41175 10.4372 7.41175 10.3967C7.41175 10.3562 7.42649 10.3171 7.45321 10.2867C8.39852 9.19975 9.45045 8.21036 10.5932 7.33335C10.6242 7.30789 10.6631 7.29398 10.7032 7.29398C10.7433 7.29398 10.7822 7.30789 10.8132 7.33335L11.0932 7.63335C11.2745 7.84812 11.5336 7.9821 11.8137 8.00586C12.0937 8.02961 12.3717 7.94118 12.5865 7.76002L13.6799 6.73335C13.7784 6.63491 13.8566 6.51799 13.91 6.3893C13.9633 6.26061 13.9908 6.12266 13.9908 5.98335C13.9908 5.84404 13.9633 5.70609 13.91 5.5774C13.8566 5.44871 13.7784 5.3318 13.6799 5.23335L13.0532 4.56669C12.8098 4.28941 12.4804 4.10188 12.1178 4.03419C11.7551 3.96649 11.3802 4.02254 11.0532 4.19335C8.36705 5.76742 6.06476 7.91941 4.31321 10.4934C4.12191 10.8045 4.0409 11.171 4.08323 11.5338C4.12555 11.8966 4.28875 12.2346 4.54654 12.4934L5.17321 13.18Z"
        />
        <path
          d="M11.5198 18.6667C11.343 18.6667 11.1734 18.7369 11.0484 18.8619C10.9234 18.987 10.8531 19.1565 10.8531 19.3333C10.8531 19.5101 10.9234 19.6797 11.0484 19.8047C11.1734 19.9298 11.343 20 11.5198 20H14.6198C14.7966 20 14.9662 19.9298 15.0912 19.8047C15.2162 19.6797 15.2865 19.5101 15.2865 19.3333C15.2865 19.1565 15.2162 18.987 15.0912 18.8619C14.9662 18.7369 14.7966 18.6667 14.6198 18.6667H13.8998C13.8556 18.6667 13.8132 18.6491 13.782 18.6179C13.7507 18.5866 13.7331 18.5442 13.7331 18.5V14.88C13.7358 14.7654 13.7155 14.6514 13.6735 14.5447C13.6315 14.4381 13.5686 14.3409 13.4884 14.2589C13.4083 14.1769 13.3126 14.1118 13.2069 14.0673C13.1013 14.0229 12.9878 14 12.8731 14C12.5729 14.0007 12.2851 14.1206 12.0731 14.3333L11.0465 15.3533C10.9834 15.4159 10.9334 15.4903 10.8991 15.5723C10.8648 15.6542 10.847 15.7421 10.8467 15.831C10.8464 15.9198 10.8636 16.0078 10.8973 16.09C10.931 16.1722 10.9806 16.247 11.0431 16.31C11.1057 16.373 11.1801 16.4231 11.2621 16.4574C11.3441 16.4917 11.432 16.5095 11.5208 16.5098C11.6096 16.5101 11.6977 16.4929 11.7798 16.4592C11.862 16.4255 11.9368 16.3759 11.9998 16.3133L12.1331 16.18C12.1576 16.1588 12.1874 16.1448 12.2193 16.1397C12.2512 16.1346 12.2839 16.1385 12.3138 16.151C12.3436 16.1634 12.3693 16.184 12.388 16.2104C12.4068 16.2367 12.4178 16.2677 12.4198 16.3V18.5067C12.4182 18.5503 12.4001 18.5918 12.3692 18.6227C12.3383 18.6536 12.2968 18.6717 12.2531 18.6733L11.5198 18.6667Z"
        />
        <path
          d="M19.9333 19.3333C19.9333 19.1565 19.8631 18.987 19.7381 18.8619C19.613 18.7369 19.4435 18.6667 19.2667 18.6667H18.5533C18.5091 18.6667 18.4667 18.6491 18.4355 18.6179C18.4042 18.5866 18.3867 18.5442 18.3867 18.5V14.88C18.3893 14.7654 18.3691 14.6514 18.327 14.5447C18.285 14.4381 18.2221 14.3409 18.142 14.2589C18.0618 14.1769 17.9661 14.1118 17.8605 14.0673C17.7548 14.0229 17.6413 14 17.5267 14C17.2264 14.0007 16.9386 14.1206 16.7267 14.3333L15.7 15.3533C15.637 15.4168 15.587 15.4921 15.5531 15.5748C15.5192 15.6576 15.5018 15.7462 15.5022 15.8357C15.5025 15.9251 15.5204 16.0137 15.5549 16.0962C15.5894 16.1787 15.6399 16.2536 15.7033 16.3167C15.8315 16.444 16.005 16.5151 16.1857 16.5145C16.2751 16.5142 16.3637 16.4963 16.4462 16.4618C16.5287 16.4272 16.6036 16.3768 16.6667 16.3133L16.8 16.18C16.8235 16.1575 16.853 16.1423 16.885 16.1364C16.917 16.1305 16.95 16.1341 16.98 16.1467C17.0098 16.1597 17.0352 16.1811 17.0529 16.2084C17.0707 16.2356 17.0801 16.2675 17.08 16.3V18.5067C17.08 18.5497 17.0634 18.5912 17.0336 18.6222C17.0037 18.6533 16.963 18.6716 16.92 18.6733H16.1667C15.9899 18.6733 15.8203 18.7436 15.6953 18.8686C15.5702 18.9936 15.5 19.1632 15.5 19.34C15.5 19.5168 15.5702 19.6864 15.6953 19.8114C15.8203 19.9364 15.9899 20.0067 16.1667 20.0067H19.2733C19.3609 20.0058 19.4474 19.9877 19.528 19.9534C19.6085 19.9191 19.6815 19.8692 19.7428 19.8067C19.8041 19.7442 19.8525 19.6702 19.8851 19.589C19.9178 19.5077 19.9342 19.4209 19.9333 19.3333Z"
        />
        <path
          d="M7.63989 18.6667C7.46308 18.6667 7.29351 18.737 7.16848 18.862C7.04346 18.987 6.97322 19.1566 6.97322 19.3334C6.97322 19.5102 7.04346 19.6798 7.16848 19.8048C7.29351 19.9298 7.46308 20 7.63989 20H7.99989C8.34665 20.008 8.69153 19.9467 9.0143 19.8197C9.33708 19.6927 9.63126 19.5026 9.87961 19.2604C10.128 19.0183 10.3255 18.729 10.4606 18.4096C10.5957 18.0901 10.6658 17.7469 10.6666 17.4V16.2334C10.6679 15.794 10.5388 15.3642 10.2957 14.9982C10.0525 14.6323 9.70627 14.3467 9.30074 14.1776C8.89521 14.0086 8.44863 13.9637 8.01755 14.0486C7.58648 14.1335 7.19029 14.3444 6.87915 14.6546C6.56801 14.9648 6.35591 15.3603 6.26971 15.7912C6.18351 16.222 6.22708 16.6687 6.39491 17.0747C6.56274 17.4808 6.84728 17.8279 7.2125 18.0721C7.57772 18.3163 8.0072 18.4467 8.44655 18.4467C8.46565 18.4464 8.48421 18.453 8.49889 18.4652C8.51356 18.4775 8.52337 18.4945 8.52655 18.5134C8.5306 18.5319 8.52731 18.5513 8.51737 18.5675C8.50742 18.5836 8.4916 18.5953 8.47322 18.6C8.32151 18.6538 8.16054 18.6765 7.99989 18.6667H7.63989ZM8.41322 17.1134C8.23914 17.1068 8.07085 17.0491 7.92935 16.9475C7.78786 16.8459 7.67944 16.7049 7.61762 16.542C7.5558 16.3791 7.54332 16.2017 7.58175 16.0318C7.62018 15.8618 7.7078 15.707 7.83369 15.5866C7.95958 15.4662 8.11814 15.3855 8.28959 15.3547C8.46105 15.3238 8.63778 15.3442 8.79774 15.4132C8.9577 15.4822 9.09379 15.5968 9.18902 15.7426C9.28425 15.8885 9.33441 16.0592 9.33322 16.2334C9.33239 16.3524 9.30777 16.4701 9.26082 16.5795C9.21387 16.6888 9.14554 16.7877 9.05983 16.8703C8.97412 16.9529 8.87277 17.0176 8.76173 17.0605C8.65069 17.1033 8.5322 17.1236 8.41322 17.12V17.1134Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_972_108990">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>{" "}
    </SvgIcon>
  );
};
export default Safety911StreamlineIcon;
