import { useState } from 'react';

import SleepioAppIcon from '../../icons/SleepioAppIcon';
import DaylightAppIcon from '../../icons/DaylightAppIcon';
import { IconWithBackgroundColor } from '../../IconWithBackgroundColor';
import { ReactComponent as LeafIcon } from "../../icons/LeafIcon.svg";
import { ReactComponent as Growth } from "../../icons/Growth.svg";
import { ReactComponent as GenericCardIcon } from "../../icons/DefaultGenericCardIcon.svg";

export interface BenefitOption {
  icon: JSX.Element;
  color: string;
}

type BenefitOptionsType = {
  [key: string]: BenefitOption;
};

export default function BenefitsStylingOptions(): BenefitOptionsType {
  const [benefitsOptions] = useState<BenefitOptionsType>({
    sleepio: {
      icon: <SleepioAppIcon sx={{ borderRadius: 1, width: 32, height: 32 }} />,
      color: 'sleepio',
    },
    daylight: {
      icon: <DaylightAppIcon sx={{ borderRadius: 1, width: 32, height: 32 }} />,
      color: 'daylight',
    },
    spring: {
      icon: <LeafIcon width={32} height={32} />,
      color: 'springHealth',
    },
    grow: {
      icon: <Growth width={32} height={32} />,
      color: 'growTherapy',
    },
    generic: {
      icon: <GenericCardIcon width={32} height={32} />,
      color: 'bhBrown40',
    },
    benefits: {
      icon: <IconWithBackgroundColor icon='SparkStreamlineIcon' color='benefitsPortal' />,
      color: 'benefitsPortal',
    },
    employee: {
      icon: <IconWithBackgroundColor icon='WorkflowTeamworkHandshakeStreamlineIcon' color='employee' />,
      color: 'employee',
    },
  });

  return benefitsOptions;
}
