import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const HospitalShieldStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M16.2002 9.77138C16.2002 9.63498 16.14 9.50417 16.0328 9.40772C15.9257 9.31128 15.7803 9.25709 15.6288 9.25709H13.9145V7.71424C13.9145 7.57784 13.8543 7.44703 13.7471 7.35058C13.6399 7.25413 13.4946 7.19995 13.3431 7.19995H11.0573C10.9058 7.19995 10.7604 7.25413 10.6533 7.35058C10.5461 7.44703 10.4859 7.57784 10.4859 7.71424V9.25709H8.77162C8.62007 9.25709 8.47473 9.31128 8.36756 9.40772C8.2604 9.50417 8.2002 9.63498 8.2002 9.77138V11.8285C8.2002 11.9649 8.2604 12.0957 8.36756 12.1922C8.47473 12.2886 8.62007 12.3428 8.77162 12.3428H10.4859V13.8857C10.4859 14.0221 10.5461 14.1529 10.6533 14.2493C10.7604 14.3458 10.9058 14.4 11.0573 14.4H13.3431C13.4946 14.4 13.6399 14.3458 13.7471 14.2493C13.8543 14.1529 13.9145 14.0221 13.9145 13.8857V12.3428H15.6288C15.7803 12.3428 15.9257 12.2886 16.0328 12.1922C16.14 12.0957 16.2002 11.9649 16.2002 11.8285V9.77138Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00003 6.25693V11.6711C5.00004 13.3824 5.5386 15.0536 6.5447 16.4643C7.55081 17.8749 8.97723 18.9589 10.6359 19.5734L11.4047 19.8578C11.9166 20.0474 12.4834 20.0474 12.9953 19.8578L13.7641 19.5734C15.4228 18.9589 16.8492 17.8749 17.8553 16.4643C18.8614 15.0536 19.4 13.3824 19.4 11.6711V6.25693C19.4015 6.05317 19.3418 5.85334 19.228 5.68151C19.1143 5.50967 18.9514 5.37317 18.759 5.28843C16.6912 4.41934 14.4561 3.98051 12.2 4.00066C9.9439 3.98051 7.70881 4.41934 5.64101 5.28843C5.44857 5.37317 5.28567 5.50967 5.17195 5.68151C5.05822 5.85334 4.99852 6.05317 5.00003 6.25693Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default HospitalShieldStreamlineIcon;
