import { Box, Typography } from "@mui/material";
import { ChatTwoBubblesOvalStreamlineIcon } from "../icons";
import { ContentContainer, Footer, ImageGroupContainer } from "./styled";
import ImageGroup from "../NavigatorCard/ImageGroup";
import CustomButtonComponent from "../CustomButton";

export default function Questions({ onClick }: { onClick: () => void }) {
  return (
    <Box
      justifyContent={"center"}
    >
      <ContentContainer>
        <ImageGroupContainer>
          <ImageGroup
            containerStyles={{ gap: "2px" }}
            rightImagesContainerStyles={{ gap: "2px" }}
            leftImageStyles={{
              height: "100%",
              borderRadius: "4.44px",
            }}
            rigthTopImageStyles={{
              width: "24.11px",
              borderRadius: "4.44px",
            }}
            rigthBottomImageStyles={{
              width: "24.11px",
              borderRadius: "4.44px",
            }}
          />
        </ImageGroupContainer>
        <Typography variant="h3Medium">Have questions? Let’s Talk</Typography>
        <Typography variant="body3" color={"neutral40.main"}>
          Our care navigators are here to help. Reach out to us to learn more,
          or ask a question when you’re ready.
        </Typography>
        <Footer>
          <CustomButtonComponent
            variant="contained"
            color="primary"
            sx={{ height: "52px" }}
            startIcon={<ChatTwoBubblesOvalStreamlineIcon />}
            onClick={onClick}
            id="start-chat-button"
          >
            Start Chat
          </CustomButtonComponent>
          <Box>
            <Typography variant="body3" color={"neutral40.main"}>
              Available Monday-Friday, 6am-6pm PST.
            </Typography>
          </Box>
        </Footer>
      </ContentContainer>
    </Box>
  );
}
