import React from "react";
import {
  SelfEsteem,
  SleepHealth,
  WorkLifeBalance,
} from "src/components/LibraryBooks";
import { SELF_ESTEEM, SLEEP_HEALTH, WORK_LIFE_BALANCE } from "src/constants";
import { BookType } from "../types";

export const generateLibraryData = () => {
  return {
    [SELF_ESTEEM]: {
      heading: {
        description: "A simple guide to overcoming imposter syndrome",
        falseButtonTitle: "Self-Esteem",
        profileDate: "July 12, 2024",
        profileName: "Sarah Glen",
        profilePicture: "/assets/svg/SandraAvatar.svg",
        timeRead: "5 min read",
        title: "Silence your self-doubt",
      },
      content: <SelfEsteem />,
      keepReadingContent: [SLEEP_HEALTH, WORK_LIFE_BALANCE] as BookType[],
    },
    [SLEEP_HEALTH]: {
      heading: {
        description:
          "Unwind effortlessly with the body scan technique for better sleep",
        falseButtonTitle: "Sleep Health",
        profileDate: "July 12, 2024",
        profileName: "Sarah Glen",
        profilePicture: "/assets/svg/SandraAvatar.svg",
        timeRead: "2 min read",
        title: "Relax and sleep better",
      },
      content: <SleepHealth />,
      keepReadingContent: [SELF_ESTEEM, WORK_LIFE_BALANCE] as BookType[],
    },
    [WORK_LIFE_BALANCE]: {
      heading: {
        description: "A compassionate guide to preventing burnout",
        falseButtonTitle: "Work-Life Balance",
        profileDate: "July 12, 2024",
        profileName: "Sarah Glen",
        profilePicture: "/assets/svg/SandraAvatar.svg",
        timeRead: "5 min read",
        title: "Find balance, prevent burnout",
      },
      content: <WorkLifeBalance />,
      keepReadingContent: [SELF_ESTEEM, SLEEP_HEALTH] as BookType[],
    },
  };
};
