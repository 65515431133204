import { SvgIconProps } from "@mui/material/SvgIcon";
import { Box } from "@mui/material";
import { useThemeColor } from "src/hooks/useThemeColor";
import * as icons from "./icons";

interface BGIconProps extends Omit<SvgIconProps, "color"> {
  icon: keyof typeof icons;
  color?: string;
  bgStyle?: React.CSSProperties;
}

export function IconWithBackgroundColor({
  icon,
  color,
  bgStyle,
  ...props
}: BGIconProps) {
  const IconComponent = icons[icon];
  return (
    <Box
      style={{
        display: "grid",
        placeContent: 'center',
        backgroundColor:useThemeColor(color || "black"),
        width: 32,
        height: 32,
        borderRadius: "20%",
        padding: '6px',
        ...bgStyle,
      }}
    >
      <IconComponent {...props} sx={{width: '100%'}} color="white" />
    </Box>
  );
}
