import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const NotificationMessageAlertStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_814_352522)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 4.14286C4.30276 4.14286 4.14286 4.30276 4.14286 4.5V19.484C4.14521 19.4899 4.1498 19.4999 4.15823 19.5143C4.18566 19.561 4.23813 19.626 4.31899 19.6949C4.3991 19.7633 4.48359 19.8153 4.54947 19.8463C4.55873 19.8506 4.56697 19.8541 4.5741 19.8571H13.7857C13.983 19.8571 14.1429 19.6973 14.1429 19.5V15.9286C14.1429 15.3368 14.6226 14.8571 15.2143 14.8571C15.806 14.8571 16.2857 15.3368 16.2857 15.9286V19.5C16.2857 20.8807 15.1664 22 13.7857 22H4.5C4.16826 22 3.86026 21.8899 3.63974 21.7864C3.39728 21.6727 3.15161 21.5156 2.92857 21.3254C2.51608 20.9737 2 20.3456 2 19.5V4.5C2 3.11929 3.11929 2 4.5 2H9.14286C9.73459 2 10.2143 2.47969 10.2143 3.07143C10.2143 3.66316 9.73459 4.14286 9.14286 4.14286H4.5ZM16.2864 2C14.7709 2 13.3175 2.60204 12.2458 3.67367C11.1742 4.74531 10.5722 6.19876 10.5722 7.71429C10.5763 8.48924 10.7316 9.25594 11.0293 9.97143L10.0535 12.2563C9.96683 12.4592 10.1331 12.6796 10.352 12.6519L12.915 12.3286C13.6437 12.8608 14.488 13.213 15.379 13.3562C16.2699 13.4994 17.182 13.4295 18.0409 13.1524C18.8996 12.8752 19.6804 12.3986 20.3196 11.7616C20.9587 11.1246 21.4379 10.3453 21.7179 9.48746C21.9979 8.62963 22.0707 7.7177 21.9306 6.8263C21.7903 5.9349 21.441 5.08937 20.9111 4.35896C20.3813 3.62853 19.686 3.03397 18.8821 2.62396C18.0783 2.21394 17.1889 2.00012 16.2864 2ZM16.2864 4.14286C16.681 4.14286 17.0007 4.46266 17.0007 4.85714V7C17.0007 7.39449 16.681 7.71429 16.2864 7.71429C15.892 7.71429 15.5722 7.39449 15.5722 7V4.85714C15.5722 4.46266 15.892 4.14286 16.2864 4.14286ZM17.3579 9.85714C17.3579 9.26541 16.8781 8.78571 16.2864 8.78571C15.6947 8.78571 15.215 9.26541 15.215 9.85714C15.215 10.4489 15.6947 10.9286 16.2864 10.9286C16.8781 10.9286 17.3579 10.4489 17.3579 9.85714ZM8.42857 15.9286C7.83684 15.9286 7.35714 16.4083 7.35714 17C7.35714 17.5917 7.83684 18.0714 8.42857 18.0714H9.85714C10.4489 18.0714 10.9286 17.5917 10.9286 17C10.9286 16.4083 10.4489 15.9286 9.85714 15.9286H8.42857Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_352522">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>{" "}
    </SvgIcon>
  );
};
export default NotificationMessageAlertStreamlineIcon;
