import styled from "@emotion/styled";
import {
  Box,
  FormHelperText,
  Link,
  MenuItem,
  Select,
  Typography,
  css,
} from "@mui/material";

export const ErrorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 440px) {
    width: 80%;
    margin: 0 auto;
  }
`;

export const DescriptionContainer = styled(Box)`
  width: 100%;
`;
export const ImageContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ErrorContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 375px;
  gap: 8px;
  height: 100%;
  justify-content: center;
`;

export const Text = styled(Typography)`
  margin-bottom: 24px;
`;

export const PrivacyText = styled(Typography)`
  text-align: start;
`;

export const PrivacyTextLink = styled(Link)`
  color: #000;
`;

export const Title = styled(Typography)`
  margin-bottom: 8px;
`;

export const Subtitle = styled(Typography)`
  margin-bottom: 16px;
`;

export const CustomButton = css`
  width: 100%;
  height: 52px;
  margin-top: 8px;
  text-transform: none;
  border-radius: 56px;
  margin-bottom: 24px;
`;

export const FormErrorText = styled(FormHelperText)`
  text-align: center;
`;

export const CustomSelect = styled(Select)`
  border-radius: 8px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.neutral40.main};
`;

export const CustomMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.neutral40.main};
  font-family: 'GT Walsheim', sans-serif;
  border-radius: 12px;

  &:hover {
    border-radius: 0px;
    background-color: ${({ theme }) => theme.palette.neutral95.main};
  }

  &.Mui-selected {
    border-radius: 0px;
    background-color: ${({ theme }) => theme.palette.neutral90.main};
    &:hover {
      border-radius: 0px;
      background-color: ${({ theme }) => theme.palette.neutral80.main};
    }
  }
`;

