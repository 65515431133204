import styled from "@emotion/styled";
import { Box, Container } from "@mui/material";

export const AppContainer = styled(Container)`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0px 24px;
  max-width: 1128px;
  @media (max-width: 768px) {
    overflow-x: hidden;
  }
`;

export const LoadingScreenContainer = styled(Box)`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
