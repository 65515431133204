import styled from "@emotion/styled";
import { Box, Fade } from "@mui/material";

export const LoadingScreenContainer = styled(Box)`
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    z-index: 10;
`;

export const HeaderContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    position: relative;
`;

export const FormContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    max-width: 321px; 
    padding: 48px 0px 32px 0px;
`; 

export const MainContainer = styled(Box)`
    height: 100%;
`

export const FadeContainer = styled(Fade)`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
`

