import { Box } from "@mui/material";
import styled from "@emotion/styled";

export const UserInfoContainer = styled(Box)`
  padding: 8px 16px;
  marginbottom: 10px;
`;

export const DropdownContainer = styled(Box)`
  display: flex;
  gap: 12px;
`;
