import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Background = styled.img`
  min-width: 500px;
  position: absolute;
  left: 30%;
  transform: translate(-50%, -50%);
  @media (max-width: 1200px) {
    left: 33%;
  }
  @media (max-width: 900px) {
    left: 65%;
  }
  @media (max-width: 600px) {
    top: 36%;
    left: 64%;
  }
  @media (max-width: 545px) {
    top: 36%;
    left: 57%;
  }
  @media (max-width: 460px) {
    left: 47%;
  }
`;

export const MainWrapper = styled(Box)`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  min-height: min-content;
`;

export const Content = styled(Box)`
  flex: 1;
  position: relative;
  z-index: 10;
`;
