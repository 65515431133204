import { Container, Image, LeftImage, RightImages, RightImage } from "./styled";
import { useThemeColor } from "src/hooks/useThemeColor";
import ImageGroupProps from "./types";

export default function ImageGroup({
  containerStyles,
  rightImagesContainerStyles,
  leftImageStyles,
  rigthTopImageStyles,
  rigthBottomImageStyles,
}: ImageGroupProps) {
  return (
    <Container
      sx={{
        gap: {
          lg: "8px",
          md: "8px",
          sm: "8px",
          xs: "4px",
        },
        ...containerStyles,
      }}
    >
      <LeftImage
        sx={{
          borderRadius: "12px",
          background: useThemeColor("bhYellow90"),
          width: {
            lg: "97px",
            md: "116.52px",
            xs: "63.75px",
          },
          ...leftImageStyles,
        }}
      >
        <Image src={"/assets/images/LiveCareImage1.png"} />
      </LeftImage>

      <RightImages
        sx={{
          gap: {
            lg: "8px",
            md: "8px",
            sm: "8px",
            xs: "4px",
          },
          ...rightImagesContainerStyles,
        }}
      >
        <RightImage
          sx={{
            borderRadius: "12px",
            background: useThemeColor("bhBlue100"),
            width: {
              lg: "69.19px",
              md: "66.98px",
              sm: "66.98px",
              xs: "63.75px",
            },
            ...rigthTopImageStyles,
          }}
        >
          <Image src={"/assets/images/LiveCareImage2.png"} />
        </RightImage>

        <RightImage
          sx={{
            borderRadius: "12px",
            background: useThemeColor("bhPink0"),
            width: {
              lg: "66.98px",
              md: "66.98px",
              sm: "66.98px",
              xs: "63.75px",
            },
            ...rigthBottomImageStyles,
          }}
        >
          <Image src={"/assets/images/LiveCareImage3.png"} />
        </RightImage>
      </RightImages>
    </Container>
  );
}
