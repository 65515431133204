import { CustomModalProps } from "src/types";
import { CustomModal } from "../CustomModal";
import { ModalCardContent } from "./styled";
import { useEffect, useRef } from "react";

interface ModalViewProps extends CustomModalProps { }

const CONTENT_MARGIN_TOP = "64px";

declare global {
  interface Window {
    scrollOnModalViewToTop?: () => void;
  }
}

export const ModalView = ({
  isOpen,
  onCloseModal,
  children,
  cardStyles,
  sxContentStyles,
  dynamicContentRenderStateKey,
  modalRootContainerStyles,
  contentContainerStyles,
  hideCloseButton
}: ModalViewProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollOnModalViewToTop = () => {
      if (modalRef.current) {
        modalRef.current.scrollTop = 0;
      }
    };
    return () => {
      delete window.scrollOnModalViewToTop;
    };
  }, []);

  return (
    <CustomModal
      dynamicContentRenderStateKey={dynamicContentRenderStateKey}
      ref={modalRef}
      isOpen={isOpen}
      isBottomTabStyle={true}
      hideCloseButton={hideCloseButton}
      onCloseModal={onCloseModal}
      cardStyles={{
        width: "100%",
        height: "100%",
        padding: 0,
        ...cardStyles,
      }}
      modalRootContainerStyles={{
        paddingTop: CONTENT_MARGIN_TOP,
        bottom: "0px",
        ...modalRootContainerStyles
      }}
      contentContainerStyles={{
        width: "100%",
        height: "100%",
        maxHeight: `calc(100% - ${CONTENT_MARGIN_TOP})`,
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",
        ...contentContainerStyles
      }}
      sxContentStyles={sxContentStyles}
    >
      <ModalCardContent>{children}</ModalCardContent>
    </CustomModal>
  );
};
