import styled from "@emotion/styled";
import { SwiperSlide } from "swiper/react";

export const ProductImage = styled.img`
width: 100%;
`;

export const Container = styled(SwiperSlide)`
border-radius: 12px; 
max-width: 255px;
@media (max-width: 1200px) {
  width: 222px;
};
@media (max-width: 900px) {
  width: 203px;
};
@media (max-width: 600px) {
  width: 222px;
};
`;
