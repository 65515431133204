import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const COMMON_SPACING_VALUE = 24;
export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LibraryImage = styled.img`
  width: 100%;
  border-radius: 12px;
  height: 200px;
  object-fit: cover;
  @media (min-width: 600px) {
    height: 424px;
  }

  @media (min-width: 980px) {
    height: 560px;
  }
`;

export const ResponsiveWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1128px;

  padding: 0px 8px;
  align-items: center;
`;

export const ResponsiveFooterWrapper = styled(ResponsiveWrapper)`
  max-width: 100%;
  padding: 0px ${COMMON_SPACING_VALUE}px;
`;

export const FooterText = styled(Typography)`
  padding: ${COMMON_SPACING_VALUE}px;
`;
