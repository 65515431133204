import { CustomModal } from "../CustomModal";
import { ModalView } from "../ModalView";
import { ModalProviderProps } from "./types";


export default function ModalProvider({ isMobile, children, isOpen, onCloseModal }: ModalProviderProps) {
  return !isMobile ? (
    <CustomModal
      isOpen={isOpen}
      hideCloseButton={true}
      children={children}
      onCloseModal={onCloseModal}
      sxContentStyles={{
        width: "100%",
        height: "100%",
        padding: "0rem 4rem 2.5rem 4rem"
      }}
    />
  ) : (
    <ModalView
      isOpen={isOpen}
      hideCloseButton={true}
      onCloseModal={onCloseModal}
      children={children}
      sxContentStyles={{
        width: "100%",
        height: "100px",
        padding: 0,
      }}
      modalRootContainerStyles={{
        height: "75%",
        borderRadius: "12px 12px 0px 0px",
        top: "auto",
        bottom: 0,
        background: "white",
        padding: "0rem 1.5rem 0rem 1.5rem"
      }}
    />
  )
}
