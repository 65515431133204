
import { FooterContainer, FooterText } from 'src/styled';
import { DescriptionContainer, ErrorWrapper, Title, Text, ErrorContainer, Subtitle, CustomButton, PrivacyText, ImageContainer } from './styled';
import { Link } from '@mui/material'
import CustomButtonComponent from '../CustomButton';
import { APP_VERSION } from 'src/constants';

export default function GenericError({ logout }: any) {

  const handleReload = () => {
    window.location.reload();
  };

  const handleRedirectHome = async () => {
    await logout();
    window.location.reload();
  }

  return (
    <ErrorWrapper>
      <ErrorContainer>
        <ImageContainer>
          <img
            style={{ width: "96px", height: "96px", marginBottom: "20px" }}
            src="/assets/images/warningIcon.png"
            alt="warningIcon"
          />
        </ImageContainer>
        <DescriptionContainer>
          <Title variant="h3" color={"neutral20.main"}>
            Oops! Something went wrong
          </Title>
          <Text variant="h5Medium" color={"neutral20.main"} style={{ fontWeight: 500 }}>
            It looks like we encountered an issue. Your experience is very important to us, and we’re working on a fix.
          </Text>
          <Subtitle variant="h5" color={"neutral20.main"}>
            Here’s what you can do:
          </Subtitle>
          <CustomButtonComponent
            extraStyles={CustomButton}
            variant="contained"
            color="continue"
            onClick={handleReload}
          >
            Refresh page
          </CustomButtonComponent>
          <CustomButtonComponent
            extraStyles={CustomButton}
            variant="contained"
            color='ultraLightButton'
            onClick={handleRedirectHome}
          >
            Return home
          </CustomButtonComponent>
        </DescriptionContainer>
        <PrivacyText variant="body2" color='neutral20.main'>
          Need assistance?{" "}
          <Link
            href="mailto:hello@bighealth.com"
            target="_blank"
            color="neutral20.main"
          >
            Contact support
          </Link>
        </PrivacyText>
      </ErrorContainer>
      <FooterContainer>
        <FooterText variant="body2" color={"neutral60.main"}>
          &copy; 2024 Big Health Version {APP_VERSION}
        </FooterText>
      </FooterContainer>
    </ErrorWrapper>
  )
}
