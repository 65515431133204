import { BenefitInfo } from 'src/types';

export default function GroupBySubject(data: BenefitInfo[]): GroupedCrisisSupport {
  const grouped: GroupedCrisisSupport = {};
  if (!Array.isArray(data)) {
    return grouped;
  }
  data.forEach(item => {
    if (!grouped[item.subject ?? '']) {
      grouped[item.subject ?? ''] = [];
    }
    grouped[item.subject ?? ''].push({
      description: item.description, uri: item.uri
    });
  });
  return grouped;
}

interface GroupedCrisisSupport {
  [key: string]: BenefitLink[];
}

interface BenefitLink {
  description: string;
  uri: string;
}