import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DaylightAppIcon = (props: SvgIconProps) => {
  const fillColor = props.color || "#FF754F";
  return (
    <SvgIcon {...props} style={{ ...props.style, color: fillColor }}>
      <g clipPath="url(#clip0_2011_8726)">
        <path d="M24 0H0V24H24V0Z" />
        <path
          d="M4.84399 15.5789C4.84399 11.6269 8.04799 8.42285 12 8.42285C15.952 8.42285 19.156 11.6269 19.156 15.5789H4.84399Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2011_8726">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default DaylightAppIcon;
