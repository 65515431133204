import { Box } from "@mui/material";
import styled from "@emotion/styled";

export const ContentContainer = styled(Box)`
  max-width: 417px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 64px;
  padding-bottom: 64px;
  gap: 10px;
`;

export const ImageGroupContainer = styled(Box)`
  width: 64px;
  height: 64px;
`;

export const Footer = styled(Box)`
  max-width: 360px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;
