import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const ChatTwoBubblesOvalStreamlineOutlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M15.0959 10.1832C16.3965 10.1832 17.6438 10.6999 18.5634 11.6195C19.4829 12.5391 19.9996 13.7864 19.9996 15.0869C20.0027 16.0522 19.7174 16.9964 19.1804 17.7984L19.9996 19.9906L17.242 19.4945C16.5788 19.8179 15.8512 19.9875 15.1134 19.9906C14.3755 19.9938 13.6465 19.8303 12.9806 19.5125C12.3147 19.1947 11.7291 18.7307 11.2675 18.1552C10.8058 17.5796 10.48 16.9073 10.3142 16.1883C10.1485 15.4693 10.1472 14.7222 10.3104 14.0026C10.4735 13.283 10.797 12.6095 11.2566 12.0323C11.7162 11.4551 12.3001 10.9891 12.9649 10.6689C13.6296 10.3488 14.3581 10.1827 15.0959 10.1832Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7997 7.3102C15.0227 6.32146 13.9567 5.59961 12.7502 5.24515C11.5437 4.89069 10.2566 4.92127 9.0683 5.33261C7.87997 5.74397 6.84947 6.51561 6.12027 7.54013C5.39108 8.56464 4.99949 9.79104 5.00003 11.0486C4.99645 12.2439 5.35002 13.4131 6.01538 14.4062L5.00003 17.1061L7.44611 16.6677"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default ChatTwoBubblesOvalStreamlineOutlineIcon;
