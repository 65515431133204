import styled from "@emotion/styled";

export const Wrapper =  styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin: 24px auto;
  .swiper {
    width: 100%;
  }
  .swiper-slide {
    width: 100%;
  }
  @media (min-width: 360px) {
    .swiper-slide {
      width: calc(calc(100% - 24px) / 2);
      max-width: 360px;
      min-width: 300px;
    }
  }
`;
