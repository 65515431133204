import { BaseCard, Content, ExtraContent, Header, MainContent } from "./styled";
import { CardProps } from "./types";

export default function Card({
  children,
  color,
  headerContent,
  extraContent,
  style,
  contentStyle,
  isFlex,
  onClick,
  tabIndex,
  onKeyDown,
  id
}: React.PropsWithChildren<CardProps>) {
  return (
    <BaseCard
      id={id}
      sx={style}
      onClick={onClick}
      tabIndex={tabIndex !== undefined ? tabIndex : -1}
      onKeyDown={onKeyDown}
    >
      <MainContent>
        {!!headerContent && <Header color={color}>{headerContent}</Header>}
        <Content
          sx={{
            padding: "0 24px",
            marginBottom: "24px",
            ...(isFlex && { display: "flex", flex: "1" }),
            ...contentStyle,
          }}
        >
          {children}
        </Content>
      </MainContent>
      {extraContent && <ExtraContent children={extraContent} />}
    </BaseCard>
  );
}
