import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const MedicalFilesMedicalCheckStreamLineUltimate = ({
  color,
  ...props
}: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
      width="100" height="100" viewBox="0 0 100 100"
    >
      <path d="M41.987 32.9922L36.4349 40.3932C36.1241 40.8076 35.6614 41.0816 35.1486 41.1549C34.6358 41.2281 34.1149 41.0947 33.7005 40.7839C33.627 40.728 33.5574 40.667 33.4922 40.6016L30.4688 37.5911" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M41.987 50.5703L36.4349 57.9714C36.281 58.1765 36.0882 58.3494 35.8675 58.4801C35.6468 58.6108 35.4025 58.6967 35.1486 58.733C34.8947 58.7692 34.6361 58.7552 34.3877 58.6915C34.1392 58.6278 33.9057 58.5159 33.7005 58.362C33.627 58.3061 33.5574 58.2452 33.4922 58.1797L30.4688 55.1667" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M79.2969 60.2135C79.2972 59.9853 79.2525 59.7592 79.1654 59.5483C79.0782 59.3374 78.9503 59.1458 78.7889 58.9845C78.6275 58.8231 78.4359 58.6952 78.225 58.608C78.0141 58.5208 77.7881 58.4761 77.5599 58.4765H68.8802V49.7968C68.8806 49.5686 68.8359 49.3426 68.7487 49.1317C68.6615 48.9208 68.5336 48.7292 68.3722 48.5678C68.2109 48.4064 68.0192 48.2785 67.8083 48.1913C67.5975 48.1042 67.3714 48.0595 67.1432 48.0598H60.2005C59.9723 48.0595 59.7463 48.1042 59.5354 48.1913C59.3245 48.2785 59.1329 48.4064 58.9715 48.5678C58.8102 48.7292 58.6822 48.9208 58.5951 49.1317C58.5079 49.3426 58.4632 49.5686 58.4635 49.7968V58.4765H49.7839C49.5557 58.4761 49.3296 58.5208 49.1187 58.608C48.9078 58.6952 48.7162 58.8231 48.5549 58.9845C48.3935 59.1458 48.2656 59.3374 48.1784 59.5483C48.0912 59.7592 48.0465 59.9853 48.0469 60.2135V67.1562C48.0465 67.3844 48.0912 67.6104 48.1784 67.8213C48.2656 68.0322 48.3935 68.2238 48.5549 68.3852C48.7162 68.5465 48.9078 68.6745 49.1187 68.7616C49.3296 68.8488 49.5557 68.8935 49.7839 68.8932H58.4635V77.5728C58.4632 77.801 58.5079 78.0271 58.5951 78.238C58.6822 78.4489 58.8102 78.6405 58.9715 78.8018C59.1329 78.9632 59.3245 79.0911 59.5354 79.1783C59.7463 79.2655 59.9723 79.3102 60.2005 79.3098H67.1432C67.3714 79.3102 67.5975 79.2655 67.8083 79.1783C68.0192 79.0911 68.2109 78.9632 68.3722 78.8018C68.5336 78.6405 68.6615 78.4489 68.7487 78.238C68.8359 78.0271 68.8806 77.801 68.8802 77.5728V68.8932H77.5599C77.7881 68.8935 78.0141 68.8488 78.225 68.7616C78.4359 68.6745 78.6275 68.5465 78.7889 68.3852C78.9503 68.2238 79.0782 68.0322 79.1654 67.8213C79.2525 67.6104 79.2972 67.3844 79.2969 67.1562V60.2135Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40.2344 71.4713H24.6094C23.5734 71.4713 22.5798 71.0598 21.8472 70.3272C21.1147 69.5946 20.7031 68.6011 20.7031 67.5651V24.5963C20.7031 23.5603 21.1147 22.5667 21.8472 21.8342C22.5798 21.1016 23.5734 20.6901 24.6094 20.6901H52.2891C53.3247 20.691 54.3176 21.1031 55.0495 21.8359L62.5286 29.3125C62.8913 29.6754 63.179 30.1062 63.3751 30.5802C63.5713 31.0543 63.6721 31.5624 63.6719 32.0755V40.2213" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M50.6562 21.6198V33.7109H63.6719" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};
export default MedicalFilesMedicalCheckStreamLineUltimate;

