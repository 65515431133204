import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const SparkStreamlineOutlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M4.74378 4.00037H10.0202C10.0202 4.00037 10.7639 4.00037 10.7639 4.74415V10.0205C10.7639 10.0205 10.7639 10.7643 10.0202 10.7643H4.74378C4.74378 10.7643 4 10.7643 4 10.0205V4.74415C4 4.74415 4 4.00037 4.74378 4.00037Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M4.74378 13.1466H10.0202C10.0202 13.1466 10.7639 13.1466 10.7639 13.8904V19.1668C10.7639 19.1668 10.7639 19.9106 10.0202 19.9106H4.74378C4.74378 19.9106 4 19.9106 4 19.1668V13.8904C4 13.8904 4 13.1466 4.74378 13.1466Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13.9796 13.1466H19.256C19.256 13.1466 19.9998 13.1466 19.9998 13.8904V19.1668C19.9998 19.1668 19.9998 19.9106 19.256 19.9106H13.9796C13.9796 19.9106 13.2358 19.9106 13.2358 19.1668V13.8904C13.2358 13.8904 13.2358 13.1466 13.9796 13.1466Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13.2974 7.37946C14.9525 7.04673 16.2851 5.69794 16.6183 4C16.9516 5.69794 18.2839 7.04673 19.939 7.37946M19.939 7.38139C18.2839 7.71412 16.9513 9.06291 16.618 10.7608C16.2848 9.06291 14.9525 7.71412 13.2974 7.38139"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default SparkStreamlineOutlineIcon;
