import { Palette, PaletteColor, useTheme } from "@mui/material/styles";

export function useThemeColor(color: string) {
  const theme = useTheme();
  const colorPalette = theme.palette[color as keyof Palette];
  const colorValue =
    typeof colorPalette === "object" && "main" in colorPalette
      ? (colorPalette as PaletteColor).main
      : "black";
  return colorValue;
}
