import CustomTitle from "src/components/CustomTitle/CustomTitle";
import NavigatorCard from "src/components/NavigatorCard";
import SupportComponent from "src/components/SupportComponent";
import WelcomeMessage from "src/components/WelcomeMessage";
import BackgroundImage from "src/components/BackgroundImage";
import MyBenefitsList from "src/components/MyBenefitsList/MyBenefitsList";
import ProTip from "src/components/ProTip";
import Header from "src/components/Header";
import { HeaderContainer } from "./styled";
import { HomeViewProps } from "src/types";
import { Grid } from "@mui/material";
import MyLibraryList from "src/components/LibraryCard/MyLibraryList";

export default function HomeView({
  user,
  benefitsData,
  supportData,
  logout,
  openChat,
  isLoading,
}: HomeViewProps) {
  return (
    <>
      <Header user={user} onLogoutClick={logout} />
      <WelcomeMessage firstName={user?.firstName} />
      <HeaderContainer sx={{maxWidth: {sm: "80%", md:"100%"}}}>
        <BackgroundImage>
          <Grid container>
            <Grid lg={8} md={8} sm={12} xs={12} item>
              <NavigatorCard onClick={openChat} />
            </Grid>
          </Grid>
        </BackgroundImage>
      </HeaderContainer>
      <CustomTitle
        title="My benefits"
        color="neutral20.main"
        variant="h3"
        marginBottomOption="24px"
        marginTopOption="44px"
      />
      <MyBenefitsList data={benefitsData} isLoading={isLoading} />
      <CustomTitle
        title="Library"
        color="neutral20.main"
        variant="h3"
        marginBottomOption="24px"
        marginTopOption="44px"
      />
      <MyLibraryList />
      <CustomTitle
        title=""
        color="neutral20.main"
        variant="h3"
        marginBottomOption="24px"
        marginTopOption="44px"
      />
      <SupportComponent data={supportData} />
      <ProTip />
    </>
  );
}
