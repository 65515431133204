import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const LogoutStreamlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M8.80078 12.469H20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3335 15.1355L20 12.469L17.3335 9.80249"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.667 15.6659V18.8657C14.6792 19.1356 14.584 19.3993 14.4022 19.5992C14.2205 19.7991 13.9669 19.9188 13.6971 19.9323H4.97025C4.70055 19.9187 4.44721 19.7988 4.2656 19.599C4.08399 19.3991 3.98889 19.1355 4.00107 18.8657V6.06659C3.9887 5.79677 4.08374 5.53304 4.26538 5.33314C4.44703 5.13324 4.70048 5.01345 4.97025 5H13.6971C13.9669 5.01345 14.2205 5.13321 14.4022 5.33309C14.584 5.53298 14.6792 5.7967 14.667 6.06659V9.26637"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default LogoutStreamlineIcon;
