
import { Box } from "@mui/material";
import React from "react";
import { useThemeColor } from "src/hooks/useThemeColor";
import { LinkStyled, TypographyStyled, links } from "./styled";
import CustomButtonComponent from "../CustomButton";

interface LinkTextProps {
  url: string;
  text: string;
  icon?: React.ReactNode;
}

export default function LinkText({ url, text, icon }: LinkTextProps) {
  return (
    <Box>
      <CustomButtonComponent extraStyles={links} onClick={() => window.open(url, "_blank", "noopener,noreferrer")}>
        <LinkStyled>
          {icon}
          <TypographyStyled sx={{
            textDecoration: 'none',
            color: useThemeColor('bhBlue40'),
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
            typography='button'
          >
            {text}
          </TypographyStyled>
        </LinkStyled>
      </CustomButtonComponent>
    </Box>
  );
}