import styled from "@emotion/styled";
import {
  Box,
  Button,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

export const FormContainer = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  // height: 100%;
  justify-content: center;
  align-items: center;

  @media (max-width: 375px) {
    align-items: flex-start;
  }
`;

export const DescriptionContainer = styled(Box)`
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  gap: 8px;

  @media (max-width: 375px) {
    padding: 48px 0px 48px 0px;
  }
`;

export const FormText = styled(Typography)`
  margin-bottom: 8px;
`;

export const PrivacyText = styled(Typography)`
  margin-top: 16px;
  font-size: 14px;
`;

export const Footer = styled(Box)`
  margin-bottom: 40px;
`;

export const MistakeText = styled(Typography)`
  font-size: 14px;
  margin-top: 20px;
`;

export const BackText = styled.span`
  text-decoration: underline;
  text-decorationcolor: ${({ theme }) => theme.palette.neutral20.main};
  cursor: pointer;
`;

export const PrivacyTextLink = styled(Link)`
  color: #000;
`;

export const FormTitle = styled(Typography)`
  margin-bottom: 8px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 52px;
  margin-top: 8px;
  text-transform: none;
  border-radius: 56px;
`;

export const FormErrorText = styled(Typography)`
  text-align: start;
  color: #b92d0f;
  padding: 8px 14px;
`;

export const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-error {
      & fieldset {
        border-color: #b92d0f;
      }
    }
  }

  & .MuiFormLabel-root {
    &.Mui-error {
      color: #b92d0f;
    }
  }
`;

export const CustomSelect = styled(Select)`
  border-radius: 8px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.neutral40.main};
`;

export const CustomMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.neutral40.main};
  font-family: "GT Walsheim", sans-serif;
  padding: 8px 16px;

  &:hover {
    border-radius: 0px;
    background-color: ${({ theme }) => theme.palette.neutral95.main};
  }

  &.Mui-selected {
    border-radius: 0px;
    background-color: ${({ theme }) => theme.palette.neutral90.main};
    &:hover {
      border-radius: 0px;
      background-color: ${({ theme }) => theme.palette.neutral80.main};
    }
  }
`;
