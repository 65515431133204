import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ResponsiveWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 936px;
`;

export const ResponsiveFooterWrapper = styled(ResponsiveWrapper)`
  max-width: none;
  padding: 24px;
`;

export const HeroWrapper = styled(Box)`
  padding: 0 24px;
  width: 100%;
  @media (max-width: 667px) {
    padding: 0 6px;
  }
`;
