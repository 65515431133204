import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const EmergencyCallStreamlineOutlineIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M12.5792 19.471C13.2173 19.8818 13.9767 20.0619 14.7312 19.9812C15.4858 19.9006 16.1901 19.5643 16.7269 19.0279L17.1946 18.5602C17.402 18.3505 17.5184 18.0674 17.5184 17.7725C17.5184 17.4775 17.402 17.1945 17.1946 16.9848L15.2254 15.0279C15.0178 14.8212 14.7368 14.7052 14.4438 14.7052C14.1509 14.7052 13.8699 14.8212 13.6623 15.0279C13.4526 15.2353 13.1696 15.3517 12.8746 15.3517C12.5797 15.3517 12.2966 15.2353 12.0869 15.0279L8.94849 11.8895C8.74104 11.6798 8.6247 11.3967 8.6247 11.1018C8.6247 10.8068 8.74104 10.5238 8.94849 10.3141C9.15517 10.1065 9.2712 9.82548 9.2712 9.53255C9.2712 9.23962 9.15517 8.95861 8.94849 8.75102L7.05311 6.7818C6.84343 6.57436 6.56038 6.45801 6.26543 6.45801C5.97047 6.45801 5.68742 6.57436 5.47774 6.7818L5.01005 7.24949C4.46409 7.77876 4.11616 8.47926 4.02433 9.23409C3.93249 9.98893 4.10231 10.7524 4.50544 11.3972C6.65155 14.5833 9.39306 17.3249 12.5792 19.471Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7612 4C15.4708 4 15.2354 4.23539 15.2354 4.52576V6.00449H13.7567C13.4664 6.00449 13.231 6.23989 13.231 6.53026V8.23899C13.231 8.52935 13.4664 8.76475 13.7567 8.76475H15.2354V10.2434C15.2354 10.5338 15.4708 10.7692 15.7612 10.7692H17.4699C17.7603 10.7692 17.9957 10.5338 17.9957 10.2434V8.76475H19.4744C19.7647 8.76475 20.0001 8.52935 20.0001 8.23899V6.53026C20.0001 6.23989 19.7647 6.00449 19.4744 6.00449H17.9957V4.52576C17.9957 4.23539 17.7603 4 17.4699 4H15.7612Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default EmergencyCallStreamlineOutlineIcon;
