import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SupportContentStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LinkBoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
