import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const LoadingContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  min-height: 80vh;
  justify-content: center;
`;
