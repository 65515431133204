import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DAYLIGHT_PRODUCT, SLEEPIO_PRODUCT } from "src/constants";

interface BenefitCardLogicProps {
  title: string;
  uri?: string;
}

interface BenefitCardLogicResult {
  buttonText: string;
  secondButtonText: string;
  isGetAppButton: boolean;
  handleClick: (isFirstButton: boolean) => void;
}

export const useBenefitCardLogic = ({
  title,
  uri,
}: BenefitCardLogicProps): BenefitCardLogicResult => {
  const navigate = useNavigate();
  const isGetAppButton =
    title.includes("Sleepio app") || title.includes("Daylight app");
  const buttonText = isGetAppButton ? "Get app" : "Get started";
  const secondButtonText = 'Explore'

  const smoothNavigationToProductDetail = useCallback(() => {
    const isSleepio = title.includes("Sleepio app");
    navigate(`/${isSleepio ? SLEEPIO_PRODUCT : DAYLIGHT_PRODUCT}`, {
      replace: true,
    });
  }, [navigate, title]);

  const handleClick = (isFirstButton: boolean) => {
    if (isFirstButton && uri) {
      window.open(uri, "_blank");
    } else if (isGetAppButton) {
      smoothNavigationToProductDetail();
    }
  };

  return {
    buttonText,
    secondButtonText,
    isGetAppButton,
    handleClick,
  };
};
