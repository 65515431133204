import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const SparkStreamlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_972_108989)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.49609 4.5365C4.94381 4.5365 4.49609 4.98421 4.49609 5.5365V10.2657C4.49609 10.818 4.94381 11.2657 5.49609 11.2657H10.2253C10.7776 11.2657 11.2253 10.818 11.2253 10.2657V5.5365C11.2253 4.98421 10.7776 4.5365 10.2253 4.5365H5.49609ZM5.49609 12.7344C4.94381 12.7344 4.49609 13.1821 4.49609 13.7344V18.4636C4.49609 19.0159 4.94381 19.4636 5.49609 19.4636H10.2253C10.7776 19.4636 11.2253 19.0159 11.2253 18.4636V13.7344C11.2253 13.1821 10.7776 12.7344 10.2253 12.7344H5.49609ZM13.7747 12.7344C13.2225 12.7344 12.7747 13.1821 12.7747 13.7344V18.4636C12.7747 19.0159 13.2225 19.4636 13.7747 19.4636H18.5039C19.0563 19.4636 19.5039 19.0159 19.5039 18.4636V13.7344C19.5039 13.1821 19.0563 12.7344 18.5039 12.7344H13.7747ZM16.6298 4.94281C16.5837 4.70827 16.3781 4.5391 16.1391 4.5391C15.9001 4.5391 15.6945 4.70827 15.6485 4.94281C15.4046 6.18563 14.4281 7.16796 13.2302 7.40877C12.9967 7.45571 12.8288 7.66081 12.8288 7.89896C12.8288 8.13711 12.9967 8.34221 13.2302 8.38915C14.4277 8.62989 15.4042 9.61355 15.6482 10.8568C15.6943 11.0913 15.8999 11.2604 16.1389 11.2604C16.3779 11.2604 16.5835 11.0913 16.6295 10.8568C16.8734 9.61393 17.8499 8.6316 19.0477 8.39079C19.2813 8.34385 19.4492 8.13875 19.4492 7.9006C19.4492 7.66245 19.2813 7.45734 19.0477 7.41041C17.8503 7.16967 16.8738 6.18601 16.6298 4.94281Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_972_108989">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default SparkStreamlineIcon;
