import Header from "src/components/Header";
import { LoadingScreenContainer, MainContainer } from "./styled";
import { Button, Fade } from "@mui/material";
import LoadingBH from "src/components/LoadingBH";
import { useCallback, useState } from "react";
import { useThemeColor } from "src/hooks/useThemeColor";
import { FooterContainer, FooterText } from "src/styled";
import { APP_VERSION } from "src/constants";

export default function LoginView({ handleLogin: _handleLogin }: { handleLogin: () => Promise<void> }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = useCallback(async () => {
    setIsLoading(true);
    try {
      await _handleLogin();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  }, [_handleLogin]);

  return (
    <MainContainer>
      <Fade in={isLoading} timeout={500}>
        <LoadingScreenContainer style={{ backgroundColor: useThemeColor("white") }}>
          <LoadingBH />
        </LoadingScreenContainer>
      </Fade>
      <Header
        customButton={
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            {/* TODO - NAV-64 - retool this to use the correct branding and styling for the login */}
            Get started
          </Button>
        }
      />

      <FooterContainer>
        <FooterText variant="body2" color={"neutral60.main"}>
          &copy; 2024 Big Health Version {APP_VERSION}
        </FooterText>
      </FooterContainer>
    </MainContainer>
  );
}
