import CardIcon from "./CardIcon";
import ImageGroup from "./ImageGroup";
import Card from "../Card";
import { Grid, Typography } from "@mui/material";
import { ChatTwoBubblesOvalStreamlineIcon } from "../icons";
import { ChatButton, ExtraContainer, Footer, SubTitle, Title } from "./styled";
import { useThemeColor } from "src/hooks/useThemeColor";
import CustomButtonComponent from "../CustomButton";

export default function NavigatorCard({ onClick }: { onClick: () => void }) {
  return (
    <Card
      color="bhYellow40"
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      headerContent={
        <Grid container spacing={"16px"}>
          <Grid item lg={12} md={12} sm={12} xs={6}>
            <CardIcon />
            <Title variant="h2Medium">Live care navigator</Title>
            <SubTitle variant={"h5Medium"}>How can we help you today?</SubTitle>
          </Grid>
          <Grid
            item
            md={7}
            xs={6}
            sx={{
              display: { lg: "none", md: "none", sm: "none", xs: "block" },
            }}
          >
            <ImageGroup />
          </Grid>
        </Grid>
      }
      extraContent={
        <Grid container height={"100%"}>
          <Grid
            item
            sx={{
              display: {
                lg: "block",
                md: "block",
                sm: "block",
                xs: "none",
              },
            }}
          >
            <ExtraContainer>
              <ImageGroup
                leftImageStyles={{
                  width: {
                    lg: "116px",
                    md: "97px",
                    sm: "97px",
                    xs: "97px",
                  },
                }}
                rigthTopImageStyles={{
                  width: {
                    lg: "116px",
                    md: "66.98px",
                    sm: "66.98px",
                    xs: "66.98px",
                  },
                }}
                rigthBottomImageStyles={{
                  width: {
                    lg: "116px",
                    md: "66.98px",
                    sm: "66.98px",
                    xs: "66.98px",
                  },
                }}
              />
            </ExtraContainer>
          </Grid>
        </Grid>
      }
    >
      <CustomButtonComponent
        extraStyles={ChatButton}
        variant="contained"
        color="primary"
        startIcon={<ChatTwoBubblesOvalStreamlineIcon />}
        onClick={onClick}
        sx={{
          width: "100%",
          height: {
            lg: "52px",
            md: "48px",
            sm: "48px",
            xs: "48px",
          },
        }}
      >
        Start chat
      </CustomButtonComponent>
      <Footer>
        <Typography
          variant="body3"
          color={useThemeColor("neutral40")}
          sx={{ opacity: 0.86 }}
        >
          Available Monday-Friday, 9am-9pm EST / 6am-6pm PST.
        </Typography>
      </Footer>
    </Card>
  );
}
