import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useThemeColor } from "src/hooks/useThemeColor";

const UserIcon = ({ color, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill="none"
      style={{ ...props.style, color: "transparent" }}
      stroke={useThemeColor(color as string)}
    >
      <path
        d="M3 21C5.33579 18.5226 8.50702 17 12 17C15.493 17 18.6642 18.5226 21 21M16.5 8.5C16.5 10.9853 14.4853 13 12 13C9.51472 13 7.5 10.9853 7.5 8.5C7.5 6.01472 9.51472 4 12 4C14.4853 4 16.5 6.01472 16.5 8.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default UserIcon;
