// src/utils/interceptorUtil.ts

import axios from 'axios';
import { useEffect } from 'react';
import { SPECIAL_URLS } from 'src/constants';

export const useSetupInterceptors = (onError: () => void, handleLogout: () => void) => {
  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status, config } = error.response;
          if (status === 403) {
            handleLogout();
            return;
          }
          // TODO: the following condition can be updated later, by adding more.
          else if (config?.url && SPECIAL_URLS.some(url => config.url.includes(url))) {
            return Promise.reject(error);
          }
        }

        onError()
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [onError, handleLogout]);
};

